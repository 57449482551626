import { observable, action, computed, runInAction } from 'mobx';
import Cookies from 'js-cookie';
import { StoreManager, Store, ModelManager } from 'qn/data';
import { access, ROLE } from 'acl';
import Currency from 'util/currency';
import api from 'remote/api';
import uuid from 'util/uuid';
const Channel = ModelManager.getModel('Channel');
const sha1 = require('sha1');

const unCreatedEnterpriseId = 'LTE=';
const expires = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);// 七天过期
const PREFIX = 'qfpay-rvw'

class SessionStore {
  @observable user = {
    name: null,
    mail: null,
    role: null,
    status: null,
    token: null,
    userKey: null,
    agentId: null
  }

  @observable psd = null

  @observable autoLogin = null

  @observable locale = null

  @computed get hasLoginAccess() {
    return this.accesses.includes(access.WEB_LOGIN);
  }

  @observable channels

  @action
  saveUser(user) {
    Object.keys(this.user).forEach((key) => {
      this.user[key] = user[key] === undefined ? null : user[key];
    });
    // this.user.accesses = user.access;
    // delete this.user.access;
    this.saveToken(user.token);
    const exp = this.autoLogin ? expires : null;
    Cookies.set(`${PREFIX}-psd`, this.psd, { expires: exp });
    Cookies.set(`${PREFIX}-user`, JSON.stringify(this.user), { expires: exp });
    // localStorage.setItem('qfpay-user', JSON.stringify(this.user));

    // 当一个浏览器开启两个tab，登录不同账号时，需要让旧tab刷新
    this.qfpayTag = uuid();
    Cookies.set(`${PREFIX}-qfpayTag`, this.qfpayTag, { expires: exp });
  }

  getUser() {
    if (!this.user.token) {
      const jsonData = Cookies.get(`${PREFIX}-user`);
      if (jsonData === undefined) {
        return this.user;
      }
      const data = JSON.parse(jsonData);
      for (let key in data) {
        this.user[key] = data[key];
      }
    }
    return this.user;
  }

  @action
  setAutoLogin(v) {
    this.autoLogin = v;
  }

  @action
  setPsd(v) {
    const exp = this.autoLogin ? expires : null;
    this.psd = sha1(v);
    Cookies.set(`${PREFIX}-psd`, this.psd, { expires: exp });
  }

  getPsd() {
    if (this.psd === null) {
      this.psd = Cookies.get(`${PREFIX}-psd`);
    }
    return this.psd;
  }

  @action
  saveLocale(v) {
    this.locale = v;
    const exp = this.autoLogin ? expires : null;
    Cookies.set(`${PREFIX}-locale`, this.locale, { expires: exp });
  }

  getLocale() {
    if (this.locale === null) {
      this.locale = Cookies.get(`${PREFIX}-locale`);
    }
    return this.locale;
  }

  getAutoLogin() {
    return this.autoLogin;
  }

  saveToken(token) {
    const option = {};
    if (this.autoLogin) {
      option.expires = expires;
    }

    Cookies.set(`${PREFIX}-token`, token, option);
  }

  getToken() {
    return (this.user && this.user.token) || Cookies.get(`${PREFIX}-token`);
  }
  
  logout() {
    Cookies.remove(`${PREFIX}-token`);
    Cookies.remove(`${PREFIX}-psd`);
    Cookies.remove(`${PREFIX}-user`);
    // localStorage.removeItem('qfpay-user');
    Object.keys(this.user).forEach((key) => {
      this.user[key] = null;
    });
    this.channels = undefined;
  }

  hasToken() {
    return (this.user && this.user.token) || Cookies.get(`${PREFIX}-token`);
  }

  async fetchChannels() {
    if (!this.channels) {
      const res = await api.post('/agency/querychannel', {
        agencyKey: this.user.userKey
      });
      runInAction(() => {
        this.channels = res.data.map(o => new Channel(o));
      });
    }
    return this.channels;
  }

  getRegisteredChannels() {
    const channels = this.channels;
    return channels.filter(o => o.hasPassedReview === true);
  }

  getMyFeeRate(channel) {
    const matchChannel = this.channels.find(o => (
      o.hasPassedReview === true && o.isLogicSame(channel)
    ));
    if (matchChannel) {
      return matchChannel.feeRate;
    }
    return null;
  }

  getRedirectUrl() {
    this.getUser();
    if (!this.hasToken()) {
      return '/auth/login';
    }

    switch (this.user.status) {
      case 'NORMAL': {
        switch(this.user.role) {
          case ROLE.ADMIN:
            return '/app/merchant';
          default:
            return '/app/merchant';
        }
      }
      case 'AWAITING_REVIEW':
        return '/auth/registerSuccess';
      default:
        return '/auth/registerMore';
    }
  }

  hasAccessToHomePage() {
    return this.hasToken() && this.user.status === 'NORMAL';
  }
}
const sessionStore = new SessionStore();
StoreManager.register(sessionStore);
export default sessionStore;