
import Loadable from 'react-loadable';

import { LCLoading } from 'control/loading';
import { AuthorizedRouter } from 'acl';
import { Route, Switch } from 'react-router-dom';
import React from 'react';

const MerchantRevenueList = Loadable({
  loader: () => import('./MerchantRevenueList'),
  loading: LCLoading,
});

const MerchantRevenueRoute = ({ match }) => {

  return (
    <Switch>
      <Route exact path={`${match.path}`} component={MerchantRevenueList} />
    </Switch>
  );
}
export { MerchantRevenueRoute };

