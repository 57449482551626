import Loadable from 'react-loadable';
import { LCLoading } from 'control/loading';
import { AuthorizedRouter } from 'acl';
import { Route, Switch } from 'react-router-dom';
import React from 'react';

const AgentList = Loadable({
  loader: () => import('./AgentList'),
  loading: LCLoading,
});

const AgentDetail = Loadable({
  loader: () => import('./AgentDetail'),
  loading: LCLoading,
});

const AgentAdd = Loadable({
  loader: () => import('./AgentAdd'),
  loading: LCLoading,
});

const AgentManageRoute = ({ match }) => {

  return (
    <Switch>
      <Route exact path={`${match.path}/new`} component={AgentAdd}/>
      <Route exact path={`${match.path}/:id`} component={AgentDetail}/>
      <Route exact path={`${match.path}`} component={AgentList} />
    </Switch>
  );
}
export { AgentManageRoute };
