import { Model } from 'qn/data';
import { computed } from 'mobx';

@Model({
  modelName: 'Settlement',
  fields: {
    startTime: 'date',
    endTime: 'date',
    createdDate: 'date',
    accountName: 'string',
    accountCode: 'string',
    bankName: 'string',
    branchName: 'string',
    channel: 'string',
    bankingOrg: 'string',
    currency: 'string',
    settlementAmount: 'number',
    operatorName: 'string',
    storeName: 'string',
    agentName: 'string',
    agencyName: 'string',
    merchantsTotalIncome: 'number'
  },
  idgen: 'uuid',
})
class Settlement {

}

export default Settlement;
