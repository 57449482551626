import React from 'react';
import logo from 'public/img/logo_white.png';
import './style.scss';
import sessionStore from 'sessionStore';
import TextButton from 'control/textButton';
import { withRouter } from 'react-router-dom';
import withLocale from 'decorator/withLocale';
import {Modal} from 'antd';
import { StoreManager } from 'qn/data';
import { observer } from 'mobx-react';
import { observable,action } from 'mobx';
import { DEFAULT_LOCALE } from 'config';
import {  locales } from 'component/LocaleContext';
import ClickListener from 'carbon-components-react/es/internal/ClickListener';
import {Link} from 'react-router-dom';


const FormItemLayout = {
  labelStyle: { paddingLeft: 0 },
  labelCol: 6,
  controlCol: 'auto',
};

@withLocale
@observer
class LanguageHeader extends React.Component {
  constructor() {
    super();
    const langInStorage = sessionStore.getLocale();
    let lang = DEFAULT_LOCALE;
    if (langInStorage) {
      lang = langInStorage;
    }
    this.state = {
      locale: locales[lang],
      setLocale: this.setLocale,
    };
    window.qf_locale = locales[lang];
    if(lang === 'jp'){
      document.getElementById("app").style.fontFamily="\"Open Sans\",\"Meiryo\", \"ヒラギノ角ゴ Pro W3\", \"Hiragino Kaku Gothic Pro\", \"ＭＳ Ｐゴシック\", Arial, Sans-serif";
    } else if(lang === 'zh-CN'){
      document.getElementById("app").style.fontFamily="\"PingFang SC\",Arial,simsun,sans-serif";
    } 
  }
  setLocale = (value) => {
    if (value !== this.state.locale.l) {
      this.setState({
        locale: locales[value],
      });
      window.qf_locale = locales[value];
      sessionStore.saveLocale(value);
      window.location.reload();
    }
  }
  @observable langListVisible = false
  @action
  changeLangListVisible = () => {
    this.langListVisible = !this.langListVisible;
  }
  @action
  handleClickOutside = () => {
    this.langListVisible = false;
  }
  @action
  handleLangClick = (lang, e) => {
    console.log(lang);
    
    e.stopPropagation();
    this.langListVisible = false;
    this.props.setLocale(lang);
  }
  render() {
  const {  locale } = this.props;
  const style = {
    display: this.langListVisible ? 'block' : 'none',
  };
    return (
      <div onClick={this.changeLangListVisible}  >
        <ClickListener onClickOutside={this.handleClickOutside}>
          <div>
            <span  style={{cursor:'pointer', marginRight:10}} className="iconfont colorwhite"> {locale.langSelection}&#xe65a;  </span>
            <div id='language'  className="account" style={style}>
              <div className="updatepasswords" onClick={this.handleLangClick.bind(this, 'zh-CN')}>简体中文</div>
              <div style={{height:1}} className="border-bottom-dashed"></div>
              <div className="updatepasswords" onClick={this.handleLangClick.bind(this, 'jp' )}>日本語</div>
              <div style={{height:1}} className="border-bottom-dashed"></div>
              <div className="updatepasswords" onClick={this.handleLangClick.bind(this, 'en')}>English</div>
            </div>
          </div>
        </ClickListener>
      </div>
    )
  }
}
@withLocale
@withRouter
@observer
class Header extends React.Component {
  @observable visible = false;
  @observable loading = false;
  logout = () => {
    const { history, locale } = this.props;
    Modal.confirm({
      title:  locale.modal.title,
      content: locale.logoutConfirm,
      centered: true,
      maskClosable: false,
      okText:locale.header.editPsdBtnOk,
      cancelText:locale.header.editPsdBtnCancle,
      onOk() {
        history.replace('/auth/login');
        sessionStore.logout();
        StoreManager.dispose();
        Modal.destroyAll();
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
    // Modal.confirm({
    //   title: locale.modal.title,
    //   content: locale.logoutConfirm,
    //   primaryButtonText: locale.header.editPsdBtnOk,
    //   secondaryButtonText: locale.header.editPsdBtnCancle,
    //   onRequestSubmit() {
    //     history.replace('/auth/login');
    //     sessionStore.logout();
    //     StoreManager.dispose();
    //   },
    // });
  }
  render() {
    const {locale} = this.props;
    return (
      <header className="d-flex header justify-content-between">
        <Link to="./merchant"><img src={logo} alt="" style={{ width: 126, height: 20 }}/></Link>
        <div className="d-flex align-items-center">
          <div style={{ marginRight: 10 , color:'#ffffff'}} className="colorwhite">{sessionStore.getUser().name || '--' }</div>
          <div className="colorwhite" style={{marginRight:20}}>株式会社QFPay Japan</div>
          <LanguageHeader/>
          <span className="login-header__right__divide divide-v" style={{height:'15px'}}></span>
          <TextButton onClick={this.logout} style={{ marginRight: 45 }} className="colorwhite iconfont">&#xe61d;{locale.logout}</TextButton>
        </div>
        
      </header>
    );
  }
};

export default Header;
