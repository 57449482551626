import { Model } from 'qn/data';
import { computed } from 'mobx';

@Model({
  modelName: 'MyEarning',
  fields: {
    monthDate: 'string',
    earnings: 'number',
    agencyName: 'string'
  },
  idgen: 'uuid',
})
class MyEarning {

}

export default MyEarning;
