import { Model } from 'qn/data';

@Model({
  modelName: 'Message',
  fields: {
    key: 'string',
    hasBeenRead: 'boolean',
    preview: 'string',
    content: 'string',
    createdDate: 'date',
  },
  idProperty: 'key',
})
class Message {

}

export default Message;
