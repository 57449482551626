export default {
  l: 'en',
  param: 'en_US',
  lang: 'English',
  langSelection: 'Language Selection',
  brand: 'Merchant management system',
  searchMore: 'Search more',
  search: 'Search',
  modify: 'Modify',
  delete: 'Delete',
  export: 'Export',
  all: 'All',
  complete: 'Complete',
  cancel: 'Cancel',
  add: 'Add',
  back: 'Return',
  logout: 'Exit',
  logoutConfirm: 'Confirm Exit?',
  submit: 'Submit',
  resubmit: 'Submit Again',
  yes: 'Yes',
  no: 'No',
  psdError: 'Wrong Password',
  noWebLoginAccess: "Sorry, you are not authorise to login ",
  operate: 'Confirm',
  nextStep: 'Next Step',
  detail: 'Details',
  modifyPsd: 'Change Password',
  repeatLogin: 'Your account is being used in another device',
  thatQrcode: 'QR Code',
  noAuth: 'You are not authorised',
  none: 'Not Available',
  certificateOfIncorporation: 'Business License',
  total: 'Total',
  count: 'Row',
  noData: 'No Data',
  choose : 'Please Select',
  home: {
    title: 'First Page',
    sevenDayEarnings: 'Last 7 days profit',
    thirtyDayEarnings: 'Last 30 days profit',
    sevenDay: 'Last 7 days ',
    thirtyDay: 'Last 30 days',
    todaySignNum: "Today's no of Merchant",
    monthSignNum: 'Month-to-date Merchant',
    allSignNum: 'Total no of Merchant',
    waitHandle : 'Rates pending',
    notBeenSubmitted: 'Pending',
    inTheReview: 'In Progress',
    haveBeenThrough: 'Passed',
    auditFailure: 'Failed',
    storeSignTrend: 'Sing-up Merchant Growth Chart',
    agencyTOP3: "Top 3 Agent's Mercant Chart ",
    agentTOP3: 'Top 3 Salesperson',
    storeTOP3: 'Top 3 Merchant Chart',
    growthMost: 'Most Performed',
    dropMost: 'Most Declined ',
    signNumberMost: 'Most Merchant',
    totalNumberMost: 'Most Profit',
    earnings: 'Profit Ratio ',
    signPercentage: 'Passed Merchant Ratio ',
    signNum: 'No of Sign-up Merchant',
    top3Detail1:"Current top 3 Agent, showing various sub-Agent's new merchant number and growth statistics. Comparison cycle is previous week and the week before. ",
    top3Detail2:"Current top 3 Salesperson, showing various sub-Agent's new merchant number and growth statistics. Comparison cycle is previous week and the week before. ",
    top3Detail3:"Current top 3 Merchant, showing various sub-Agent's new merchant number, income and growth statistics. Comparison cycle is previous week and the week before. ",
    top3Detail4:"Current top 2 Merchant, showing various sub-Agent's new merchant number, income and growth statistics. Comparison cycle is previous week and the week before. ",
    top3Detail5:"Top 5 wallets volume",

  },
  login: {
    welcome:'Welcome Login',
    id: 'Login ID',
    idPlaceholder: 'Please enter Login ID',
    storeownerEmail: "Shop Owner's Email",
    psd: 'Password',
    psdPlaceholder: 'Please enter Password',
    workNumber: 'Employee No ',
    workNumberPlaceholder: 'Please enter Employee No',
    autoLogin: 'Auto Login',
    shopkeeperLogin: 'Branch Manager',
    employeeLogin: 'Staff',
    forgetPsd: 'Forget Password',
    submit: 'Login',
    emailSend:'Send',
    noLoginIdTip: 'Not register yet,',
    registerNow: 'Register Now ',
    mail: 'Register email',
    forgetMail:'Login email',
    mailPlaceholder: 'Please enter email address',
    findPassword:'Find password',
    Message:'Please use at least 8 characters. Do not use same password of other website, and do not create the simple password ',
    remind:'Remember password',
    goLog:'Login',
    newPad : 'New password',
    newPadConfirm: 'Confirm new password'

  },
  qrCodeRegister:{
    newtitle:'Add merchant',
    brdtitleStep1:'Enterprise Register',
    brdtitleStep2:'Store Register',
    brdtitleStep3:'Complete',
    entertitle:'Enterprise Register',
    storetitle:'Store Register',
    next:'next',
    commit:'Submitted!',
    committext:'The registration information you filled in has been submitted and will be notified by email after approval',
    waitmail:'Please wait patiently for review......',

    enterprisemail:'Enterprise Mail',
    enterprisemailconfim:'Enterprise Mail Again',
    enterprisename:'Enterprise Name',
    enterprisetype:'Enterprise Type',
    companycode:'Company Code',
    accountphone:'Account Phone',

    storemail:'Store Mail',
    storemailconfim:'Store Mail Again',
    storename:'Store Name',
    prefecture:'Prefecture',
    municipalward:'Municipal Ward',
    detailadress:'Detail Adress',
    streetnumber:'Street Number',
    zipcode:'Zip Code',
    businesscode:'Business Code',
    businessphone:'Business Phone',

    enterprisemailPlaceholder:'Example：qfpay@qfpay.com',
    enterprisemailconfimPlaceholder:'Please enterprise mail again',
    enterprisenamePlaceholder:'Please enterprise name',
    enterprisetypePlaceholder:'Please enterprise type',
    companycodePlaceholder:'0100-01-178407',
    accountphonePlaceholder:'090-1374-1287',

    storemailPlaceholder:'Example：qfpay@qfpay.com',
    storemailconfimPlaceholder:'Please store mail again',
    storenamePlaceholder:'Please store name',
    prefecturePlaceholder:'東京都',
    municipalwardPlaceholder:'港区',
    detailadressPlaceholder:'愛宕グリーンヒルズMORIタワー38F',
    streetnumberPlaceholder:'2-5-1',
    zipcodePlaceholder:'160-0023',
    businesscodePlaceholder:'佐藤太郎',
    businessphonePlaceholder:'090-1374-1287',
    registerInfo1:'It will take you 5-8 minutes to fill in the registration information. If you quit halfway, the filled information will not be saved. Please reserve time',
    registerInfo2:'To gather information.',
  },
  register: {
    newtitle:'Agent Register',
    mailtitle:'Verify Email ',
    title: 'Register Account',
    mail: 'Register Email ',
    agentInformation:'Agent Info',
    success:'Complete',
    mailPlaceholder: 'eg: qfpay@qfpay.com',
    mailConfirm: 'Confirm Email ',
    mailConfirmPlaceholder: 'Please enter email again ',
    captcha: 'Get verification code ',
    newcaptcha:'Verification code',
    captchaPlaceholder: 'Please enter verification code from email, eg: k2j4',
    termsOfService: 'Service Agreement',
    privacyRelatedPolicies: 'Privacy Policy',
    privacyRelatedPoliciesandtermsOfService:'Privacy Policy, Service Agreement',
    agree: 'Agree',
    and: 'And',
    submit: 'Register',
    hasLoginId: 'Already have Account,',
    toLogin: 'Login',
    psd: 'enter Password',
    psd2:"******",
    psdPlaceholder: 'Please enter Password',
    psdConfirm: 'Confirm Password',
    psdConfirmPlaceholder: 'Please enter password again ',
    loginId: 'Login ID',
    phone: 'Mobile Number',
    name: 'Name',
    mailVerifyInvalid: 'Verification Link Expired',
    waitingTip: 'Your application is being processed, we will complete it within 1-2 working days, thank you for your patience. ',
    failureTip: 'Sorry, your submitted information is not complete, please update and re-submit again ',
    errorCaptcha: 'Wrong verification code',
    phonePlaceholder: 'eg: 090-1374-1287',
    namePlaceholder: 'eg: 佐藤太郎',
    agencyNamePlaceholder: 'eg: 株式会社〇〇〇〇',
    agencyName: "Agent's Merchant Name",
    privacyTip: 'Please complete the agreement with QFPay prior applying the account',
    next:'Next Step',
    invitationCode:'Invitation Code',
    invitationcode2:'Please enter Invitation Code',
    mail2: 'Email',
    mailPlaceholder2: 'Email',
    mailPlaceholderStore: 'Merchant User Login ID',
    mailPlaceholderEnterprise: 'Enterprise User Login ID',
    storeUserLoginMail: 'Merchant Email ',
    defaultPassword: 'Initial Password is 123456',
    theDefaultPassword: 'Default Password',
    captchaPlaceholder: 'Verification Code',
    storeInfo: "Merchant's Info",
    attachedStoreInfo: "Sub-Merchant's Info",
    storeName: 'Merchant Name',
    storeAddress: 'Merchant Address',
    storeEmail: 'Merchant Email',
    storeTel: 'Merchant Phone Number',
    bankInfo: 'Bank Info',
    bankCode: 'Bank Code',
    bankName: 'Bank Name',
    branchBankCode: 'Bank Branch Code',
    branchBankName: 'Bank Branch Name',
    accountType: 'Account Type',
    accountName: 'Account Name',
    accountCode: 'Account Number',
    settlementCurrency: 'Settlement Currency',
    storeNamePlaceholder: 'Merchant Name',
    storeAddressPlaceholder: 'Merchant Address',
    storeEmailPlaceholder: 'Merchant Email',
    storeTelPlaceholder: 'Merchant Phone Number',
    bankCodePlaceholder: 'Bank Code',
    bankNamePlaceholder: 'Bank Name',
    branchBankCodePlaceholder: 'Bank Branch Code',
    branchBankNamePlaceholder: 'Bank Branch Name',
    accountTypePlaceholder: 'Account Type',
    accountNamePlaceholder: 'Account Name',
    accountCodePlaceholder: 'Account Number',
    psdPlaceholder: 'Please enter 6-8 digit number and letter combination',
    isDevicePurchase: 'Needs Equipment',
    next:'Next Step',
    loadingmore:'More',
  },

  registerAftermail:{
    agentManagement: 'Agent Management',
    homePagenew:'Agent First Page',
    create: 'Create Agent',
    mailtitle:'Verify Email ',
    agentInformation:"Agent's Information",
    success:'Complete',
    mailRegistration:'Register Email ',
    captcha: 'Obtain Verification Code',
    newcaptcha:'Verification Code',
    bankInfo: 'Bank Info',
    bankName : 'Bank Name',
    bankCode : 'Bank Code',
    bankType:'Bank Name',
    branchName : 'Bank Branch Name',
    branchnumber:'Bank Branch Code',
    Subbranchtype:'Bank Branch Type',
    Accounttype:'Account Type',
    Bankaccountname:'Bank Account Name (half-width Katakana)',
    Bankaccountname2:'Bank Account Name',
    Bankaccount:'Account Number',
    psd: 'enter Password',
    psdConfirm: 'Confirm Password',
    bankInfo: 'Bank Info',
    ChannelAddition:'Add Channel',
    submit:'Submit',
    man:'Male',
    woman:'Female',
    mailtitle:'Verify Email ',
    agentInformation:"Agent's Information",
    corporateInformation:'Corporate Information',
    Contacts:'Contact Person Information',
    undertaker:'Person in Charge',
    success:'Complete',
    Preservation:'Save',
    trueEmail: 'Please enter correct Email ',
    filEmail:'Please enter Email ',
    psd:'Password',
    popcontent:'Remember to Save, so you may continue after login again',
    fillcode:'Please enter Verification Code',
    psdPlaceholder: 'Please enter Password',
    psdConfirmPlaceholder: 'Please enter Password Again',
    fillInformation:'Please enter Information',
    fillNumber:'Please enter Number',
    filEmail:'Please enter Email ',
    rate:'Please enter fees rates',
    addTips:'(Note: Default is enable all channels) ',
    toLogin1:'already have account,',
    toLogin2:'Login',
    picDetail:"Please upload clear and actual picture, do not include any words, numbers, URL etc in the photo. Do not upload name card, QR Code, selfie or other irrelevant photo, suppor JPG/PNG format, maximum 9 photos, maximum 2M per photo",
    WeChatExplain:'China most common chat app to serve you "WeChat Pay"',
    AlipayExplain:"'Alipay' started with TaoBao online shopping platform with the 1 billion user worldwide (※until January 2019) , world's 3rd largest payment platform",
    OrigamiExplain:"Origami with the concept of 'create the future of money, payment and commercial', provide the cashless platform",
    PaypayExplain:"Mobiel payment by Softbank, Yahoo - PayPay",
    NetspayExplain:"All Singapore 3 banks could pay with this QR Code service",
    RakutenpayExplain:"Rakuten Pay, even you are not in Rakuten, you still can make the payment with Rakuten ID ",
    UmspayExplain:"CUP(UnionPay), the sixth international card brand after the 5 international card brands (VISA, MASTER, JCB, AMERICAN EXPRESS, DINERS CLUB)",
    VIAExplain:"can use the Dash from Singapore, Global Pay from Thailand payment service.",
    DocomoExplain:"Docomo Pay, combine allthe payment networks and mobile top-up,simpleAnd convenient,realThe benefits of mobile payment serviceBusiness.",
    PringExplain:"pring, it is a payment app like messaging among the friends and family ",
    SolPayExpain: 'QR payment by Shinhan Bank',
    GMOExpalin : 'Payment gateway (Online and credit card payment)',
    DiXingExpalin : 'Denjoy,Cross-border payment and bank member services provider',
    tBTCExplain: 'It is an ERC20 token that is trustlessly backed by and redeemable for Bitcoin. ',
    BTCExplain: 'Bitcoin is a form of digital currency created in January 2009 and is tradable throughout the world. Bitcoins can be transferred between businesses to pay for goods and services, all without the use of a bank.',
    ACDExplain: 'ACD COIN cooperates with airlines and EC platforms ,and can be applied to online and offline stores  in global.',
  },


  registerMore: {
    belongToEInfo: 'Company Information',
    belongToEName: 'Company Name',
    hasEnterprise: 'Company Registered?',
    has: 'Yes',
    not: 'No',
    privacy: "IS Merchant agreed on QFPay's term and condition? "
  },
  registerMailSended: {
    title: 'Registered Email Verification',
    content1: 'An email has been sent ',
    content2: 'Please check your email and click the activation link to fill in information'
  },

  agentregistration:{
    newtitle:'Agent Registration',
    mailRegistration:'Email Registration',
    agentInformation:'Agent Information',
    success:'Complete',
    Submitted:' Submitted',
    SubmissionDetails:'Your information has been submitted',
    SubmissionDetails2:'Please wait for the result ',
    loginto:'Login',
    backPage:"Back to Agent's page "
  },
  registerStep: {
    register: 'Register Account',
    moreInfo: 'Complete Information',
    waitingReview: 'Waiting for Approval ',
  },
  revenue: {
    typeLabel: 'Search Category',
    viewType: {
      iso: 'Agent',
      agentManagement: 'Salesperson '
    },
    search: {
      tradeTime: 'Transaction date',
      keywordPlaceholder: 'Please enter keyword',
    },
    key: 'Serial Number',
    isoName: 'Agent Name',
    agencyCode: 'Agent Number',
    cashFlow: 'Transaction Amount',
    superiorName: 'Superior Agent',
    agentId: 'Salesperson Serial Number',
    agentCode: 'Agent Number',
    agentName: 'Agent Name',
    parentAgency: 'Affiliates Agent',
    phone: 'Contact ',
  },
  merchant: {
    managementTitle: 'Merchant Management ',
    statTitle: 'Merchant Statistics',
    unReview: 'Pending Merchant ',
    pendingReview: 'Under Review Merchant',
    reviewFailure: 'Failed Merchant ',
    signedMerchant: 'Merchant Number',
    agentName: 'Agent/Agent Name',
    addMerchant: 'Add Merchant',
    myQrcode: 'My QR Code',
    qrcodeHover: 'Merchant Scan QR Code, bring along your information for registratiion ',
    download: 'Download',
    registerTime: 'Register date',
    enterpriseName: 'Company Name',
    storeName: 'Branch Name',
    type: 'Merchant Type',
    address: 'Address',
    channels: 'Existing Channel',
    reviewingChannels: 'Pending Review Channel',
    status: 'Status',
    typeEnum: {
      ENTERPRISE: 'Enterprise',
      SUB_STORE: 'Branch ',
    },
    attachedTypeEnum: {
      ENTERPRISE: 'Enterprise',
      SUB_STORE: 'Affiliate Branch ',
    },
    statusEnum: {
      AWAITING_REVIEW: 'Pending',
      TO_REVISE_MATERIAL: 'Failed',
      REVIEW_PASS: 'Passed',
      REVIEWING: 'In Progress',
    },
    search: {
      registerTime: 'Register date',
      keywords: 'Search Keyword',
      keywordsPlaceholder: 'Please enter keyword',
    },

    add: 'Add Merchant',
    copy: 'Copy link',
    complete: 'Your QR Code has been copied to clipboard ',
    hasBeendisabled: 'Disabled',
  },
  merchantRevenue: {
    revenue: 'Transaction Amount',
    dividends: 'Our Profit',
    dividendsInMonth: 'This Month Our Profit',
    showMore: 'Check More',
    title: 'Check Number ',
    merchantDividends: 'Merchant Commission',
    agencyDividends: 'Sub-Tier Agent Commission',
    agentDividends: 'Salesperson Commission',
    search: {
      date: 'Check date',
      channel: 'Channel Selection',
    }
  },
  footer: {
    concatInfo: 'Contact ',
    concatMail: 'Cutomer Service Email',
    concatTel: 'Customer Service Contact Number',
    businessHours: 'Operating Hours',
    except: 'Except Weekends and Holidays',
    useRole: 'Rules and Regulation',
    privacy: 'Privacy Agreement',
    companyInfo: 'Company Information',
    aboutUs: 'About Us',
    qfpay: 'QFPay Japan Operation ',
    payMode:'Payment',
    address:'Address ：〒105-0001 東京都港区虎ノ門 1-10-5 KDX Toranomon 1 Chome 11F 株式会社QFPay Japan',
    payModeDetail:'QFPay support payment method',
  },
  nav: {
    title: 'Home',
    agentSettlementSelf: 'Settlement Management',
    merchantManagement: 'Merchant Management ',
    agentManagement: 'Salesperson Management',
    agencyManagement: 'Agent Management',
    agencySettlement: 'Agent Settlement',
    agentSettlement: 'Salesperson Settlement',
    bankInfo: 'Agreement Information',
    RevenueSearch: 'Agent Number Search ',
    ISOManagement: 'First-tier Agent Management',
    ISV1Management: 'Second-tier Agent Management',
    ISV2Management: 'Third-tier Agent Management',
    ISOReview: 'First-tier Agent Review',
    ISOSettlement: 'First-tier Agent Settlement',
    Storemanagement:'Merchant Management',
    am:'Operation Mangement',
    Revenuemanagement:'Revenue Management ',
    messageManagent:'Notification Mgmt',
    groupManagent:'Group Mgmt',
    accountManage:'Account management',
    infoManage:'information management',
    dataDownLoad:'Downloads'

  },
  agency: {
    agencyName: 'Agent Name',
    agentCode: 'Agent Serial Number',
    new: 'New Agent',
    info: 'User Information',
  },
  agent: {
    agentCode: 'Agent Number',
    new: 'New Salesperson',
    AgentTitle:'Salesperson Overview',
    AgentName:'Salesperson Management',
    AgentAdd:'Add Salesperson',
    AgentDetail:'Salesperson Information',
    AgentLocal:'Basic Information',
    AgentMail:'Register Email ',
    AgentPhone:'Mobile Number',
    AgentNa:'Name in Charge',
    AgentNo:'Agent Number',
    currency:'Settlement Currency ',
    feerate:'Fees',
    all:'Total ',
    one:'Row',
    loginId: 'Login ID',
  },
  bankInfo: {
    title: 'Bank Info',
    bankCode: 'Bank Code',
    bankName: 'Bank Name',
    settlementCurrency: 'Settlement Currency ',
    accountType: 'Account Type',
    accountName: "AccountHolder's name",
    accountCode: 'Account ID',
    branchName: 'Bank Branch Name',
    branchCode: 'Bank Branch Code',
    noData: 'You have not create bank information yet',
    accountTypeEnum: {
      SAVINGS: 'Normal',
      CHECKING: 'Current'
    },
    bankTypeEnum : {
      BANK : 'Bank',
      FINANCIAL_COOPERATIVE : 'Financial Institution',
      CREDIT_COOPERATIVE : 'Credit Institution',
      AGRICULTURAL_COOPERATIVE : 'Agricultural Institution'
    },
    branchTypeEnum : {
      BRANCH_STORE : 'Branch ',
      HEAD_OFFICE : 'Headquater',
      FIELD_OFFICE : 'Other Office',
      BRANCH_OFFICE : 'Branch Office'
    },
    bankNamePlaceholder: 'eg：三菱銀行',
    bankCodePlaceholder: 'eg：0005',
    bankTypePlaceholder: 'eg：銀行',
    branchBankNamePlaceholder: 'eg：新瑞橋支店',
    branchBankCodePlaceholder: 'eg：251',
    branchTypePlaceholder : 'eg：支店',
    accountTypePlaceholder: 'eg：Normal預金',
    accountNameHalfKataPlaceholder :'eg：カブシキガイシャ〇〇〇〇',
    accountNamePlaceholder: 'eg：株式会社〇〇〇〇',
    accountCodePlaceholder: 'eg：12414141',
  },
  companyInfo: {
    devicePurchaseType: {
      lease: 'Rent',
      buy: 'Buy',
      noBuy: 'Not Buy',
    }
  },
  review: {
    search: {
      registerTime: 'Register date',
      keywords: 'Search Keyword',
      keywordsPlaceholder: 'Please enter keyword',
    },
    statusEnum: {
      NORMAL : 'Passed',
      AWAITING_REVIEW: 'In Progress',
      TO_REVISE_MATERIAL: 'Failed',
      TO_COMPLETE_MATERIAL: 'Pending'
    },
    registerTime: 'Register date',
    name: 'Agent Name',
    mail: 'Email',
    audit: 'Review',
    status: 'Status',
    loginId: 'Login ID',
  },
  isoManagement: {
    key: 'Serial Number',
    loginId: 'Login ID',
    name: 'Agent Name',
    mail: 'Email',
    registeredChannel: 'Existing Channel',
    phone: 'Contact ',
    resetPsd: 'Reset password',
    search: {
      keywords: 'Search Keyword',
      keywordsPlaceholder: 'Please enter keyword',
    },
  },
  agentCreate:{
    agentManagement: 'Agent Management',
    homePagenew:'Agent First Page',
    create: 'Create Agent',
    mailRegistration:'Email Registration',
    mailConfirm: 'Confirm Email ',
    mailConfirmPlaceholder: 'Please enter email again ',
    mailPlaceholder: 'eg: qfpay@qfpay.com',
    captcha: 'Get verification code ',
    captchaPlaceholder: 'Verification Code',
    psd2:"******",
    psdConfirmPlaceholder: 'Please enter password again ',
    psdConfirmNewPlaceholder: 'Please enter new password again',
    newcaptcha:'Verification Code:',
    bankInfo: 'Bank Info',
    bankName : 'Bank Name',
    bankCode : 'Bank Code',
    bankType:'Bank Name',
    branchName : 'Bank Branch Code',
    branchnumber:'Bank Branch Code',
    Subbranchtype:'Bank Branch Type',
    Accounttype:'Account Type',
    Bankaccountname:'Bank Account Name (half-width Katakana)',
    Bankaccountname2:'Bank Account Name',
    Bankaccount:'Account Number',
    psd: 'enter Password',
    psdConfirm: 'Confirm Password',
    bankInfo: 'Bank Info',
    ChannelAddition:'Add Channel',
    submit:'Submit',
    man:'Male',
    woman:'Female',
    mailtitle:'Verify Email ',
    agentInformation:'Agent Information',
    corporateInformation:'Corporate Information',
    Contacts:'Contact Person Information',
    undertaker:'Person in Charge',
    success:'Complete',
    Preservation:'Save',
    trueEmail: 'Please enter correct Email ',
    filEmail:'Please enter Email ',
    psd:'Password',
    popcontent:'Remember to Save, so you may continue after login again',
    fillcode:'Please enter Verification Code',
    psdPlaceholder: 'Please enter Password',
    psdConfirmPlaceholder: 'Please enter Password Again',
    fillInformation:'Please enter Information',
    fillNumber:'Please enter Number',
    filEmail:'Please enter Email ',
    rate:'Please enter fees rates',
    rateTip1 : 'Fee Range: Min: ',
    rateTip2 : 'Max:',
    rateTip3: '',
    picDetail:"Please upload clear and actual picture, do not include any words, numbers, URL etc in the photo. Do not upload name card, QR Code, selfie or other irrelevant photo, suppor JPG/PNG format, maximum 9 photos, maximum 2M per photo",
    WeChatExplain:'China most common chat app to serve you "WeChat Pay"',
    AlipayExplain:"'Alipay' started with TaoBao online shopping platform with the 1 billion user worldwide (※until January 2019) , world's 3rd largest payment platform",
    OrigamiExplain:"Origami with the concept of 'create the future of money, payment and commercial', provide the cashless platform",
    PaypayExplain:"Mobiel payment by Softbank, Yahoo - PayPay",
    NetspayExplain:"All Singapore 3 banks could pay with this QR Code service",
    RakutenpayExplain:"Rakuten Pay, even you are not in Rakuten, you still can make the payment with Rakuten ID ",
    UmspayExplain:"CUP(UnionPay), the sixth international card brand after the 5 international card brands (VISA, MASTER, JCB, AMERICAN EXPRESS, DINERS CLUB)",
    VIAExplain:"can use the Dash from Singapore, Global Pay from Thailand payment service.",
    DocomoExplain:"Docomo Pay, combine allthe payment networks and mobile top-up,simpleAnd convenient,realThe benefits of mobile payment serviceBusiness.",
    PringExplain:"pring, it is a payment app like messaging among the friends and family ",
    SolPayExpain: 'QR payment by Shinhan Bank',
    GMOExpalin : 'Payment gateway (Online and credit card payment)',
    DiXingExpalin : 'Denjoy,Cross-border payment and bank member services provider',
    tBTCExplain: 'It is an ERC20 token that is trustlessly backed by and redeemable for Bitcoin. ',
    BTCExplain: 'Bitcoin is a form of digital currency created in January 2009 and is tradable throughout the world. Bitcoins can be transferred between businesses to pay for goods and services, all without the use of a bank.',
    ACDExplain: 'ACD COIN cooperates with airlines and EC platforms ,and can be applied to online and offline stores  in global.',
  },

  agentdetail:{
    agentManagement: 'Agent Management',
    homePagenew:'Agent First Page',
    agentdetail:'Agent Detail',
    ChannelAddition:'Channel Detail ',
    feeRate: 'Fees:',
    man:'Male',
    woman:'Female',
    bankInfo: 'Bank Info',
    bankName : 'Bank Name',
    bankCode : 'Bank Code',
    bankType:'Bank Name',
    branchName : 'Bank Branch Code',
    branchnumber:'Bank Branch Code',
    Subbranchtype:'Bank Branch Type',
    Accounttype:'Account Type',
    Bankaccountname:'Bank Account Name (half-width Katakana)',
    Bankaccountname2:'Account Name',
    Bankaccount:'Account Number',

  },
  accountdetail:{
    agentManagement: 'Agent Management',
    homePagenew:'Agent First Page',
    agentdetail:'Agent Detail',
    ChannelAddition:'Channel Detail ',
    feeRate: 'Fees:',
    man:'Male',
    woman:'Female',
    bankInfo: 'Bank Info',
    bankName : 'Bank Name',
    bankCode : 'Bank Code',
    bankType:'Bank Name',
    branchName : 'Bank Branch Code',
    branchnumber:'Bank Branch Code',
    Subbranchtype:'Bank Branch Type',
    Accounttype:'Account Type',
    Bankaccountname:'Bank Account Name (half-width Katakana)',
    Bankaccountname2:'Account Name',
    Bankaccount:'Account Number',
    accountManage:'Account management',
    infoManage:'information management',
    contactInfo:'Basic information',
    loginId:'LoginId',
    loginPsd:'Password',
    tell:'Contact',
    agentName:'agentName',
    qrCode:'myQrcode',
    invitationCode:'invitationCode',
    inputOldPsd:'Please enter password',
    inputNewPsd:'Please enter new password',
    confirmNewPsd:'Please enter new password again ',
    resetPsdBtn:'Reset password',
    psdInfoTitle:'Password strength:',
    psdInfo:'The password must contain letters and numbers with a length of 6-8 digits. Do not use the password you use to log in to other websites, or use a password that is easy to guess (for example, 123abc)',
},

  agentedit:{
    agentManagement: 'Agent Management',
    homePagenew:'Agent Home ',
    edit: 'Edit',
    create: 'Create Agent',
    mailRegistration:'Email Registration',
    psd: 'Password',
    bankInfo: 'Bank Info',
    bankName : 'Bank Name',
    bankCode : 'Bank Code',
    bankType:'Bank Name',
    branchName : 'Bank Branch Code',
    branchnumber:'Bank Branch Code',
    Subbranchtype:'Bank Branch Type',
    Accounttype:'Account Type',
    Bankaccountname:'Bank Account Name (half-width Katakana)',
    Bankaccountname2:'Account Name',
    Bankaccount:'Account Number',
    ChannelAddition:'Add Channel',
    submit:'Submit',
    mailtitle:'Verify Email ',
    agentInformation:'Agent Information',
    corporateInformation:'Corporate Information',
    undertaker:'Person in Charge',
    Contacts:'Contact Person Information',
    success:'Complete',
    Preservation:'Save',
    popcontent:'Remember to Save, so you may continue after login again',
    man:'Male',
    woman:'Female',
    rate:'Please enter fees rates',
    WeChatExplain:'China most common chat app to serve you "WeChat Pay"',
    AlipayExplain: "'Alipay' started with TaoBao online shopping platform with the 1 billion user worldwide (※until January 2019) , world's 3rd largest payment platform",
    OrigamiExplain:"Origami with the concept of 'create the future of money, payment and commercial', provide the cashless platform",
    PaypayExplain:"Mobiel payment by Softbank, Yahoo - PayPay",
    NetspayExplain:"All Singapore 3 banks could pay with this QR Code service",
    RakutenpayExplain:"Rakuten Pay, even you are not in Rakuten, you still can make the payment with Rakuten ID ",
    UmspayExplain:"CUP(UnionPay), the sixth international card brand after the 5 international card brands (VISA, MASTER, JCB, AMERICAN EXPRESS, DINERS CLUB)",
    VIAExplain:"can use the Dash from Singapore, Global Pay from Thailand payment service.",
    DocomoExplain:"Docomo Pay, combine allthe payment networks and mobile top-up,simpleAnd convenient,realThe benefits of mobile payment serviceBusiness.",
    PringExplain:"pring, it is a payment app like messaging among the friends and family ",
    SolPayExpain: 'QR payment by Shinhan Bank',
    GMOExpalin : 'Payment gateway (Online and credit card payment)',
    DiXingExpalin : 'Denjoy,Cross-border payment and bank member services provider',
    tBTCExplain: 'It is an ERC20 token that is trustlessly backed by and redeemable for Bitcoin. ',
    BTCExplain: 'Bitcoin is a form of digital currency created in January 2009 and is tradable throughout the world. Bitcoins can be transferred between businesses to pay for goods and services, all without the use of a bank.',
    ACDExplain: 'ACD COIN cooperates with airlines and EC platforms ,and can be applied to online and offline stores  in global.',
    picDetail:"Please upload clear and actual picture, do not include any words, numbers, URL etc in the photo. Do not upload name card, QR Code, selfie or other irrelevant photo, suppor JPG/PNG format, maximum 9 photos, maximum 2M per photo",
  },


  agentaudit:{
    agentManagement: 'Agent Management',
    homePagenew:'Agent Home ',
    auditAgent:'Review New Agent',
    ChannelAddition:'Add Channel',
    man:'Male',
    woman:'Female',
    bankInfo: 'Bank Info',
    bankName : 'Bank Name',
    bankCode : 'Bank Code',
    bankType:'Bank Name',
    branchName : 'Bank Branch Code',
    branchnumber:'Bank Branch Code',
    Subbranchtype:'Bank Branch Type',
    Accounttype:'Account Type',
    Bankaccountname:'Bank Account Name (half-width Katakana)',
    Bankaccountname2:'Account Name',
    Bankaccount:'Account Number',
    feeRate: 'Fees:',
    reject: 'Reject',
    ok: 'Pass',
    Refuseapplication:'Reject?',
    Confirmrejection:'Confirm Reject? Agent may re-submit after reject ',
    Determine:'Confirm',
    cancel:'Cancel',
    rate:'Please enter fees rates',
    distribution : 'Distribute',
    toBeISO: 'Become tier-1 Agent',
    toBeSubordinate : 'Add sub-Agent',
    chooseOne: 'Please select superior-Agent'
  },


  agentManagement: {
    examinePass:'Reviewed Payment Channel Fee',
    agentManagement: 'Agent Management',
    id: 'Salesperson Serial Number',
    agentCode: 'Agent Number',
    agentTime:'Application Date',
    loginId: 'Login ID',
    name: 'Salesperson ',
    mail: 'Email',
    registeredChannel: 'Existing Channel',
    phone: 'Contact ',
    resetPsd: 'Reset Password',
    search: {
      keywords: 'Search Keyword',
      keywordsPlaceholder: 'Name/Email/Contact ',
      applytime: 'Application Date',
    },
    underCount:'Total sub-tier Agent',
    agency : 'Agent',
    state : 'Status',
    query:'Query ',
    reset:'Reset',
    status: {
      comoleteMeterial :  'Failed',
      waitingReview : 'In Progress',
      reverseMaterial: 'Pending',
      normal: 'Normal'
    },
    contactInfo: 'Contact Person Information',
    contactPhone: 'Contact ',
    name: 'Name',
    mail: 'Email',
    agencyName : 'Agent Name',
    bankInfo : 'Bank Name',
    bankName : 'Bank Name',
    bankCode : 'Bank Code',
    branchName : 'Bank Branch Code',
    screen : 'Selection',
    keyword : ' Keyword',
    subState : 'SubmitStatus',
    query:'Query ',
    reset:'Reset',

    all : 'All',
    agentsList : 'Agent Overview',
    systemCode : 'Serial Number',
    agentName : 'Agent Name',
    curChannel : 'Existing Channel',
    create: 'Create Agent',
    invatationnew:'Invite Agent',
    homePagenew:'Agent Home ',

    invatation:'Invite Agent',
    invateCode: 'Invitation Code',
    inVateCodePh: 'Please enter Invitation Code',
    mailPh : 'Please enter email of the agent you would like to invite',
    send : 'Send',
    sendpop:'After pressing "Send" button, we will generate email to the invited agent with your invitation code and the agent could self-register ',
    select : 'please select method to create agent',
    direct : 'Direct Adding',
    tips : 'Note: ',
    tip1: "'Invite Agent' - send invitation link to new agent's email, and the new agent may self-register themselves",
    tip2 : '"Direct Adding" - create new agent directly under this login, and enter the information on behalf of new agent ',
    homePage : 'Agent Home ',
    action: {
      detail : 'Details',
      edit : 'Edit',
      delete : 'Delete',
      review : 'Review'
    },
    channel: 'Channel',
    operation: 'Operate',
    editPsdBtnOk: 'Confirm',
    editPsdBtnCancle: 'Cancel',
    Promptdescription:'After pressing "Send" button, we will generate email to the invited agent with your invitation code and the agent could self-register ',
    sure:'Confirm Send',
    sucsend:"Sent, click to back to home page ",
    sendPrompt:'Your verification code has been sent,please complete registration within 60 mins',
    level : 'Tier',
    roleEnum: {
      ADMIN : 'Administrator',
      AGENT: 'Salesperson',
      ISO: 'First-tier Agent',
      ISV_LEVEL_1: 'Second-tier Agent',
      ISV_LEVEL_2: 'Third-tier Agent'
    },
    superiorAgencyName : ' superior-tier Agent Name'
  },



  agencyManagement: {
    id: 'AgentSerial Number',
    agentCode: 'Agent Serial Number',
    loginId: 'Login ID',
    name: 'Agent',
    mail: 'Email',
    registeredChannel: 'Existing Channel',
    phone: 'Contact ',
    resetPsd: 'ResetPassword',
    search: {
      keywords: 'Search Keyword',
      keywordsPlaceholder: 'Please enter keyword',
    },
  },
  reviewDetail: {
    revoke: 'Cancel',
    reject: 'Reject',
    ok: 'Pass',
    rejectReason: 'Reject Reason',
    null: 'Nil',
  },
  modal: {
    title: 'Note',
    primaryButtonText: 'Complete',
    secondaryButtonText: 'Cancel',
    errorTitle: 'Something went wrong',
    confirmPrimaryButtonText: 'Confirm',
    confirmSecondaryButtonText: 'Cancel',
  },
  validator: {
    required: 'Mandatory ',
    number: 'Please enter correct numbers',
    format : 'Please enter correct format',
    email: 'Please enter correct email',
    len: 'Your enter is too long',
    len1: 'The password must contain letters and numbers with a length of 6-8 digits',
    integer: 'Please enter integer only',
    samePwd: 'Passwords do not matched',
    samePwdAccount: 'Passwords do not matched',
    sameMail: 'Email do not matched',
    existEmail: 'The email is being registered, please use another one ',
    unExistUser: 'Login do not exist or wrong password',
    receivable: {
      refoundAmountTooHight: 'Refund amount cannot higher than original amount',
      refoundAmountGtZero: 'Refund amount must greater than zero',
      refoundAmountInvalidDecimal: 'Refund amount can reserve 2 decimal ',
      max_l_8: 'Maximum is not more than 8 digits.',
    },
    max_l_x: 'Maxmum not more than x% ',
    max_l_100: 'Maximum is not more than 100 digits',
    min_l_6: 'Not less than 6 digits.',
    positiveNumber: 'Cannot be lower than zero',
    noBindingChannels: 'Missing Channel',
    existLoginId: 'The login ID is being registered, please use another one ',
    enCode: 'Special Character is not allowed.',
    halfChar: 'Please enter kana, space, and brackets.',
    maxUploadCount: 'Cannot  upload more than 9 pictures ',
    maxUploadSize: 'Every picture size cannot more than 1M',
    maxOneDeciaml: 'Decimal cannot more than 1 digit',
    maxTwoDeciaml: 'Decimal cannot more than 2 digit',
    percentageNumber: 'Please enter the correct percentage (up to two decimal places)',
    invalidUploadExtensions: 'Upload file format should be a normal picture format',
    invalidUploadExtensionsAndPdf: 'Upload file format should be a normal picture or PDF format',
    simplePsd: 'Your Password is too simple',
    mailCannotRepeat: 'Company and merchant login email cannot be same',
    errorCaptchaCode : 'Wrong Verification Code',
    kata : 'Please enter half-width Katakana',
    kata1 : 'Please enter a pseudonym',
    firstMail : 'Please enter your email ',
    password: 'Please enter password',
    passwordAgin: 'Please enter password again ',
    mailAgin: 'Please enter email again '
  },
  enterprise: {
    tip: 'If you would like to change company profile, please contact customer service',
    applyForChannel: 'Channel application',
    registeredChannelInfo: 'Reviewed Channel',
    applyChannelInfo: 'Pending Review Channel',
    toApplyNewChannel: 'New Channel',
  },
  channel: {
    editTitle: 'Modify Channel',
    addTitle: 'Add Channel',
    id: 'Channel ID',
    qf: 'QianFang',
    channel: 'Channel',
    bankingOrg: 'Payment Channel',
    currency: 'Currency ',
    rate: 'Fees',
    rateUnit: '%',
    withdrawCycle: 'Settlement Cycle',
    withdrawCycleUnit: 'per day',
    withdrawAmount: 'Maximum of Single Settlement  ',
    withdrawAmountUnit: 'per unit',
    applySuccess: 'Your application has been submitted, please wait for the result',
    comment: 'Remark',
    applicationDate: 'Application Date',
    rejectReason: 'Reject Reason',
    typeEnum: {
      ALIPAY: 'AliPay',
      WECHAT: 'WeChat Pay',
    },
    toApply: 'New Channel',
    invalidFeeRate: 'Fees cannot be lower than your sign-up fees',
    nullChannel: "You haven't enable current channel",
    feeRateTooLarge: 'Fees cannot be higher than 6% ',
    type: 'Payment method',
    repeatApplyError: 'Every channel only can apply once',
  },
  tips: {
    confirmToDelete: 'Confirm delete ',
    needToReWrite: 'Rejected, please contact user to re-submit again ',
    noPremission: 'This account is not authorised',
    reviewInEmail: 'Please check your email, change the password once confirmed',
    modifySuccess: 'Password change successfully ',
    channelExist: 'This account alreaddy have the channel, cannot enable repeatly ',
    confirmPassed: 'confirm pass? ',
    rejectStore: 'Reject application ',
    confirmToResetPsd: 'Send the reset password email to this account? ',
    actionSuccess : 'Completed',
    confirmRescindTip: 'Confirm termination with ${name}? Registration time: ${createDate}, termination time: ${now}.',
    confirmRescindEnterpriseTip: 'All stores under the enterprise will be terminated at the same time',
    confirmRenewalTip: 'Confirm the recovery contract with ${name}?',
    confirmRenewalEnterpriseTip: 'All stores under the enterprise will be restored at the same time',
  },
  message: {
    readed: 'Read',
    unread: 'Unread',
    readAll: 'Check All ',
  },
  pagination: {
    prev: 'Next Page',
    next: 'Previous Page',
    each: 'Total per Page',
    total: 'Total Pages',
  },
  currency: {
    CNY: 'RMB',
    USD: 'USD',
    JPY: 'JPY',
  },
  bank: {
    ALIPAY: 'AliPay',
    WECHAT: 'WeChat Pay',
    UNKNOWN: 'Unknown',
    ZERO: 'Zero',
  },
  settlement: {
    amountInThisMonth: 'Settlement Amount of this month',
    pay: 'Payment',
    status: 'Settlement Status',
    status_unsettlement: 'Pending Settlement',
    status_settled: 'Already Settled',
    status_unsettlement_csv: 'Auto Transfer File',
    settlementTime: 'Settlement Date',
    payeeName: 'Recipient Name ',
    payeeAccount: 'Recipient Account Number',
    payeeBankName: 'Recipient Bank Name',
    bankBranch: 'Bank Branch ',
    channel: 'Channel',
    tradeAmount: 'Transaction Amount',
    feeRate: 'Fees',
    channelFee: 'Channel Fees',
    systemFee: 'System Fees',
    serviceFee: 'Service Fees',
    otherFee: 'Other Fees',
    finalFee: 'Actual Settlement Amount',
    storeName: 'Branch Name',
    doSettle: 'Settlement',
    enterpriseName: 'Company Name',
    userType: 'User Type',
    settlementRange: 'Settlement Cycle',
    settlementFee: 'Estimated Settlement Amount',
    settlementInfo: 'Settlement Information',
    settlementSuccess: 'Settlement Complete',
    doSettleTime: 'Operating Time',
    operator: 'Operator',
    exportUnsettlement: 'Export Auto-transfer file',
    transferDate: 'Transfer Date',
    ISOName: 'First-tier Agent Name',
    commission: 'Payable Commission',
    agentCommission: 'Receivable Commission',
    QFPayCommission: 'QFPayReceivable Commission',
    agent: 'Salesperson ',
    agencyName: 'Agent Name',
    bankInfo: 'Bank Info',
    bankCode: 'Bank Code',
    bankName: 'Bank Name',
    branchBankCode: 'Bank Branch Code',
    branchBankName: 'Bank Branch Name',
    accountType: 'Account Type',
    accountName: 'Account Name',
    accountCode: 'Account Number',
    mineCommission: 'Our commission',
    stat: {
      totalIncome: 'Total ',
      totalPay: 'Payable Commission',
      dividends: 'Receivable Commission',
      title: 'Clearing Statistics',
    }
  },
  profitManage: {
    profitTitle: 'Revenue This Month',
    storeTopFive: 'Top 5 merchant',
    profitAccount:'Wallet Revenue Statistics',
    trendAccount:'Revenue Statistics',
    salesClerk:'Top 5 Agent/Salesperson',
    profitShop:'Merchant',
    allEarnings:'Total Profit',
    otherAgentString:'Other Agent',
    day_30:'Last 30 days',
    day_7:'Last 7 days ',
    liquidationTitle:'Revenue Query',
    lowerLevelEarnings:'Sub-tier Revenue Query ',
    agencyLevel:'Agent Category',
    all:'All ',
    agencyLevel_1:'First-tier Agent',
    agencyLevel_2:'Second-tier Agent ',
    agencyLevel_3:'Third-tier Agent ',
    agent:'Salesperson ',
    keyword:'Keyword',
    keywordDefaultValue:'Please enter Agent Name',
    monthScope:'Month Range',
    query:'Query ',
    reset:'Reset',
    myEarnings:'Own Revenue Query ',
    index:'Serial Number',
    agencyName:'Agent Name',
    name : 'Agent Name',
    month:'Month',
    earnings:'Profit',
    meAndLowerEarnings: 'Total Income (include sub-tier)',
    phone:'Phone Number',
    op:'Operate',
    exportDetail:'Export Details',
    fileName:'Revenue Details',
    profitName:'Revenue Management ',
    count: 'unit',
    totalEarning:'Total revenue',
    otherAgent:'Other'
  },
  resetPsd: {
    mailSended: 'An email has been sent out, please inform the account owner to modify  the password',
  },
  header: {
    oldPsd: 'Please enter old password',
    newPsd: 'Please enter new password',
    confirmPsd: 'Please enter new password again ',
    editPsdBtnOk: 'Confirm',
    editPsdBtnCancle: 'Cancel',
  },

  StoreManage:{
    tableTitle:'List of Merchants',
    Title:'Merchant Management',
    TabTitle:'Operation Mangement',
    MesWrite:"Merchant's Info Edit",
    TodayAccount:"Today's no of Merchant",
    AccountMonth:'Month-to-date Merchant',
    AccountNum:'Total no of Merchant',
    AccountWait:'Pending',
    AccountWaitPass:'Passed',
    AccountToday:'Total of Store - Top 2',
    AccountUp:'Most Performed',
    AccountDown:'Most Declined ',
    AccountProfit:'Most Merchant',
    Package:'Wallet Income TOP5',
    StoreList:'Overview of Merchant',
    AddStore:'Add Merchant',
    Sign:'Sing-up Merchant',
    StoreClass:'Merchant Type',
    SignWait:'Pending to Submit Merchant',
    Key:'Please enter keyword',
    Search:'Query ',
    Reset:'Reset',
    All:'Total ',
    AllDetail:'Row,Company Count',
    AllEnd:'Merchant Count',
    StoreNumber:'Company Count',
    Submit:'Submit',
    TableDetail:'Details',
    TableWrite:'Edit',
    SignTime:'Signed date',
    SignCard:'Wallet',
    CardWait:'Pending Review Channel',
    Address:'Address',
    StoreName:'Merchant Name',
    StoreLevel:'Level Relationship',
    StoreStatus:'Status',
    Action:'Operate',
    MessageAll:'Complete information',
    MessageError:'Missing information',
    DownloadMoke:'Download Template',
    AddAll:'Bulk Add',
    ChooseAdd:'Select Registration Type',
    ChooseClass:'Account Type:',
    ClassPerson:'Personal',
    ClassStore:'Corporate',
    StoreMessage:'Corporate Account Info',
    StoreMessageLabel:'Corporate ID (Email)：',
    male:'Male',
    female:'Female',
    yes:'Yes',
    no:'No',
    StoreRate:'Merchant Discount Rate',
    Please:'Please enter fees rates',
    PendingApproval:"Pending",
    Rescind: "Rescind",
    Normal:"normal",
    Reviewed:"Reviewed",
    awaitHandle: 'Rates pending',
    AddChannel:'New Channel',
    ChannelMes:'New channels of information',
    ChoosePlease:'please choose',
    StartTime:'Start Time',
    EndTime:'End Time',
    LogMail:'Login Email',
    export:'Export',
    Save :"Save",
    ChooseTime:"Select time",
    loginId: 'Login ID:',
    feerateRule : 'The rate must meet the following conditions: Lower organization rate>= Upper organization rate',
    appliFeerateRule : 'The rate must meet the following conditions: Lower organization rate>= Upper organization rate,Channel rates are lower than agency rates.',
    crossLevelNow : 'You are being reviewed across levels, please confirm.',
    awaitHandleChannel : 'Rates pending channels',
    state : 'Channel Status.',
    differExplain : 'Agency rates for this channel are different from their contracted rates, please confirm.',
    edit : 'Edit',
    rescind: 'Rescind',
    renewal: "Renewal",
  },
  StoreAdd: {
    SelectRegistrationType:'Select Registration Type',
    AccountType:'Account Type:',
    LegalPerson:'Corporate ',
    Personal:'Personal',
    EnterpriseInfo:'Corporate Information',
    EnterpriseLoginId:'Corporate ID',
    FillEnterpriseLoginId:'Please enter Corporate ID!',
    man:'Male',
    woman:'Female',
    yes: 'Yes',
    no: 'No',
    submit: 'Submit',
    rate:'Please enter fees rates',
    storeRate:'Merchant Discount Rate',
    storeChannel : 'Please select your channel',
  },
  ModalMessage: {
    confirmTitle:'Add under this company? ',
    confirmContent:'This company login already exists, do you want to add the merchant under this company? ',
    Cancel:'Cancel',
    Ok:'Confirm',
    mailExist:'This email has been used',
    onlyChoose:'Please select at least one channel ',
    WriteRate:'Please enter the fees of the chosen channel(s) ',
    AccountError:'Account Error',
    NoRate:'No Channel',
    RightNo:'Please enter correct numbers',
    MessageError:'Wrong information ',
    need:'Mandatory ',
    check:'Please enter correct email ',
    MessageNoAll:'Merchant infromation is not complete',
    MessageNoAllStore:'Company information is not complete',
    MessageNoAllInfo:'Please fill in all the information.',
    ForgetMail:'Your email is not registered',
    ForgetMailDetail:'Please confirm email before entering',
    ForgetMailSend:'Email already sent to your mailbox',
    ForgetMailSendDetail:'Please check your email, change the password once confirmed！',
    NotSame:'Company email and merchan email are same!',
    RateGreater:'Channel fees is less than superior fees',
    UnExist:"Agent didn't choose this channel",
    RateError:'Wrong fees in the channel',
    AddSuccess:'Adding successfully',
    AddError:'Adding failed ',
    NoAddInfo:"No information to import",
    imgTypeError:"Only JPG/PNG picture format is allowed",
    imgBigError:"Maximum can upload 2MB ",
    imgLengthError:"The number of picture uploads exceeds the limit!",
    fileTypeError:"Only JPG/PNG/PDF file format is allowed",
    fileBigError:"Maximum can upload 2MB ",
    fileLengthError:"The number of file uploads exceeds the limit!",
    SaveSuccess:"Save successfully",
    errorInfo:"Please correct your store information to reapply for the payment channel.",
    ConfirmDoc:"Please use the most recent document.",
  },
  StoreDetail:{
    Title:'Merchant Management',
    Detail:"Merchant's Info",
    channelDetail:'Channel Information',
    StoreName:'Merchant',
    StoreNum:'Merchant Count',
    StoreEarn:'Fees Income',
    StoreNumber:'No of Cashier',
    examinePass:'Reviewed Payment Channel Fee',
    StoreMessage:"Merchant's Info",
    CompanyMessage:'Corporate Information',
    StoreExamine:'Reviewed Channel',
    RateClass:'Currency ',
    Rate:'Store rates',
    RateCompany:'Acquirnig Company',
    StoreElse:'Pending Review Channel',
    superWaitHandle : 'Channel confirmation in progress.',
    ownWaitHandle : 'Channel rates pending.',
    StoreActive:'Active Rate of Merchant',
    Company:'Enterprise',
    ownFeerate: 'Your Rate',
    ISORate: 'First-tier agency rates',
    ISVOneRate : 'Second-tier agency rates',
    ISVTwoRate : 'Third-tier agency rates',
    agentRate : 'Salesperson rates',
    channelRate : 'Channel-side rates',
    notHave : 'Pending',
    channel: 'Channel',
    StoreElsePop : "Awaiting the store manager's rate review.",
    superWaitHandlePop : "Awaiting a senior agency's rate review.",
    ownWaitHandlePop:'Channel rates need to be reviewed.',
  },
  MessageDownLoad:{
    type:'Downloads',
    messageManagent:'Downloads',
    messageList:'Downloads',
    total:'Total',
    count:'Row',
    title:'Channel name',
    createDate:'Announce Time',
    content:'Channel logo',
    detail:'Details',
    checkDetail:'Check Details',
    messageDetail:'Information details',
    loadDetail: 'For more information, please download the attached file.',
  },
  MessageNotice:{
    type:'Notification Type',
    store:'Merchant Management',
    agent:'Agent Management',
    profit:'Revenue Management ',
    level:'Notification level',
    warning:'Warning',
    task:'Task',
    notice:'Notice',
    title:'title',
    createDate:'Announce Time',
    content:'Content',
    detail:'Details',
    checkDetail:'Check Details',
    messageManagent:'Notification Mgmt',
    screen:'Selection',
    messageList:'Notification Overview',
    total:'Total ',
    count:'Row',
    createNotice:'New Notification',

    messageDetail:'Notification Detail ',
    send:'Send to ',

    sendTarget:'Sending Target',
    agentGroup:'Agent Group',
    agents:'Group Member',
    messageContent:'Notification content',
    messageTitle:'Notification title',
    chooseGroup:'Please select Agent Group',
    titleTips:'Please enter Enter titlem, maximum of 16 characters',
    contentTips:'Please enter The content',
    submit:'Announce',
    back:'Return',

    groupManagent:'Group Mgmt',
    groupList:'Group Overview',
    createGroup:'New Group',
    chosseAgents:'Select Group Member',
    save :"Save",
    cancel:'Cancel',
    groupInfo:'Group Info',
    groupTitle:'Group Title',
    groupRemark:'Group Note',
    remarkTips:'Can enter group details and information',
    operate: 'Operate',
    edit : 'Edit',
    agentManagement: 'Salesperson Management',
    readStatus:'Check Status',
    isRead:'Read',
    noRead:'Unread',
    chooseAgents:'Please select Agent Group member!'
  },
  agencyAsyncFieldPlaceholder: {
    'Agency Name(S)': 'eg：株式会社〇〇〇〇',
    'Name of Person in Charge': 'eg：藤本太郎',
    'Phone of Person in Charge': 'eg：01-3987-3298',
    'E-Mail Address of Person in Charge': '：fujimoto@xxx.com'
  },
  errorCode: {
    403041: 'Not authorised',
    40401: 'Login doesn’t exist',
    40405: "Couldn't find the record",
    40601: 'Wrong Parameters',
    40602: 'Verification Error',
    40603: 'Login ID does not match with Email ',
    40605: 'Expired Token',
    50001: 'Connectivity to 3rd party server has gone wrong, please try again ',
    50002: 'Error in the merchant update  ',
    50003: 'Error in adding the agent, please try again ',
    50004: 'Error in adding the salesperson, please try again',
    50005: 'Error in adding the channel, please try again',
    50006: 'Error in adding the channel, please try again',
    50007: 'Error in updating the channel, please try again',
    50008: 'Error in updating the branch, please try again',
    50009: 'Adding branch, please try again ',
    50010: 'Adding bank information, please try again ',
    50011: 'Update bank account information, please try again ',
    50012: 'Error in updating the company information, please try again ',
    50013: 'Error in adding the company, please try again ',
    50014: 'Error in adding the company information, please try again',
    50015: 'Login doesn’t exist',
    50016: 'Wrong Password',
    50017: 'Error in updating new agent',
    50018: 'Channel already applied',
    60001: 'Channel fees must greater than superior-tier',
    70001: 'Adding failed ,please try again ',
    70002: 'Update failed,please try again ',
  },
  businessCategory: {
    ShoesGarments: 'Shoes&Garments',
    ComprehensiveMall: 'Comprehensive mall',
    Food: 'Food',
    Cosmetics: 'Cosmetics',
    MaternalInfant: 'Maternal and infant',
    DigitalAppliance: 'Digital appliance',
    Logistics: 'Logistics',
    EducationIndustry: 'Education Industry',
    HotelIndustry: 'Hotel Industry',
    StationeryOfficeSupplies: 'Stationery/office supplies',
    AirTicket: 'Air Ticket',
    OverseasEducation: 'Overseas Education',
    TravelTicket: 'Travel ticket',
    CarRental: 'Car rental',
    InternationalConference: 'International Conference',
    Software: 'Software',
    MedicalService: 'Medical Service',
    OtherTradeIndustry: 'Other trade industry',
  },
};
