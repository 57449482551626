import Loadable from 'react-loadable';
import { LCLoading } from 'control/loading';
import { AuthorizedRouter } from 'acl';
import { Route, Switch } from 'react-router-dom';
import React from 'react';

const AgentList = Loadable({
  loader: () => import('./AgentList'),
  loading: LCLoading,
});

const Agentinvitation = Loadable({
  loader: () => import('./Agentinvitation'),
  loading: LCLoading,
});

const Agentcreate = Loadable({
  loader: () => import('./Agentcreate'),
  loading: LCLoading,
});

const Agentaudit = Loadable({
  loader: () => import('./Agentaudit'),
  loading: LCLoading,
});

const Agentregistration = Loadable({
  loader: () => import('./Agentregistration'),
  loading: LCLoading,
});

const AgentDetail = Loadable({
  loader: () => import('./AgentDetail'),
  loading: LCLoading,
});

const AgentEdit = Loadable({
  loader: () => import('./AgentEdit'),
  loading: LCLoading,
});

const AgentRoute = ({ match }) => {
  // console.log(match.path);
  return (
    <Switch>
      {/* <Route exact path={`${match.path}/new`} component={AgentAdd}/> */}
      <Route exact path={`${match.path}/agentdetail/:id`} component={AgentDetail}/>
      <Route exact path={`${match.path}`} component={AgentList} />
      <Route exact path={`${match.path}/agentinvitation`} component={Agentinvitation} />
      <Route exact path={`${match.path}/agentcreate`} component={Agentcreate} />
      <Route exact path={`${match.path}/agentaudit/:id`} component={Agentaudit} />
      <Route exact path={`${match.path}/agentregistration`} component={Agentregistration} />
      <Route exact path={`${match.path}/agentedit/:id`} component={AgentEdit} />

    </Switch>
  );
}
export { AgentRoute };
