import Loadable from 'react-loadable';
import { LCLoading } from 'control/loading';
import { AuthorizedRouter } from 'acl';
import { Route, Switch } from 'react-router-dom';
import React from 'react';

const QfpayTostoremanagement = Loadable({
  loader: () => import('./QfpayTostoremanagement'),
  loading: LCLoading,
});

const QfpayToAgentregistration = Loadable({
  loader: () => import('./QfpayToAgentregistration'),
  loading: LCLoading,
});


export { QfpayTostoremanagement, QfpayToAgentregistration };


