const Arrays = {
  findInsertionIndex: function(item, items, comparatorFn, index) {
    var len = items.length,
        beforeCheck, afterCheck;

    comparatorFn = comparatorFn || Arrays.lexicalCompare;

    if (index < len) {
        beforeCheck = index > 0 ? comparatorFn(items[index - 1], item) : 0;
        afterCheck = index < len - 1 ? comparatorFn(item, items[index]) : 0;
        if (beforeCheck < 1 && afterCheck < 1) {
            return index;
        }
    }

    return Arrays.binarySearch(items, item, comparatorFn);
  },
  binarySearch: function (array, item, begin, end, compareFn) {
    var length = array.length,
        middle, comparison;

    if (begin instanceof Function) {
        compareFn = begin;
        begin = 0;
        end = length;
    } else if (end instanceof Function) {
        compareFn = end;
        end = length;
    } else {
        if (begin === undefined) {
            begin = 0;
        }
        if (end === undefined) {
            end = length;
        }
        compareFn = compareFn || Arrays.lexicalCompare;
    }

    --end;

    while (begin <= end) {
        middle = (begin + end) >> 1;
        comparison = compareFn(item, array[middle]);
        if (comparison >= 0) {
            begin = middle + 1;
        } else if (comparison < 0) {
            end = middle - 1;
        }
    }

    return begin;
  },
  lexicalCompare: function(lhs, rhs) {
    lhs = String(lhs);
    rhs = String(rhs);

    return (lhs < rhs) ? -1 : ((lhs > rhs) ? 1 : 0);
  }
}
export default Arrays;
