import Loadable from 'react-loadable';
import { LCLoading } from 'control/loading';
import { AuthorizedRouter } from 'acl';
import { Route, Switch } from 'react-router-dom';
import React from 'react';

const MerchantList = Loadable({
  loader: () => import('./MerchantList'),
  loading: LCLoading,
});

const MerchantRegister = Loadable({
  loader: () => import('./register/MerchantRegister'),
  loading: LCLoading,
});

const MerchantRegisterMore = Loadable({
  loader: () => import('./register/MerchantRegisterMore'),
  loading: LCLoading,
});

const MerchantRegisterMoreResubmit = Loadable({
  loader: () => import('./register/MerchantRegisterMoreResubmit'),
  loading: LCLoading,
});

const MerchantRoute = ({ match }) => {

  return (
    <Switch>
      <Route exact path={`${match.path}/register`} component={MerchantRegister} />
      <Route exact path={`${match.path}/registerMore`} component={MerchantRegisterMore} />
      <Route exact path={`${match.path}/registerMoreResubmit`} component={MerchantRegisterMoreResubmit} />
      <Route exact path={`${match.path}`} component={MerchantList} />
    </Switch>
  );
}
export { MerchantRoute };
