export default {
  l: 'zh-CN',
  param: 'zh_CN',
  lang: '简体中文',
  langSelection: '语言选择  ',
  brand: '营业管理系统',
  searchMore: '更多搜索',
  search: '搜索',
  modify: '修改',
  delete: '删除',
  export: '导出',
  all: '全部',
  complete: '完成',
  cancel: '取消',
  add: '添加',
  back: '返回',
  logout: '  退出',
  logoutConfirm: '确认退出系统吗？',
  submit: '提交',
  resubmit: '再次提交',
  yes: '是',
  no: '否',
  psdError: '您输入的密码不正确',
  noWebLoginAccess: '抱歉，您没有登录权限',
  operate: '操作',
  nextStep: '下一步',
  detail: '详情',
  modifyPsd: '更新密码',
  repeatLogin: '您的账号正在另一个设备登录',
  thatQrcode: '二维码',
  thatQrcodeHover: '商户扫描二维码，可携带该账号信息进行注册',
  noAuth: '您没有权限',
  none: '無し',
  certificateOfIncorporation: '营业执照',
  total: '共',
  count: '条',
  noData: '暂无数据',
  choose : '请选择',
  home: {
    title: '首页',
    sevenDayEarnings: '最近七天利润',
    thirtyDayEarnings: '最近30天利润',
    sevenDay: '最近七天',
    thirtyDay: '最近30天',
    todaySignNum: '今日累计商户数量',
    monthSignNum: '本月累计',
    allSignNum: '总商户数量',
    waitHandle : '费率待处理',
    notBeenSubmitted: '待提交',
    inTheReview: '审核中',
    haveBeenThrough: '审核通过',
    auditFailure: '审核失败',
    storeSignTrend: '签约商户走势图',
    agencyTOP3: '代理店TOP3排行榜',
    agentTOP3: '营业人员TOP3排行榜',
    storeTOP3: '店铺TOP3排行榜',
    growthMost: '增幅最多',
    dropMost: '跌幅最多',
    signNumberMost: '签约商户最多',
    totalNumberMost: '收益最多',
    earnings: '利润占比',
    signPercentage: '已通过审核的商户占比',
    signNum: '签约商户数',
    top3Detail1:'当前代理top3排行榜为您展示旗下各级代理在签约商户增幅、签约商户跌幅以及签约商户数量方面的周排行变化。周期为上周与上上周的数据对比。',
    top3Detail2:'当前营业人员top3排行榜为您展示旗下各级代理在签约商户增幅、签约商户跌幅以及签约商户数量方面的周排行变化。周期为上周与上上周的数据对比。',
    top3Detail3:'当前店铺top3排行榜为您展示旗下各级代理在签约商户增幅、签约商户跌幅以及店铺收益最多方面的周排行变化。周期为上周与上上周的数据对比。',
    top3Detail4:'当前店铺统计top2为您展示旗下各级代理在签约商户增幅、签约商户跌幅以及店铺收益最多方面的周排行变化。周期为上周与上上周的数据对比',
    top3Detail5:'钱包收入top5排行为您展示由您所开通的各钱包渠道而产生交易额最多的前五位。',

  },
  login: {
    welcome:'欢迎登录',
    id: '登录ID',
    idPlaceholder: '请输入登录ID',
    storeownerEmail: '店主邮箱',
    psd: '密码',
    psdPlaceholder: '请输入密码',
    workNumber: '工号',
    workNumberPlaceholder: '请输入工号',
    autoLogin: '自动登录',
    shopkeeperLogin: '店长',
    employeeLogin: '员工',
    forgetPsd: '忘记密码',
    submit: '登 录',
    emailSend:'发送',
    noLoginIdTip: '还没有账号,',
    registerNow: '立即注册',
    mail: '注册邮箱',
    forgetMail:'登录邮箱',
    mailPlaceholder: '请输入邮箱地址',
    findPassword:'找回密码',
    Message:'请使用至少8个字符。不要使用您用于登录其他网站的密码，也不要使用很容易被猜到的密码（例如，123abc）。',
    remind:'想起密码',
    goLog:'去登录',
    newPad : '新密码',
    newPadConfirm: '确认新密码'
  },
  qrCodeRegister:{
    newtitle:'添加商户',
    brdtitleStep1:'企业信息注册',
    brdtitleStep2:'店铺信息注册',
    brdtitleStep3:'完成',
    entertitle:'企业信息注册',
    storetitle:'店铺信息注册',
    next:'下一步',
    commit:'已提交!',
    committext:'你所填写的注册信息已提交,审核通过后将以邮件形式通知',
    waitmail:'请耐心等待审核......',

    enterprisemail:'企业邮箱',
    enterprisemailconfim:'再次输入企业邮箱',
    enterprisename:'公司名称',
    enterprisetype:'商户种类',
    companycode:'法人号码',
    accountphone:'营业担当者电话',

    storemail:'店铺邮箱',
    storemailconfim:'再次输入店铺邮箱',
    storename:'实际店铺名称',
    prefecture:'都道府县',
    municipalward:'市区町村',
    detailadress:'详细地址',
    streetnumber:'建筑名称・门牌号',
    zipcode:'邮政编码',
    businesscode:'担当者姓氏',
    businessphone:'担当者电话',

    enterprisemailPlaceholder:'例：qfpay@qfpay.com',
    enterprisemailconfimPlaceholder:'请再次输入企业邮箱',
    enterprisenamePlaceholder:'请输入公司名称',
    enterprisetypePlaceholder:'请输入商户所属种类',
    companycodePlaceholder:'0100-01-178407',
    accountphonePlaceholder:'090-1374-1287',

    storemailPlaceholder:'例：qfpay@qfpay.com',
    storemailconfimPlaceholder:'请再次输入店铺邮箱',
    storenamePlaceholder:'请输入实际店铺名称',
    prefecturePlaceholder:'東京都',
    municipalwardPlaceholder:'港区',
    detailadressPlaceholder:'愛宕グリーンヒルズMORIタワー38F',
    streetnumberPlaceholder:'2-5-1',
    zipcodePlaceholder:'160-0023',
    businesscodePlaceholder:'佐藤太郎',
    businessphonePlaceholder:'090-1374-1287',
    registerInfo1:'填写注册信息需要花费您5-8分钟时间,若中途退出,填写过的信息将无法保存,请您预留好时间',
    registerInfo2:'以便收集信息.',
  },
  register: {
    newtitle:'代理商注册',
    mailtitle:'邮箱验证',
    title: '注册账号',
    mail: '注册邮箱',
    agentInformation:'代理商信息',
    success:'完成',
    mailPlaceholder: '例：qfpay@qfpay.com',
    mailConfirm: '确认邮箱',
    mailConfirmPlaceholder: '请再次输入邮箱',
    captcha: '获取验证码',
    newcaptcha:'验证码',
    captchaPlaceholder: '请输入邮箱中的验证码，如：k2j4',
    termsOfService: '服务条款',
    privacyRelatedPolicies: '隐私政策',
    privacyRelatedPoliciesandtermsOfService:'隐私政策、服务条款',
    agree: '同意',
    and: '和',
    submit: '注册',
    hasLoginId: '已有账号',
    toLogin: '去登录',
    psd: '输入密码',
    psd2:"******",
    psdPlaceholder: '请输入密码',
    psdConfirm: '确认密码',
    psdConfirmPlaceholder: '请再次输入密码',
    loginId: '登录ID',
    phone: '手机号码',
    name: '名字',
    mailVerifyInvalid: '该验证链接已失效',
    waitingTip: '您提交的申请正在审核中，我们将在1-2个工作日内处理完毕，请耐心等待。',
    failureTip: '很抱歉，您提交的商户信息有部分信息未审核通过，请您编辑后重新提交。',
    errorCaptcha: '错误的验证码',
    phonePlaceholder: '例：090-1374-1287',
    namePlaceholder: '例：佐藤太郎',
    agencyNamePlaceholder: '例：株式会社〇〇〇〇',
    agencyName: '代理店名',
    privacyTip: '请您在申请账号之前完成与QFPAY的相关契约',
    next:'下一步',
    invitationCode:'邀请码',
    invitationcode2:'请输入邀请码',
    mail2: '邮箱',
    mailPlaceholder2: '邮箱',
    mailPlaceholderStore: '店铺用户登录ID',
    mailPlaceholderEnterprise: '企业用户登录ID',
    storeUserLoginMail: '店铺登录邮箱',
    defaultPassword: '初始密码为123456',
    theDefaultPassword: '默认密码',
    captchaPlaceholder: '验证码',
    storeInfo: '店铺信息',
    attachedStoreInfo: '附带店铺信息',
    storeName: '店铺名称',
    storeAddress: '店铺地址',
    storeEmail: '店铺邮箱',
    storeTel: '店铺联系电话',
    bankInfo: '银行信息',
    bankCode: '银行编号',
    bankName: '银行名称',
    branchBankCode: '银行分行编号',
    branchBankName: '银行分行名称',
    accountType: '账户类型',
    accountName: '银行账户名称',
    accountCode: '银行账号',
    settlementCurrency: '结算币种',
    storeNamePlaceholder: '店铺名称',
    storeAddressPlaceholder: '店铺地址',
    storeEmailPlaceholder: '店铺邮箱',
    storeTelPlaceholder: '店铺联系电话',
    bankCodePlaceholder: '银行编号',
    bankNamePlaceholder: '银行名称',
    branchBankCodePlaceholder: '银行分行编号',
    branchBankNamePlaceholder: '银行分行名称',
    accountTypePlaceholder: '账户类型',
    accountNamePlaceholder: '银行账户名称',
    accountCodePlaceholder: '银行账号',
    psdPlaceholder: '请输入6-8位数字与字母组合',
    isDevicePurchase: '设备是否需要购买',
    next:'下一步',
    loadingmore:'加载更多',
  },

  registerAftermail:{
    agentManagement: '代理商管理',
    homePagenew:'代理商主页',
    create: '创建代理',
    mailtitle:'邮箱验证',
    agentInformation:'代理商信息',
    success:'完成',
    mailRegistration:'邮箱注册',
    captcha: '获取验证码',
    newcaptcha:'验证码:',
    bankInfo: '银行信息',
    bankName : '银行名称',
    bankCode : '银行编号',
    bankType:'银行种类',
    branchName : '支行名称',
    branchnumber:'支行编号',
    Subbranchtype:'支行类型',
    Accounttype:'账户类型',
    Bankaccountname:'银行账户名称(半角片假名)',
    Bankaccountname2:'银行账户名称',
    Bankaccount:'银行账号',
    psd: '输入密码',
    psdConfirm: '确认密码',
    bankInfo: '银行信息',
    ChannelAddition:'渠道添加',
    submit:'提交',
    man:'男',
    woman:'女',
    mailtitle:'邮箱验证',
    agentInformation:'代理商信息',
    corporateInformation:'法人信息',
    Contacts:'联系人信息',
    undertaker:'担当者情报',
    success:'完成',
    Preservation:'保存已填写内容',
    trueEmail: '请输入正确的邮箱!',
    filEmail:'请填写邮箱',
    psd:'密码',
    popcontent:'填写过程退出程序记得保存，再次登录可继续填写',
    fillcode:'请填写验证码',
    psdPlaceholder: '请输入密码!',
    psdConfirmPlaceholder: '请再次输入密码!',
    fillInformation:'请填写信息',
    fillNumber:'请填写号码',
    filEmail:'请填写邮箱',
    rate:'请填写费率',
    addTips:'（提示：默认开通所有渠道）',
    toLogin1:'已有帐号，',
    toLogin2:'去登陆',
    picDetail:'请上传清晰、实拍的图片，请不要在图片上添加文字、数字、网址等内容，请勿上传名片、二维码、自拍照、风景照等无关图片，支持JPG、PNG格式，最多上传9张，每张最大2M',
    WeChatExplain:'是由中国最为通用的一款聊天软件“微信”来为您提供支付服务---“WeChat Pay”。',
    AlipayExplain:'“支付宝”以淘宝网络购物为开端，受到全球10亿余（※统计至2019年1月）用户的青睐，是堪称世界第三的支付服务平台。',
    OrigamiExplain:'Origami以“创造金钱、支付、和商业的未来”为时代理念，提供无现金支付平台。',
    PaypayExplain:'是由软银和雅虎日本携手打造的一款手机支付软件---PayPay。',
    NetspayExplain:'新加坡三大银行皆可进行二维码支付的一款便捷服务。',
    RakutenpayExplain:'乐天支付，即便您不在乐天，只要手持乐天ID就可随时随地进行便捷支付。',
    UmspayExplain:'银联（UnionPay），确立了继国际5大经济品牌（VISA、MASTER、JCB、AMERICAN EXPRESS、DINERS CLUB）的第六国际品牌地位。',
    VIAExplain:'可使用新加坡的Dash、泰国的Global Pay的一款支付服务。',
    DocomoExplain:'docomo支付，是一款可以将大街小巷网络支付与手机充值合并结算、简单而便捷、实惠的手机支付服务。',
    PringExplain:'pring，是一款将金钱交易以如同亲朋好友间消息发送的往来方式进行支付的手机软件。',
    SolPayExpain: '韩国国内排行第二位的新韩银行所提供的QR服务',
    GMOExpalin : '专精线上交易的gateway系统',
    DiXingExpalin : '提供高效的跨境支付与银行会员服务',
    tBTCExplain: '这是一种ERC20代币,得到可靠的支持并可兑换成比特币。',
    BTCExplain: '比特币是一个共识网络，促成了一个全新的支付系统和一种完全数字化的货币。它是第一个去中心化的对等支付网络，由其用户自己掌控而无须中央管理机构或中间人。从用户的角度来看，比特币很像互联网的现金。比特币也可以看作是目前最杰出的三式簿记系统。',
    ACDExplain: 'ACD COIN同航空公司、EC平台进行合作，应用于全球线上、线下商铺。',
    ContentPic1:'個人事業主の場合は、必ず営業許可証が必要になります。 (写真/スキャンデーター)',
    ContentPic2:'振込口座が確認できるページの写真/スキャンデーター',

  },


  registerMore: {
    belongToEInfo: '所属公司信息',
    belongToEName: '所属企业名称',
    hasEnterprise: '所属企业是否已注册',
    has: '有',
    not: '无',
    privacy: '店铺已经同意了QFPay的服务条款'
  },
  registerMailSended: {
    title: '注册邮箱验证',
    content1: '一封邮件已发送到您的邮箱',
    content2: '请及时登录邮箱查收邮件，点击激活链接进入注册信息填写。'
  },

  agentregistration:{
    newtitle:'代理商注册',
    mailRegistration:'邮箱注册',
    agentInformation:'代理商信息',
    success:'完成',
    Submitted:' 已提交!',
    SubmissionDetails:'您所填写的注册信息已提交，',
    SubmissionDetails2:'请耐心等待审核...',
    loginto:'去登陆',
    backPage:'返回代理主页'
  },
  registerStep: {
    register: '注册账号',
    moreInfo: '完善信息',
    waitingReview: '等待审核',
  },
  revenue: {
    typeLabel: '查询分类',
    viewType: {
      iso: '代理商',
      agentManagement: '营业员'
    },
    search: {
      tradeTime: '交易时间',
      keywordPlaceholder: '请输入关键字',
    },
    key: '系统编号',
    isoName: '代理商名称',
    agencyCode: '代理商编号',
    cashFlow: '交易金额',
    superiorName: '上级代理商',
    agentId: '营业员系统编号',
    agentCode: '营业员编号',
    agentName: '营业员名称',
    parentAgency: '所属代理商',
    phone: '联系方式',
  },
  merchant: {
    managementTitle: '商户管理',
    statTitle: '商户统计',
    unReview: '待审核商户数',
    pendingReview: '审核中商户数',
    reviewFailure: '审核失败商户数',
    signedMerchant: '签约商户数',
    agentName: '代理商/营业员名称',
    addMerchant: '添加商户',
    myQrcode: '我的二维码',
    qrcodeHover: '商户扫描二维码，可携带您信息进行注册',
    download: '下载',
    registerTime: '注册时间',
    enterpriseName: '所属企业名',
    storeName: '分店名',
    type: '商户类型',
    address: '地址',
    channels: '当前渠道',
    reviewingChannels: '待审核渠道',
    status: '状态',
    typeEnum: {
      ENTERPRISE: '企业',
      SUB_STORE: '分店',
    },
    attachedTypeEnum: {
      ENTERPRISE: '企业',
      SUB_STORE: '附属分店',
    },
    statusEnum: {
      AWAITING_REVIEW: '待审核',
      TO_REVISE_MATERIAL: '审核失败',
      REVIEW_PASS: '审核通过',
      REVIEWING: '审核中',
    },
    search: {
      registerTime: '注册时间',
      keywords: '关键字搜索',
      keywordsPlaceholder: '请输入关键字',
    },
    // register: {
    //   mail: '邮箱',
    //   mailPlaceholder: '邮箱',
    //   mailPlaceholderStore: '店铺用户登录ID',
    //   mailPlaceholderEnterprise: '企业用户登录ID',
    //   storeUserLoginMail: '店铺登录邮箱',
    //   defaultPassword: '初始密码为123456',
    //   theDefaultPassword: '默认密码',
    //   captchaPlaceholder: '验证码',
    //   storeInfo: '店铺信息',
    //   attachedStoreInfo: '附带店铺信息',
    //   storeName: '店铺名称',
    //   storeAddress: '店铺地址',
    //   storeEmail: '店铺邮箱',
    //   storeTel: '店铺联系电话',
    //   bankInfo: '银行信息',
    //   bankCode: '银行编号',
    //   bankName: '银行名称',
    //   branchBankCode: '银行分行编号',
    //   branchBankName: '银行分行名称',
    //   accountType: '账户类型',
    //   accountName: '银行账户名称',
    //   accountCode: '银行账号',
    //   settlementCurrency: '结算币种',
    //   storeNamePlaceholder: '店铺名称',
    //   storeAddressPlaceholder: '店铺地址',
    //   storeEmailPlaceholder: '店铺邮箱',
    //   storeTelPlaceholder: '店铺联系电话',
    //   bankCodePlaceholder: '银行编号',
    //   bankNamePlaceholder: '银行名称',
    //   branchBankCodePlaceholder: '银行分行编号',
    //   branchBankNamePlaceholder: '银行分行名称',
    //   accountTypePlaceholder: '账户类型',
    //   accountNamePlaceholder: '银行账户名称',
    //   accountCodePlaceholder: '银行账号',
    //   psdPlaceholder: '请输入6-8位数字',
    //   isDevicePurchase: '设备是否需要购买',
    // },
    add: '添加商户',
    copy: '复制链接',
    complete: '您的二维码链接已复制到剪贴板！',
    hasBeendisabled: '已禁用',
  },
  merchantRevenue: {
    revenue: '交易金额',
    dividends: '我司分成',
    dividendsInMonth: '本月我司分成',
    showMore: '查看更多',
    title: '查看流水',
    merchantDividends: '商户提成',
    agencyDividends: '下级代理商提成',
    agentDividends: '营业员提成',
    search: {
      date: '查看时间',
      channel: '渠道筛选',
    }
  },
  footer: {
    concatInfo: '联系方式',
    concatMail: '客服邮箱',
    concatTel: '客服电话',
    businessHours: '营业时间',
    except: '休息日、节假日除外',
    useRole: '利用规约',
    privacy: '隐私协议',
    companyInfo: '公司信息',
    aboutUs: '关于我们',
    qfpay: 'qfpay日本运营',
    payMode:'支付',
    address:'住所：〒105-0001 東京都港区虎ノ門 1-10-5 KDX Toranomon 1 Chome 11F 株式会社QFPay Japan',
    payModeDetail:'QFPay支持的支付方式',
  },
  nav: {
    title: '首页',
    agentSettlementSelf: '清算管理',
    merchantManagement: '商户管理',
    agentManagement: '营业员管理',
    agencyManagement: '代理商管理',
    agencySettlement: '代理商清算',
    agentSettlement: '营业员清算',
    bankInfo: '签约信息',
    RevenueSearch: '代理商流水查询',
    ISOManagement: '一级代理商管理',
    ISV1Management: '二级代理商管理',
    ISV2Management: '三级代理商管理',
    ISOReview: '一级代理商审核',
    ISOSettlement: '一级代理商清算',
    Storemanagement:'店铺管理',
    am:'营业管理',
    Revenuemanagement:'收益管理',
    messageManagent:'通知管理',
    groupManagent:'群组管理',
    accountManage:'账户管理',
    infoManage:'信息管理',
    dataDownLoad:'资料下载'
  },
  agency: {
    agencyName: '代理商名称',
    agentCode: '代理商编号',
    new: '新增代理商',
    info: '用户信息',
  },
  agent: {
    agentCode: '营业员编号',
    new: '新增营业员',
    AgentTitle:'营业员一览',
    AgentName:'营业员管理',
    AgentAdd:'添加营业员',
    AgentDetail:'营业员详情',
    AgentLocal:'基本信息',
    AgentMail:'注册邮箱',
    AgentPhone:'手机号码',
    AgentNa:'担当名称',
    AgentNo:'营业员编号',
    currency:'结算货币',
    feerate:'费率',
    all:'共',
    one:'条',
    loginId: '登录ID',
  },
  bankInfo: {
    title: '银行信息',
    bankCode: '银行编号',
    bankName: '银行名称',
    settlementCurrency: '结算货币',
    accountType: '账户类型',
    accountName: '账户持有人姓名',
    accountCode: '账户编号',
    branchName: '银行分行名称',
    branchCode: '银行分行编号',
    noData: '您还没有创建银行信息',
    accountTypeEnum: {
      SAVINGS: '普通',
      CHECKING: '当座'
    },
    bankTypeEnum : {
      BANK : '银行',
      FINANCIAL_COOPERATIVE : '金融合作社',
      CREDIT_COOPERATIVE : '信用合作社',
      AGRICULTURAL_COOPERATIVE : '农业合作社'
    },
    branchTypeEnum : {
      BRANCH_STORE : '分店',
      HEAD_OFFICE : '总店',
      FIELD_OFFICE : '外地办事处',
      BRANCH_OFFICE : '分所'
    },
    bankNamePlaceholder: '例：三菱銀行',
    bankCodePlaceholder: '例：0005',
    bankTypePlaceholder: '例：銀行',
    branchBankNamePlaceholder: '例：新瑞橋支店',
    branchBankCodePlaceholder: '例：251',
    branchTypePlaceholder : '例：支店',
    accountTypePlaceholder: '例：普通預金',
    accountNameHalfKataPlaceholder :'例：カブシキガイシャ〇〇〇〇',
    accountNamePlaceholder: '例：株式会社〇〇〇〇',
    accountCodePlaceholder: '例：12414141',
  },
  companyInfo: {
    devicePurchaseType: {
      lease: '租借',
      buy: '购买',
      noBuy: '不购买',
    }
  },
  review: {
    search: {
      registerTime: '注册时间',
      keywords: '关键字搜索',
      keywordsPlaceholder: '请输入关键字',
    },
    statusEnum: {
      NORMAL : '已通过',
      AWAITING_REVIEW: '待审核',
      TO_REVISE_MATERIAL: '审核失败',
      NORMAL: '审核通过',
      TO_COMPLETE_MATERIAL: '未提交'
    },
    registerTime: '注册时间',
    name: '代理商名称',
    mail: '邮箱',
    audit: '审核',
    status: '状态',
    loginId: '登录ID',
  },
  isoManagement: {
    key: '系统编号',
    loginId: '登录ID',
    name: '代理商名称',
    mail: '邮箱',
    registeredChannel: '当前渠道',
    phone: '联系方式',
    resetPsd: '重置密码',
    search: {
      keywords: '关键字搜索',
      keywordsPlaceholder: '请输入关键字',
    },
  },
  isvManagement: {
    id: '系统编号',
    agencyCode: '代理商编号',
    loginId: '登录ID',
    isoName: '一级代理商名称',
    isv1Name: '二级代理商名称',
    superIsvName: '上级代理商名称',
    name: '代理商名称',
    mail: '邮箱',
    phone: '联系方式',
    resetPsd: '重置密码',
    search: {
      keywords: '关键字搜索',
      keywordsPlaceholder: '请输入关键字',
    },
  },
  agentCreate:{
    agentManagement: '代理商管理',
    homePagenew:'代理商主页',
    create: '创建代理',
    mailRegistration:'邮箱注册',
    mailConfirm: '确认邮箱',
    mailConfirmPlaceholder: '请再次输入邮箱',
    captcha: '获取验证码',
    newcaptcha:'验证码:',
    bankInfo: '银行信息',
    bankName : '银行名称',
    bankCode : '银行编号',
    bankType:'银行种类',
    branchName : '支行名称',
    branchnumber:'支行编号',
    Subbranchtype:'支行类型',
    Accounttype:'账户类型',
    Bankaccountname:'银行账户名称(半角片假名)',
    Bankaccountname2:'银行账户名称',
    Bankaccount:'银行账号',
    captchaPlaceholder: '请输入邮箱中的验证码，如：k2j4',
    mailPlaceholder: '例：qfpay@qfpay.com',
    psd: '输入密码',
    psd2:"******",
    psdConfirm: '确认密码',
    psdConfirmPlaceholder: '请再次输入密码',
    psdConfirmNewPlaceholder: '请再次输入新密码',
    bankInfo: '银行信息',
    ChannelAddition:'渠道添加',
    submit:'提交',
    man:'男',
    woman:'女',
    mailtitle:'邮箱验证',
    agentInformation:'代理商信息',
    corporateInformation:'法人信息',
    Contacts:'联系人信息',
    undertaker:'担当者情报',
    success:'完成',
    Preservation:'保存已填写内容',
    trueEmail: '请输入正确的邮箱!',
    filEmail:'请填写邮箱',
    psd:'密码',
    popcontent:'填写过程退出程序记得保存，再次登录可继续填写',
    fillcode:'请填写验证码',
    psdPlaceholder: '请输入密码!',
    psdConfirmPlaceholder: '请再次输入密码!',
    fillInformation:'请填写信息',
    fillNumber:'请填写号码',
    filEmail:'请填写邮箱',
    rate:'请填写费率',
    rateTip1 : '费率的范围为: 最小值为:',
    rateTip2 : '最大值为:',
    rateTip3: '',
    picDetail:'请上传清晰、实拍的图片，请不要在图片上添加文字、数字、网址等内容，请勿上传名片、二维码、自拍照、风景照等无关图片，支持JPG、PNG格式，最多上传9张，每张最大2M',
    WeChatExplain:'是由中国最为通用的一款聊天软件“微信”来为您提供支付服务---“WeChat Pay”。',
    AlipayExplain:'“支付宝”以淘宝网络购物为开端，受到全球10亿余（※统计至2019年1月）用户的青睐，是堪称世界第三的支付服务平台。',
    OrigamiExplain:'Origami以“创造金钱、支付、和商业的未来”为时代理念，提供无现金支付平台。',
    PaypayExplain:'是由软银和雅虎日本携手打造的一款手机支付软件---PayPay。',
    NetspayExplain:'新加坡三大银行皆可进行二维码支付的一款便捷服务。',
    RakutenpayExplain:'乐天支付，即便您不在乐天，只要手持乐天ID就可随时随地进行便捷支付。',
    UmspayExplain:'银联（UnionPay），确立了继国际5大经济品牌（VISA、MASTER、JCB、AMERICAN EXPRESS、DINERS CLUB）的第六国际品牌地位。',
    VIAExplain:'可使用新加坡的Dash、泰国的Global Pay的一款支付服务。',
    DocomoExplain:'docomo支付，是一款可以将大街小巷网络支付与手机充值合并结算、简单而便捷、实惠的手机支付服务。',
    PringExplain:'pring，是一款将金钱交易以如同亲朋好友间消息发送的往来方式进行支付的手机软件。',
    SolPayExpain: '韩国国内排行第二位的新韩银行所提供的QR服务',
    GMOExpalin : '专精线上交易的gateway系统',
    DiXingExpalin : '提供高效的跨境支付与银行会员服务',
    tBTCExplain: '这是一种ERC20代币,得到可靠的支持并可兑换成比特币。',
    BTCExplain: '比特币是一个共识网络，促成了一个全新的支付系统和一种完全数字化的货币。它是第一个去中心化的对等支付网络，由其用户自己掌控而无须中央管理机构或中间人。从用户的角度来看，比特币很像互联网的现金。比特币也可以看作是目前最杰出的三式簿记系统。',
    ACDExplain: 'ACD COIN同航空公司、EC平台进行合作，应用于全球线上、线下商铺。',
  },

  agentdetail:{
    agentManagement: '代理商管理',
    homePagenew:'代理商主页',
    agentdetail:'代理详情',
    ChannelAddition:'渠道详情',
    feeRate: '费率:',
    man:'男',
    woman:'女',
    bankInfo: '银行信息',
    bankName : '银行名称',
    bankCode : '银行编号',
    bankType:'银行种类',
    branchName : '支行名称',
    branchnumber:'支行编号',
    Subbranchtype:'支行类型',
    Accounttype:'账户类型',
    Bankaccountname:'银行账户名称(半角片假名)',
    Bankaccountname2:'银行账户名称',
    Bankaccount:'银行账号',

  },
  accountdetail:{
    accountManage:'账户管理',
    infoManage:'信息管理',
    agentManagement: '代理商管理',
    homePagenew:'代理商主页',
    agentdetail:'代理详情',
    ChannelAddition:'渠道详情',
    feeRate: '费率:',
    man:'男',
    woman:'女',
    bankInfo: '银行信息',
    bankName : '银行名称',
    bankCode : '银行编号',
    bankType:'银行种类',
    branchName : '支行名称',
    branchnumber:'支行编号',
    Subbranchtype:'支行类型',
    Accounttype:'账户类型',
    Bankaccountname:'银行账户名称(半角片假名)',
    Bankaccountname2:'银行账户名称',
    Bankaccount:'银行账号',
    contactInfo:'基本信息',
    loginId:'登录ID',
    loginPsd:'登录密码',
    tell:'联系方式',
    agentName:'代理名称',
    qrCode:'我的二维码',
    invitationCode:'邀请码',
    inputOldPsd:'请输入原密码',
    inputNewPsd:'请输入新密码',
    confirmNewPsd:'再确认',
    resetPsdBtn:'重置密码',
    psdInfoTitle:'密码强度:',
    psdInfo:'请使用数字和字母组合,长度为6-8个字符。不要使用您用于登录其他网站的密码,也不要使用很容易被猜到的密码(例如,123456)',
  },

  agentedit:{
    agentManagement: '代理商管理',
    homePagenew:'代理商主页',
    edit: '编辑信息',
    create: '创建代理',
    mailRegistration:'邮箱注册',
    psd: '密码',
    bankInfo: '银行信息',
    bankName : '银行名称',
    bankCode : '银行编号',
    bankType:'银行种类',
    branchName : '支行名称',
    branchnumber:'支行编号',
    Subbranchtype:'支行类型',
    Accounttype:'账户类型',
    Bankaccountname:'银行账户名称(半角片假名)',
    Bankaccountname2:'银行账户名称',
    Bankaccount:'银行账号',
    ChannelAddition:'渠道添加',
    submit:'提交',
    mailtitle:'邮箱验证',
    agentInformation:'代理商信息',
    corporateInformation:'法人信息',
    undertaker:'担当者情报',
    Contacts:'联系人信息',
    success:'完成',
    Preservation:'保存已填写内容',
    popcontent:'填写过程退出程序记得保存，再次登录可继续填写',
    man:'男',
    woman:'女',
    rate:'请填写费率',
    WeChatExplain:'是由中国最为通用的一款聊天软件“微信”来为您提供支付服务---“WeChat Pay”。',
    AlipayExplain:'“支付宝”以淘宝网络购物为开端，受到全球10亿余（※统计至2019年1月）用户的青睐，是堪称世界第三的支付服务平台。',
    OrigamiExplain:'Origami以“创造金钱、支付、和商业的未来”为时代理念，提供无现金支付平台。',
    PaypayExplain:'是由软银和雅虎日本携手打造的一款手机支付软件---PayPay。',
    NetspayExplain:'新加坡三大银行皆可进行二维码支付的一款便捷服务。',
    RakutenpayExplain:'乐天支付，即便您不在乐天，只要手持乐天ID就可随时随地进行便捷支付。',
    UmspayExplain:'银联（UnionPay），确立了继国际5大经济品牌（VISA、MASTER、JCB、AMERICAN EXPRESS、DINERS CLUB）的第六国际品牌地位。',
    VIAExplain:'可使用新加坡的Dash、泰国的Global Pay的一款支付服务。',
    DocomoExplain:'docomo支付，是一款可以将大街小巷网络支付与手机充值合并结算、简单而便捷、实惠的手机支付服务。',
    PringExplain:'pring，是一款将金钱交易以如同亲朋好友间消息发送的往来方式进行支付的手机软件。',
    SolPayExpain: '韩国国内排行第二位的新韩银行所提供的QR服务',
    GMOExpalin : '专精线上交易的gateway系统',
    DiXingExpalin : '提供高效的跨境支付与银行会员服务',
    tBTCExplain: '这是一种ERC20代币,得到可靠的支持并可兑换成比特币。',
    BTCExplain: '比特币是一个共识网络，促成了一个全新的支付系统和一种完全数字化的货币。它是第一个去中心化的对等支付网络，由其用户自己掌控而无须中央管理机构或中间人。从用户的角度来看，比特币很像互联网的现金。比特币也可以看作是目前最杰出的三式簿记系统。',
    ACDExplain: 'ACD COIN同航空公司、EC平台进行合作，应用于全球线上、线下商铺。',
    ContentPic1:'個人事業主の場合は、必ず営業許可証が必要になります。 (写真/スキャンデーター)',
    ContentPic2:'振込口座が確認できるページの写真/スキャンデーター',
    picDetail:'请上传清晰、实拍的图片，请不要在图片上添加文字、数字、网址等内容，请勿上传名片、二维码、自拍照、风景照等无关图片，支持JPG、PNG格式，最多上传9张，每张最大2M',
  },


  agentaudit:{
    agentManagement: '代理商管理',
    homePagenew:'代理商主页',
    auditAgent:'审核新代理',
    ChannelAddition:'渠道添加',
    man:'男',
    woman:'女',
    bankInfo: '银行信息',
    bankName : '银行名称',
    bankCode : '银行编号',
    bankType:'银行种类',
    branchName : '支行名称',
    branchnumber:'支行编号',
    Subbranchtype:'支行类型',
    Accounttype:'账户类型',
    Bankaccountname:'银行账户名称(半角片假名)',
    Bankaccountname2:'银行账户名称',
    Bankaccount:'银行账号',
    feeRate: '费率:',
    reject: '拒绝',
    ok: '通过',
    Refuseapplication:'拒绝申请？',
    Confirmrejection:'确定拒绝代理申请？拒绝后申请人可重新提交',
    Determine:'确定',
    cancel:'取消',
    rate:'请填写费率',
    distribution : '分配',
    toBeISO: '成为一级代理',
    toBeSubordinate : '添加为下级代理',
    chooseOne: '请选择一个上级代理'
  },


  agentManagement: {
    examinePass:'已审核支付渠道费率',
    agentManagement: '代理商管理',
    id: '营业员系统编号',
    agentCode: '营业员编号',
    agentTime:'申请时间',
    loginId: '登录ID',
    name: '营业员',
    mail: '邮箱',
    registeredChannel: '当前渠道',
    phone: '联系方式',
    resetPsd: '重置密码',
    search: {
      keywords: '关键字搜索',
      keywordsPlaceholder: '姓名/邮箱/联系方式',
      applytime: '申请时间',
    },
    underCount:'下级代理数量统计',
    agency : '代理',
    state : '状态',
    query:'查询',
    reset:'重置',
    status: {
      comoleteMeterial :  '未通过',
      waitingReview : '待审核',
      reverseMaterial: '未提交',
      normal: '已通过'
    },
    contactInfo: '联系人信息',
    contactPhone: '联系方式',
    name: '姓名',
    mail: '邮箱',
    agencyName : '代理名称',
    bankInfo : '银行名称',
    bankName : '银行名称',
    bankCode : '银行编号',
    branchName : '支行名称',
    screen : '筛选',
    keyword : '关键字',
    subState : '提交状态',
    query:'查询',
    reset:'重置',

    all : '全部',
    agentsList : '代理商一览',
    systemCode : '系统编号',
    agentName : '代理商名称',
    curChannel : '当前渠道',
    create: '创建代理',
    invatationnew:'邀请代理',
    homePagenew:'代理商主页',

    invatation:'邀请代理',
    invateCode: '邀请码',
    inVateCodePh: '请填写邀请码',
    mailPh : '请输入您邀请的代理邮箱',
    send : '发送',
    sendpop:'点击“发送”后，我们会将您填写的邀请码发送到被邀请的代理邮箱中，被邀请的代理可自行注册。',
    select : '请选择创建代理方式',
    direct : '直接添加',
    tips : '提示：',
    tip1: '“邀请代理”即发送邀请链接至新代理邮箱，由新代理本人填写注册信息',
    tip2 : '“直接添加”即在本账号内直接创建，为新代理填写注册信息',
    homePage : '代理商主页',
    action: {
      detail : '详情',
      edit : '编辑',
      delete : '删除',
      review : '审核'
    },
    channel: '渠道',
    operation: '操作',
    editPsdBtnOk: '确认',
    editPsdBtnCancle: '取消',
    Promptdescription:'确认发送后，我们会将您填写的邀请码发送到被邀请的代理邮箱中，被邀请的代理可自行进行注册。',
    sure:'确定发送',
    sucsend:"已发送，点击返回代理主页",
    sendPrompt:'您的验证码已发送至邮箱,请于六十分钟内完成验证注册。',
    level : '级别',
    roleEnum: {
      ADMIN : '管理员',
      AGENT: '营业员',
      ISO: '一级代理',
      ISV_LEVEL_1: '二级代理',
      ISV_LEVEL_2: '三级代理'
    },
    superiorAgencyName : '上级代理商名称'
  },



  agencyManagement: {
    id: '代理商系统编号',
    agentCode: '代理商编号',
    loginId: '登录ID',
    name: '代理商',
    mail: '邮箱',
    registeredChannel: '当前渠道',
    phone: '联系方式',
    resetPsd: '重置密码',
    search: {
      keywords: '关键字搜索',
      keywordsPlaceholder: '请输入关键字',
    },
  },
  reviewDetail: {
    revoke: '撤销',
    reject: '拒绝',
    ok: '通过',
    rejectReason: '拒绝原因',
    null: '无',
  },
  modal: {
    title: '提示',
    primaryButtonText: '完成',
    secondaryButtonText: '取消',
    errorTitle: '出错啦',
    confirmPrimaryButtonText: '确认',
    confirmSecondaryButtonText: '取消',
  },
  validator: {
    required: '必填',
    number: '请输入正确的数字',
    format : '请输入正确格式',
    email: '请输入正确的邮箱',
    len: '你输入的字符太长了',
    len1: '密码必须包含字母和数字,字符长度为6-8位',
    integer: '请输入整数',
    samePwd: '请确保两次密码一致',
    samePwdAccount:'请确保两次密码一致',
    sameMail: '请确保两次邮箱一致',
    existEmail: '邮箱已被注册了，请换一个',
    unExistUser: '用户不存在或密码错误',
    receivable: {
      refoundAmountTooHight: '退款金额不能高于可退款金额',
      refoundAmountGtZero: '退款金额必须大于0',
      refoundAmountInvalidDecimal: '退款金额保留两位小数',
      max_l_8: '最多不超过8位',
    },
    max_l_x: '最多不超过%x%位',
    max_l_100: '最多不超过100位',
    min_l_6: '不得少于6位',
    positiveNumber: '不能填写负数',
    noBindingChannels: '没有绑定渠道',
    existLoginId: '登录ID已被注册了，请换一个',
    enCode: '不能有特殊字符',
    halfChar: '请输入半角假名、半角空格、半角括号。',
    maxUploadCount: '上传图片最多不超过9张',
    maxUploadSize: '每张图片大小不能超过1M',
    maxOneDeciaml: '小数点不超过1位',
    maxTwoDeciaml: '小数点不超过2位',
    percentageNumber: '请输入正确的百分数(最多两位小数)',
    invalidUploadExtensions: '上传文件格式应为合法的图片格式',
    invalidUploadExtensionsAndPdf: '上传文件格式应为合法的图片格式或者pdf格式',
    simplePsd: '您输入的密码过于简单',
    mailCannotRepeat: '企业和店铺的登录邮箱不能重复',
    errorCaptchaCode : '验证码错误,请重新填写',
    kata : '请输入半角片假名',
    kata1 : '请输入假名',
    firstMail : '请先填写您的邮件信息',
    password: '请输入密码',
    passwordAgin: '请再次输入密码',
    mailAgin: '请再次输入邮箱',
  },
  enterprise: {
    tip: '如需修改公司信息，请联系客服',
    applyForChannel: '渠道申请',
    registeredChannelInfo: '已审核渠道',
    applyChannelInfo: '待审核渠道',
    toApplyNewChannel: '申请新渠道',
  },
  channel: {
    editTitle: '修改渠道',
    addTitle: '添加渠道',
    id: '渠道支付ID',
    qf: '钱方',
    channel: '渠道',
    bankingOrg: '支付渠道',
    currency: '币种',
    rate: '费率',
    rateUnit: '%',
    withdrawCycle: '提现周期',
    withdrawCycleUnit: '以天为单位',
    withdrawAmount: '单次提现额度',
    withdrawAmountUnit: '以分为单位',
    applySuccess: '您的申请已提交，请等待审核',
    comment: '备注',
    applicationDate: '申请时间',
    rejectReason: '拒绝原因',
    typeEnum: {
      ALIPAY: '支付宝',
      WECHAT: '微信',
    },
    toApply: '申请新渠道',
    invalidFeeRate: '费率不能小于您签约的费率（{{1}}%）',
    nullChannel: '您尚未开通当前渠道',
    feeRateTooLarge: '费率不能超过6%',
    type: '支付方式',
    repeatApplyError: '每个渠道只能申请一次',
    applyInAttachedStore: '提示: 请在附属店铺内申请新渠道'
  },
  tips: {
    confirmToDelete: '确定要删除吗',
    needToReWrite: '已拒绝，请联系该用户重新填写信息',
    noPremission: '该账号没有权限',
    reviewInEmail: '请登录邮箱，确认邮件后修改密码',
    modifySuccess: '密码修改成功',
    channelExist: '该用户已有该渠道，不能重复添加',
    confirmPassed: '确定审核通过吗？',
    rejectStore: '拒绝商户申请',
    confirmToResetPsd: '要将重置密码链接发送到当前注册账号的邮箱吗？',
    actionSuccess : '操作成功',
    confirmRescindTip: '确认与${name}解约？注册时间：${createDate},解约时间：${now}。',
    confirmRescindEnterpriseTip: '将同时解约企业下所有店铺',
    confirmRenewalTip: '确认与${name}恢复契约？',
    confirmRenewalEnterpriseTip: '将同时恢复企业下所有店铺',
  },
  message: {
    readed: '已读',
    unread: '未读',
    readAll: '查看全部',
  },
  pagination: {
    prev: '前一页',
    next: '后一页',
    each: '每页个数',
    total: '总页数',
  },
  currency: {
    CNY: '人民币',
    USD: '美元',
    JPY: '日本円',
  },
  bank: {
    ALIPAY: '支付宝',
    WECHAT: '微信',
    UNKNOWN: '未知',
    ZERO: '零',
  },
  settlement: {
    amountInThisMonth: '本月该清算金额',
    pay: '支付',
    status: '清算状态',
    status_unsettlement: '未清算',
    status_settled: '已清算',
    status_unsettlement_csv: '自动汇款书',
    settlementTime: '清算时间',
    payeeName: '收款人账户名称',
    payeeAccount: '收款人账户',
    payeeBankName: '收款人银行名称',
    bankBranch: '银行分行',
    channel: '渠道',
    tradeAmount: '交易金额',
    feeRate: '费率',
    channelFee: '渠道费用',
    systemFee: '系统使用费',
    serviceFee: '服务费',
    otherFee: '其他费用',
    finalFee: '实际清算金额',
    storeName: '分店名',
    doSettle: '清算',
    enterpriseName: '所属企业名',
    userType: '用户类型',
    settlementRange: '清算周期',
    settlementFee: '预计清算金额',
    settlementInfo: '清算信息',
    settlementSuccess: '清算成功',
    doSettleTime: '操作时间',
    operator: '操作人',
    exportUnsettlement: '导出自动汇款文件',
    transferDate: '汇款时间',
    ISOName: '一级代理商名称',
    commission: '应付提成',
    agentCommission: '应得提成',
    QFPayCommission: 'QFPay应得提成',
    agent: '营业员',
    agencyName: '代理商名称',
    bankInfo: '银行信息',
    bankCode: '银行编号',
    bankName: '银行名称',
    branchBankCode: '银行分行编号',
    branchBankName: '银行分行名称',
    accountType: '账户类型',
    accountName: '银行账户名称',
    accountCode: '银行账号',
    mineCommission: '我司提成',
    stat: {
      totalIncome: '总流水',
      totalPay: '应付提成',
      dividends: '应得提成',
      title: '清算统计',
    }
  },
  profitManage: {
    profitTitle: '本月收益',
    storeTopFive: 'TOP5店铺',
    profitAccount:'钱包收益额统计',
    trendAccount:'收益走势统计',
    salesClerk:'TOP5代理/营业员',
    profitShop:'店铺',
    otherAgentString:'其他代理',
    allEarnings:'总盈利',
    day_30:'最近30天',
    day_7:'最近7天',
    liquidationTitle:'收益清算查询',
    lowerLevelEarnings:'下级收益查询',
    agencyLevel:'代理级别',
    all:'全部',
    agencyLevel_1:'一级代理',
    agencyLevel_2:'二级代理',
    agencyLevel_3:'三级代理',
    agent:'营业员',
    keyword:'关键字',
    keywordDefaultValue:'请输入代理商名称',
    monthScope:'月份范围',
    query:'查询',
    reset:'重置',
    myEarnings:'自身收益查询',
    index:'序号',
    agencyName:'代理商名称',
    name : '代理名称',
    month:'月份',
    earnings:'收益额',
    meAndLowerEarnings: '总收益(包含下级)',
    phone:'联系电话',
    op:'操作',
    exportDetail:'导出详情',
    fileName:'收益详情',
    profitName:'收益管理',
    count: '个数',
    totalEarning:'总收益',
    otherAgent:'其他代理'
  },
  resetPsd: {
    mailSended: '一封邮件已发送至该账号，请联系他修改密码',
  },
  header: {
    oldPsd: '请输入旧密码',
    newPsd: '请输入新密码',
    confirmPsd: '请再次输入新密码',
    editPsdBtnOk: '确认',
    editPsdBtnCancle: '取消',
  },

  StoreManage:{
    tableTitle:'签约店铺一览',
    Title:'店铺管理',
    TabTitle:'营业管理',
    MesWrite:'店铺信息编辑',
    TodayAccount:'今日累计商户数量',
    AccountMonth:'本月累计',
    AccountNum:'总商户数量',
    AccountWait:'待提交',
    AccountWaitPass:'审核通过',
    AccountToday:'店铺统计TOP2',
    AccountUp:'增幅最多',
    AccountDown:'跌幅最多',
    AccountProfit:'收益最多',
    Package:'钱包收入TOP5',
    StoreList:'商户一览',
    AddStore:'添加商户',
    Sign:'签约商户',
    StoreClass:'商户类型',
    SignWait:'待提交商户',
    Key:'请输入关键字',
    Search:'查询',
    Reset:'重置',
    All:'共',
    AllDetail:'条,企业数量',
    AllEnd:'店铺数量',
    StoreNumber:'企业数量',
    Submit:'提交',
    TableDetail:'详情',
    TableWrite:'编辑',
    SignTime:'签约日期',
    SignCard:'钱包',
    CardWait:'待审核钱包',
    Address:'地址',
    StoreName:'商户名称',
    StoreLevel:'级别关系',
    StoreStatus:'状态',
    Action:'操作',
    MessageAll:'信息完整',
    MessageError:'信息缺失',
    DownloadMoke:'下载模板',
    AddAll:'批量添加',
    ChooseAdd:'选择注册类型',
    ChooseClass:'账户类型:',
    ClassPerson:'个人',
    ClassStore:'法人',
    StoreMessage:'企业账户信息',
    StoreMessageLabel:'企业账户ID（邮箱）：',
    male:'男',
    female:'女',
    yes:'是',
    no:'否',
    StoreRate:'商户手续费',
    Please:'请填写费率',
    PendingApproval:"待审核",
    Rescind: "已解约",
    Normal:"正常",
    Reviewed:"已审核",
    awaitHandle: '费率待处理',
    AddChannel:'申请新渠道',
    ChannelMes:'新渠道信息',
    ChoosePlease:'请选择',
    StartTime:'开始时间',
    EndTime:'结束时间',
    LogMail:'登陆邮箱',
    export:'导出',
    Save :"保存",
    ChooseTime:"请选择时间",
    loginId: '登录ID:',
    feerateRule : '费率需满足如下条件 :店铺费率>=直属代理费率,下级费率>=上级费率',
    appliFeerateRule : '费率需满足如下条件 :店铺费率>=直属代理费率,下级费率>=上级费率,渠道费率小于代理费率',
    crossLevelNow : '您正在跨级审核，请确认。',
    awaitHandleChannel : '费率待处理渠道',
    state : '渠道状态',
    differExplain : '该渠道的代理费率与其签约费率不同，请查看',
    edit : '编辑',
    rescind: '解约',
    renewal: "续约",
  },
  StoreAdd: {
    SelectRegistrationType:'选择注册类型',
    AccountType:'账户类型:',
    LegalPerson:'法人',
    Personal:'个人',
    EnterpriseInfo:'企业情报',
    EnterpriseLoginId:'企业ID:',
    FillEnterpriseLoginId:'请填写企业ID!',
    man:'男',
    woman:'女',
    yes: '是',
    no: '否',
    submit: '提交',
    rate:'请填写费率',
    storeRate:'加盟店手数料',
    storeChannel : '请选择渠道'
  },
  ModalMessage: {
    confirmTitle:'添加在既有企业下？',
    confirmContent:'该企业登录ID（邮箱）已存在，是否在该企业下添加店铺？',
    Cancel:'取消',
    Ok:'确定',
    mailExist:'此邮箱已被占用',
    onlyChoose:'请至少选择一个渠道',
    WriteRate:'请填写已购选的渠道费率',
    AccountError:'营业担当账号错误',
    NoRate:'渠道没有',
    RightNo:'请输入正确的数字',
    MessageError:'信息有误,请核对后导入!',
    need:'必填！',
    check:'请填写正确的邮箱！',
    MessageNoAll:'店铺信息不完整！',
    MessageNoAllStore:'企业信息不完整！',
    MessageNoAllInfo:'请补全全部信息.',
    ForgetMail:'您输入的邮箱未注册！',
    ForgetMailDetail:'请确认邮箱后重新输入！',
    ForgetMailSend:'邮件已发送到您的邮箱！',
    ForgetMailSendDetail:'请登录邮箱，确认邮件后修改密码！',
    NotSame:'企业邮箱与店铺邮箱相同!',
    RateGreater:'渠道费率小于上级费率',
    UnExist:'代理不存在该渠道!',
    RateError:'渠道费率填写有误!',
    AddSuccess:'添加成功',
    AddError:'添加失败',
    NoAddInfo:"没有需要导入的信息",
    imgTypeError:"仅可上传 JPG/PNG 格式的图片!",
    imgBigError:"图片最大可上传2MB!",
    imgLengthError:"图片上传数量超过上限!",
    fileTypeError:"仅可上传 JPG/PNG/PDF 格式的文件!",
    fileBigError:"文件最大可上传2MB!",
    fileLengthError:"文件上传数量超过上限!",
    SaveSuccess:"保存成功",
    errorInfo:"店铺信息输入有误,请及时修改后,再次申请支付渠道.",
    ConfirmDoc:"请确认使用最新文档.",
  },
  StoreDetail:{
    Title:'店铺管理',
    Detail:'店铺信息',
    channelDetail:'渠道信息',
    StoreName:'店铺',
    StoreNum:'店铺数量',
    StoreEarn:'手续费收入',
    StoreNumber:'收银员数量',
    examinePass:'已审核支付渠道费率',
    StoreMessage:'店铺信息',
    CompanyMessage:'企业信息',
    StoreExamine:'已审核渠道',
    RateClass:'币种',
    Rate:'店铺费率',
    RateCompany:'收单公司',
    StoreElse:'待审核渠道',
    superWaitHandle : '上级待确认渠道',
    ownWaitHandle : '费率待处理渠道',
    StoreActive:'店铺活跃率',
    Company:'企业',
    ownFeerate: '您的费率',
    ISORate: '一级代理费率',
    ISVOneRate : '二级代理费率',
    ISVTwoRate : '三级代理费率',
    agentRate : '营业员费率',
    channelRate : '渠道方费率',
    notHave : '待处理',
    channel: '渠道',
    StoreElsePop : '正在等待店铺管理员进行费率审核',
    superWaitHandlePop : '正在等待上级代理进行费率审核',
    ownWaitHandlePop:'您需要审核渠道费率',
  },
  MessageDownLoad:{
    type:'资料下载',
    messageManagent:'资料下载',
    messageList:'资料下载',
    total:'共',
    count:'条',
    title:'渠道名称',
    createDate:'发布时间',
    content:'渠道logo',
    detail:'详情',
    checkDetail:'查看详情',
    messageDetail:'资料详情',
    loadDetail: '详细资料请下载附件',
  },
  MessageNotice:{
    type:'通知类型',
    store:'店铺管理',
    agent:'代理管理',
    profit:'收益管理',
    level:'通知等级',
    warning:'警告',
    task:'任务',
    notice:'通知',
    title:'标题',
    createDate:'发布时间',
    content:'正文内容',
    detail:'详情',
    checkDetail:'查看详情',
    messageManagent:'通知管理',
    screen:'筛选',
    messageList:'通知一览',
    total:'共',
    count:'条',
    createNotice:'新建通知',

    messageDetail:'通知详情',
    send:'发送给',

    sendTarget:'发送对象',
    agentGroup:'代理群组',
    agents:'群组成员',
    messageContent:'通知内容',
    messageTitle:'通知标题',
    chooseGroup:'请选择代理群组',
    titleTips:'请输入标题,最多16个字',
    contentTips:'请输入内容',
    submit:'发布',
    back:'返回',

    groupManagent:'群组管理',
    groupList:'群组一览',
    createGroup:'新建群组',
    chosseAgents:'选择群组成员',
    save :"保存",
    cancel:'取消',
    groupInfo:'群组信息',
    groupTitle:'群组标题',
    groupRemark:'群组备注',
    remarkTips:'可输入关于群组详细描述等信息',
    operate: '操作',
    edit : '编辑',
    agentManagement: '营业员管理',
    readStatus:'查看状态',
    isRead:'已读',
    noRead:'未读',
    chooseAgents:'请选择群组代理成员!'
  },
  merchantAsyncFieldPlaceholder: {
    'Agent Name': '请输入地推人姓名',
    'Agent Code': '请输入地推人编号',
    'Agency Name': '请输入所属地推公司名称',
    'First Contact Name': '请输入主要联系人姓名',
    'First Contact Phone': '请输入主要联系人电话',
    'First Contact E-Mail Address': '请输入主要联系人邮箱',
    'Bank Code': '如果不知，可垂询您的银行',
    'Bank Name': '请填写您的银行名称',
    'Branch Code': '如果不知，可垂询您的银行',
    'Branch Name': '如果不知，可垂询您的银行',
    'Account Code': '请输入银行账户编号',
    'Account Name': '请输入银行账户持有人姓名',
    'Account Type': '请输入银行账户类型',
    'Company Name': '请输入公司名称',
    'Company Code': '请填写法人号码',
    'Business Category': '请输入商户所属种类',
    'Principal Shareholder Name': '请输入企业股东代表姓名',
    'Principal Shareholder Birth Day': '请输入股东代表出生日期',
    'Principal Shareholder Home Address': '请输入股东代表住址',
    'Principal Shareholder Phone': '请输入股东代表联系电话',
    'Principal Shareholder E-Mail Address': '请输入股东代表联系邮箱',
  },
  agencyAsyncFieldPlaceholder: {
    'Agency Name(S)': '例：株式会社〇〇〇〇',
    'Name of Person in Charge': '例：藤本太郎',
    'Phone of Person in Charge': '例：01-3987-3298',
    'E-Mail Address of Person in Charge': '例：fujimoto@xxx.com'
  },
  errorCode: {
    403041: '没有权限',
    40401: '该用户不存在',
    40405: '没有该记录',
    40601: '请求参数错误',
    40602: '验证码错误',
    40603: '登录ID与邮箱不匹配',
    40605: 'Token已失效',
    50001: '与第三方服务器连接发送错误，请重试',
    50002: '更新商户信息出错',
    50003: '添加代理商出错，请重试',
    50004: '添加营业员出错，请重试',
    50005: '添加渠道出错，请重试',
    50006: '添加渠道出错，请重试',
    50007: '更新渠道信息出错，请重试',
    50008: '更新分店信息出错，请重试',
    50009: '添加分店，请重试',
    50010: '添加银行账户信息，请重试',
    50011: '更新银行账户信息，请重试',
    50012: '更新企业信息失败，请重试',
    50013: '添加企业失败，请重试',
    50014: '添加企业信息失败，请重试',
    50015: '该用户不存在',
    50016: '用户密码错误',
    50017: '更新代理商错误',
    50018: '渠道已申请',
    60001: '渠道费率必须大于上级代理',
    70001: '添加失败,请重试',
    70002: '修改失败,请重试',
  },
  businessCategory: {
    ShoesGarments: '服饰',
    ComprehensiveMall: '综合商场',
    Food: '饮食',
    Cosmetics: '化妆品',
    MaternalInfant: '母婴用品',
    DigitalAppliance: '数码家电',
    Logistics: '物流',
    EducationIndustry: '教育',
    HotelIndustry: '酒店行业',
    StationeryOfficeSupplies: '办公文具',
    AirTicket: '航空票务',
    OverseasEducation: '海外留学',
    TravelTicket: '旅游出行票务',
    CarRental: '汽车租赁',
    InternationalConference: '国际会议',
    Software: '软件',
    MedicalService: '医疗服务',
    OtherTradeIndustry: '其他贸易',
  },
};
