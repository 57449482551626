import Loadable from 'react-loadable';
import { LCLoading } from 'control/loading';
import { AuthorizedRouter } from 'acl';
import { Route, Switch } from 'react-router-dom';
import React from 'react';

const NoticeGroup = Loadable({
    loader: () => import('./NoticeGroup'),
    loading: LCLoading,
  });


  const NoticeGroupRoute = ({ match }) => {

    return (
      <Switch>
        <Route exact path={`${match.path}`} component={NoticeGroup} />
        {/* <Route exact path={`${match.path}/noticeDetail`} component={NoticeDetail} />
        <Route exact path={`${match.path}/addnotice`} component={Addnotice} /> */}
      </Switch>
    );
  }
  export { NoticeGroupRoute };
  