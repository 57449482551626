import { Model } from 'qn/data';
import { MERCHANT_USER_STATUS } from 'const';

@Model({
  modelName: 'Merchant',
  fields: {
    agencyOrAgentKey: 'string',
    ownerKey: 'string',
    agencyName: 'string',
    agentName: 'string',
    isAgent: 'boolean',
    enterpriseKey: 'string',
    enterpriseName: 'string',
    status: 'string',
    storeKey: 'string',
    storeName: 'string',
    type: 'string',
    createdDate: 'date',
    isAttached: 'boolean',
    registeredChannels: 'any',
    bankingOrgApplication: 'any',
  },
  idgen: 'uuid',
})
class Merchant {

  hasRegisteredChannels() {
    return this.registeredChannels && this.registeredChannels.length > 0;
  }

  getStatus() {
    if (this.status === MERCHANT_USER_STATUS.NORMAL) {
      // if (this.hasRegisteredChannels()) {
        return MERCHANT_USER_STATUS.REVIEW_PASS;
      // } else {
      //   return MERCHANT_USER_STATUS.REVIEWING;
      // }
    }
    return this.status;
  }
}

export default Merchant;
