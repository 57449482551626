import { Model } from 'qn/data';
import { computed } from 'mobx';

@Model({
    modelName: 'ISV1',
    fields: {
        id: 'id',
        superiorName: 'string',
        key: 'string',
        loginId: 'string',
        name: 'string',
        status: 'string',
        mail: 'string',
        phone: 'string',
        agentCode: 'string',
        agencyName: 'string',
        createdDate: 'date',
    },
    idProperty: 'key',
})
class ISV1 {
    constructor(data) {
    }
}

export default ISV1;
