import React from 'react';

import './style.scss';

class LCLoading extends React.Component {
  
  render() {
    return ( 
    <div id="loading">
      <div className="new-loading">
        {/* <div className="loading-new"><img src={Loadings} style={{width:'60%',width:'60%'}}/></div> */}
        <div className=" loading"></div>
      </div>
      
    </div>
    )
  }
}

export default LCLoading;
