import React from 'react';
import { observer } from 'mobx-react';
// import { Menu, MenuItem, MenuList } from 'control/leftMenu';
import { Link } from 'react-router-dom';
import Card from 'control/card';
import appStore from 'appStore';
import './nav.scss';
import api from 'remote/api';
import { canDoSome, cannotDoSome, ROLE } from 'acl';
import withLocale from 'decorator/withLocale';
import sessionStore from 'sessionStore';
import { observable } from 'mobx';
import { Menu, Icon} from 'antd';

const { SubMenu } = Menu;



@withLocale
@observer
class Nav extends React.Component {
  // componentWillMount() {
  //   const lang = this.props.locale.nav;
  //   this.menu = [
  //     { link: '/app/dashboard', title: lang.homePage, access: [] },
  //     { link: '/app/transaction', title: lang.tranManagemant, noAccess: [access.SUPER_ADMIN] },
  //     { title: lang.propManagement, access: [access.MANAGE_PROMOTION_CAMPAIGN, access.STORE_OWNER, access.ENTERPRISE_OWNER],
  //       children: [
  //         { title: lang.promActivities, link: '/app/campaign', access: [access.MANAGE_PROMOTION_CAMPAIGN, access.STORE_OWNER, access.ENTERPRISE_OWNER] },
  //       ],
  //     },
  //     { title: lang.mercManagement, access: [access.ENTERPRISE_OWNER, access.MANAGE_EMPLOYEE, access.STORE_OWNER], children: [
  //       { title: '签约信息', link: '/app/enterprise', access: [] },
  //       { title: lang.branchStoreManagement, link: '/app/branch', access: [access.ENTERPRISE_OWNER] },
  //       { title: lang.employeeManagement, link: '/app/user', access: [access.MANAGE_EMPLOYEE, access.STORE_OWNER] },
  //     ] },
  //     { link: '/app/pendingReview', title: lang.toReview, access: [access.SUPER_ADMIN] },
  //   ];
  // }
  // @observable useOwnBankAccountForSettlement = false

  // async componentWillMount() {
  //   const user = sessionStore.getUser();
  //   const res = await api.get(`/store/${user.storeKey}`);
  //   this.useOwnBankAccountForSettlement = res.data.useOwnBankAccountForSettlement;
  // }

  state = {
    openKeys: [''],
    // current:'app/merchant',
  };

  // componentDidMount=()=>{

  //   let addressnow = window.location.pathname;

  //   this.setState({
  //     current:addressnow.substring(addressnow.lastIndexOf('/')+1,addressnow.length)
  //   });
  //   // this.props.history.listen((event)=>{
  //   //   let test = event.pathname
  //   //   let text = test.substring(test.lastIndexOf('/')+1,test.length)
  //   //   this.setState({
  //   //     current:text
  //   //   });
  //   // });

  //   window.addEventListener('hashchange',()=>{
  //           let test = event.pathname
  //     let text = test.substring(test.lastIndexOf('/')+1,test.length)
  //     this.setState({
  //       current:text
  //     });

  //   })

  // }

  savePath = (path) => {
    // 把路径存到某个js内的变量中， 然后在每个页面都写一段读取js内的变量的方法， 更改菜单颜色



  }

  createMenuByRole = () => {
    if (sessionStore.getUser().role === ROLE.ADMIN) {

    }
  }

  buildMenu = () => {
    const lang = this.props.locale.nav;

    const menu = [
      { link: '/app/merchant', title: lang.merchantManagement, access: [] },
      { link: '/app/agent', title: lang.agentManagement, access: [ROLE.ADMIN,ROLE.ISO, ROLE.ISV_LEVEL_1] },
      { link: '/app/agency', title: lang.agencyManagement, access: [ROLE.ISO, ROLE.ISV_LEVEL_1] },
      { link: '/app/agencySettlement', access: [ROLE.ISO, ROLE.ISV_LEVEL_1], title: lang.agencySettlement },
      { link: '/app/agentSettlement', access: [ROLE.ISO, ROLE.ISV_LEVEL_1, ROLE.ISV_LEVEL_2], title: lang.agentSettlement },
      { link: '/app/settlement', access: [ROLE.AGENT], title: lang.agentSettlementSelf },
      { link: '/app/bankinfo', title: lang.bankInfo, noAccess: [ROLE.ADMIN] },
      { title: lang.agencyManagement, access: [ROLE.ADMIN], children: [
        { link: '/app/iso', title: lang.ISOManagement, access: [] },
        { link: '/app/isv1', title: lang.ISV1Management, access: [] },
        { link: '/app/isv2', title: lang.ISV2Management, access: [] },
      ]},
      { link: '/app/revenue', access: [ROLE.ADMIN], title: lang.RevenueSearch },
      { link: '/app/agencyreview', access: [ROLE.ADMIN], title: lang.ISOReview },
      { link: '/app/ISOSettlement', access: [ROLE.ADMIN], title: lang.ISOSettlement },
    ];

    const level1Menus = [];
    menu.forEach((m, i) => {
      if (m.children) {
        const level2Menus = [];
        m.children.forEach((child, k) => {
          if (child.access && canDoSome(child.access)) {
            level2Menus.push(<MenuItem key={k}><Link to={child.link}>{child.title}</Link></MenuItem>);
          } else if (child.noAccess && cannotDoSome(child.noAccess)) {
            level2Menus.push(<MenuItem key={k}><Link to={child.link}>{child.title}</Link></MenuItem>);
          }
        });
        if (m.access && canDoSome(m.access)) {
          level1Menus.push(<MenuList title={m.title} key={i}>{level2Menus}</MenuList>);
        } else if (m.noAccess && cannotDoSome(m.noAccess)) {
          level1Menus.push(<MenuList title={m.title} key={i}>{level2Menus}</MenuList>);
        }
      } else if (m.access && canDoSome(m.access)) {
        level1Menus.push(<MenuItem key={i}><Link to={m.link}>{m.title}</Link></MenuItem>);
      } else if (m.noAccess && cannotDoSome(m.noAccess)) {
        level1Menus.push(<MenuItem key={i}><Link to={m.link}>{m.title}</Link></MenuItem>);
      }
    });
    return level1Menus;
  }

  rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];


  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

    render() {

      const lang = this.props.locale.nav;

    const { style } = this.props;

    return (
      // <div className="left-nav" style={style}>
      //   <Menu
      //     activeHref={(window.location && window.location.pathname)}
      //   >
      //     {this.buildMenu()}
      //   </Menu>
      // </div>
      <Menu
        mode="inline"
        openKeys={this.state.openKeys}
        onOpenChange={this.onOpenChange}
        style={{ width: 200 }}
        defaultSelectedKeys={['01']}
        // selectedKeys={[this.state.current]}

      >

        <Menu.Item key="/app/merchant" className=" first-ant">
          <Link to='/app/merchant'><span className="iconfont">&#xe648;</span><span style={{marginLeft:10}} className="size-17 f-bold">{lang.title}</span></Link>
        </Menu.Item>

        <SubMenu
          key="sub1"
          title={
            <span>
                <span className="iconfont">&#xe608;</span><span style={{marginLeft:10}} className="size-17 f-bold">{lang.am}</span>
            </span>
          }
        >
          {sessionStore.getUser().role == ROLE.AGENT || sessionStore.getUser().role == ROLE.ISV_LEVEL_2 ? null : <Menu.Item key="/app/agent"><Link to="/app/agent" className={this.props.locale.l=="en" ? "size-14 sub-title-overflow" : "size-17"} >{lang.agencyManagement}</Link></Menu.Item>}
          {sessionStore.getUser().role == ROLE.ADMIN || sessionStore.getUser().role == ROLE.AGENT ? null : <Menu.Item key="/app/agentmanage" className="size-14"><Link to="/app/agentmanage" className={this.props.locale.l=="en" ? "size-14 sub-title-overflow" : "size-17"}>{lang.agentManagement}</Link></Menu.Item>}
          <Menu.Item key="/app/storemanagement"><Link to='/app/storemanagement' className={this.props.locale.l=="en" ? "size-14 sub-title-overflow" : "size-17"}>{lang.Storemanagement}</Link></Menu.Item>
        </SubMenu>
        <SubMenu
          key="sub2"
          title={
            <span>
              <span className="size-17 iconfont">&#xe63c;</span><span style={{marginLeft:10}} className="size-17 f-bold">{lang.Revenuemanagement}</span>
            </span>
          }
        >
          <Menu.Item  key="/app/profitmanage"><Link className={this.props.locale.l=="en" ? "size-14 sub-title-overflow" : "size-17"} to="/app/profitmanage" onClick={() => this.savePath('profitmanage')} >{lang.Revenuemanagement}</Link></Menu.Item>
        </SubMenu>
        <SubMenu
          key="sub3"
          title={
            <span>
                <span className="size-17 iconfont">&#xe61b;</span><span style={{marginLeft:10}} className="size-17 f-bold">{lang.messageManagent}</span>
            </span>
          }
        >
          <Menu.Item key='/app/noticeManage'><Link to='/app/noticeManage' className={this.props.locale.l=="en" ? "size-14 sub-title-overflow" : "size-17"}>{lang.messageManagent}</Link></Menu.Item>
          {sessionStore.getUser().role == ROLE.ADMIN ? <Menu.Item key='/app/noticeGroup'><Link to='/app/noticeGroup' className={this.props.locale.l=="en" ? "size-14 sub-title-overflow" : "size-17"}>{lang.groupManagent}</Link></Menu.Item>:null}
        </SubMenu>
        <SubMenu
          key="sub4"
          title={
            <span>
                <span className="size-17 iconfont">&#xe628;</span><span style={{marginLeft:10}} className="size-17 f-bold">{lang.accountManage}</span>
            </span>
          }
        >
          <Menu.Item key='/app/account'><Link to='/app/account' className={this.props.locale.l=="en" ? "size-14 sub-title-overflow" : "size-17"}>{lang.infoManage}</Link></Menu.Item>
        </SubMenu>
        <Menu.Item key="/app/dataDownload" className=" first-ant">
          <Link to='/app/dataDownload'><span className="iconfont">&#xe639;</span><span style={{marginLeft:10}} className="size-17 f-bold">{lang.dataDownLoad}</span></Link>
        </Menu.Item>
      </Menu>
    );
  }
}
export default Nav;
