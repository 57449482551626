export default class Page {
  currentPage = 1

  pageSize = 10

  totalCount

  totalPageCount

  constructor(totalCount, pageNumber) {
    this.currentPage = pageNumber;
    this.totalCount = totalCount;
  }

  getStartPoz() {
    return (this.currentPage - 1) * this.pageSize;
  }

  getEndPoz() {
    return this.getStartPoz() + this.pageSize;
  }

  getTotalPageCount() {
    return Math.ceil(this.totalCount / this.pageSize);
  }

  setPageSize(num) {
    this.pageSize = num;
  }

  isValid() {
    return this.currentPage <= this.getTotalPageCount();
  }
}