import { Model } from 'qn/data';
import { computed } from 'mobx';

@Model({
    modelName: 'AgentRevenue',
    fields: {
        id: 'id',
        key: 'string',
        name: 'string',
        agencyName: 'string',
        superiorName: 'string',
        channels: 'any',
        agentCode: 'string',
        phone: 'string'
    },
    idProperty: 'key',
})
class AgentRevenue {
    constructor(data) {
    }
}

export default AgentRevenue;
