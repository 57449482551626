import {observable} from 'mobx';

class Errors {
  @observable items = [];
  getFirst() {
    return this.items[0];
  }

  insert(msg) {
    this.items.push(msg);
  }

  getData() {
    return this.items;
  }

  clear() {
    this.items.clear();
  }

  getCount() {
    return this.items.length;
  }
}
export default Errors;
