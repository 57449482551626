import { Model } from 'qn/data';
import { MERCHANT_USER_STATUS } from 'const';

@Model({
  modelName: 'MerchantRevenue',
  fields: {
    ownerUserKey: 'string',
    enterpriseKey: 'string',
    enterpriseName: 'string',
    status: 'string',
    storeKey: 'string',
    storeName: 'string',
    createdDate: 'date',
    channel: 'string',
    bankingOrg: 'string',
    currency: 'string',
    feeRate: 'number',
    revenue: 'number',
    dividends: 'number',
  },
  idgen: (data) => {
    return `${data.ownerUserKey}-${data.bankingOrg}-${data.currency}`;
  },
})
class MerchantRevenue {

  
}

export default MerchantRevenue;
