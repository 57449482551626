import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import './style.scss';

const TextButton = ({ kind, onClick, children, className, ...rest }) => {
  const btnClassName = cx(className, 'text-button', `text-button--${kind}`);
  return (
    <button className={btnClassName} type="button" onClick={onClick} {...rest}>{children}</button>
  );
};
TextButton.propTypes = {
  kind: PropTypes.oneOf([
    'primary', 'danger', 'muted',
  ]),
};
TextButton.defaultProps = {
  kind: 'primary',
};
export default TextButton;
