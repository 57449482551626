import Loadable from 'react-loadable';
import { LCLoading } from 'control/loading';
import { AuthorizedRouter } from 'acl';
import { Route, Switch } from 'react-router-dom';
import React from 'react';

const ProfitManage = Loadable({
  loader: () => import('./ProfitManage'),
  loading: LCLoading,
});

const ProfitManagementRoute = ({ match }) => {

  return (
    <Switch>
      <Route exact path={`${match.path}`} component={ProfitManage} />
      {/* <Route exact path={`${match.path}/Detail/StoreDetail/:storeKey`} component={StoreDetail} />
      <Route exact path={`${match.path}/Detail/EnterpriseDetail/:storeKey`} component={EnterpriseDetail} />
      <Route exact path={`${match.path}/Detail/NewTempDetail/:storeKey`} component={TempDetail} />
      <Route exact path={`${match.path}/Detail/AddStore`} component={AddStore} /> */}
    </Switch>
  );
}
export { ProfitManagementRoute };
