import axios from 'axios';
import sessionStore from 'sessionStore';
import { Modal } from 'antd';
import { DEFAULT_LOCALE } from 'config';
// import Modal from 'control/modal';
/* ===============加载mock================= */
// import {
//     mockBranch,
//     mockEnterprise,
//     mockTransaction,
//     mockReturnFund,
//     mockLogin,
//     mockRegister,
//     mockVcode,
// } from 'mock';

// mockBranch();
// mockEnterprise();
// mockBranch();
// mockTransaction();
// mockReturnFund();
// mockLogin();
// mockRegister();
// mockVcode();
/* ===============加载mock================= */

let baseUrl = 'http://localhost:8888';
if (process.env.NODE_ENV === 'production') {
    baseUrl = '/api';
}
const api = axios.create({
    baseURL: baseUrl,
    // timeout: 1000,
    withCredentials: true,
    // transformRequest: [data => JSON.stringify(data.data)],
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    },
});

function isLocaleApi(config) {
    const url = config.url;
    return url.includes('entreginfo') ||
        url.includes('enterprise') ||
        url.includes('agencyreginfo') ||
        url.includes('resetpassword') ||
        url.includes('verificationmail') ||
        url.includes('sendmail') ||
        url.includes('newunsubmitinfo') ||
        url.includes('storerelevantinfo') ||
        url.includes('newenterinfo') ||
        url.includes('invitation')
       
}
let isSessionExpired = false;
api.interceptors.request.use((config) => {

    // Do something before request is sent
    if (sessionStore.hasToken()) {
        config.headers['qfpay-login-token'] = sessionStore.getToken();
    }
    if (isLocaleApi(config)) {
        const lang = window.qf_locale.param;
        config.params = {
            ...config.params,
            locale: lang || DEFAULT_LOCALE,
        };
    }
    return config;
});
api.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (_.get(error, 'response.status') === 401) {
        if (!isSessionExpired) {
            isSessionExpired = true;
            Modal.warning({
                title: window.qf_locale.repeatLogin,
                // okText: window.qf_locale.ModalMessage.Ok,
                onOk() {
                    sessionStore.logout();
                    window.history.replaceState(null, null, '/auth/login');
                    window.location.reload();
                },
            });
            // window.alert(window.qf_locale.repeatLogin);
            
        }
    }
    return Promise.reject(error);
});

/**
 * unAuth api
 */
const unAuthApi = axios.create({
    baseURL: baseUrl,
    // timeout: 1000,
    withCredentials: true,
    // transformRequest: [data => JSON.stringify(data.data)],
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    },
});

export { unAuthApi, api }
export default api;