import invariant from 'invariant';
import {extendObservable, observable, action, runInAction} from 'mobx';
import _ from 'lodash';
import {
  BooleanField,
  DateField,
  NumberField,
  StringField,
  DecimalField,
  Field
} from './field';
import ModelManager from './ModelManager';
import uuid from '../util/uuid'

/*
 * Model装饰器
 *
 */
function model(options) {
  function transformFields(fieldConfig) {
    let result = {};
    let field;
    for(let name in fieldConfig) {
      invariant(name !== 'errors', 'the attribute errors is forbidden');
      if(typeof fieldConfig[name] === 'string') {
        result[name] = {
          type: fieldConfig[name],
          name: name
        };
      }else{
        result[name] = {
          ...fieldConfig[name],
          name: name
        };
      }
      switch(result[name].type) {
        case 'string':
          result[name] = new StringField(result[name]);
          break;
        case 'date':
          result[name] = new DateField(result[name]);
          break;
        case 'number':
          result[name] = new NumberField(result[name]);
          break;
        case 'boolean':
          result[name] = new BooleanField(result[name]);
          break;
        case 'decimal':
          result[name] = new DecimalField(result[name]);
          break;
        default:
          result[name] = new Field(result[name]);
      }
      field = result[name];
    }
    return field;
  }

  function isNil(value) {
    return value === '' || value === null || value === undefined;
  }

  const ASSOCIATIONS = ['belongsTo', 'hasOne', 'hasMany'];
  const ASSOCIATION_KEYS = ['model', 'name', 'key', 'type'];

  function createAttributes(data) {
    invariant(Reflect.has(this.options, 'fields'), 'must has fields config');
    let fields = this.options.fields;
    for(let name in fields) {
      let fieldOption = fields[name];
      let field = this.createField({[name]: fieldOption});
      if(Reflect.has(data, name)) {
        field.setValue(data[name]);
      }
    }
  }

  function setIdProperty(idProperty) {
    let id = idProperty || 'id';
    this.constructor.idProperty = id;
    this.constructor.prototype.idProperty = id;
  }

  return target => {
    let modelName;
    // constructor =====================
    let f = function(...args) {
      let data = args[0] || {};

      this.modelName = modelName = options.modelName || target.name;
      this.isModel = true;
      this.idProperty = options.idProperty || 'id';
      extendObservable(this, {
        fields: {},
        cid: null,
        destroyed: false,
        phantom: false,
        isSync: true,
        loading: false,
        saving: false,
        deleting: false,
        errors: {}
      });
      createAttributes.call(this, data);
      setIdProperty.call(this, this.idProperty);
      if (options.idgen) {
        if (typeof options.idgen === 'string') {
          if (options.idgen === 'uuid') {
            this.id = uuid();
          }
        } else {
          this.id = options.idgen(data);
        }
      }
      if(this.getId()) {
        this.cid = this.getId();
      }else{
        this.phantom = true;
        this.cid = 1;
      }
      return target.apply(this, args);
    };
    f.prototype = target.prototype;
    for (let k in target) {
      f[k] = target[k];
    }
    Reflect.defineProperty(f, 'name', {
      value: modelName,
      writable: false
    });

    // prototype [public] function ========================
    f.prototype.getAttributes = function() {
      let data = {};
      this.getFieldNames().forEach(fieldName => {
        let field = this[fieldName];
        if(field instanceof DecimalField) {
          data[fieldName] = field.result;
        }else{
          data[fieldName] = field;
        }
      });
      return data;
    };
    f.prototype.getFieldNames = function() {
      return Object.keys(this.fields);
    };
    f.prototype.getField = function(fieldName) {
      let record;
      let name;
      if(fieldName === undefined) {
        return undefined;
      }
      record = this;
      name = fieldName
      if(!record) {
        return undefined;
      }
      // invariant(Reflect.has(record.fields, name), `${name} not exist`);
      return record.fields[name];
    };
    f.prototype.getFieldType = function(fieldName) {
      let field = this.getField(fieldName);
      if(!field) {
        return null;
      }
      return field.type;
    };
    f.prototype.getIdProperty = function() {
      return this.idProperty;
    };
    f.prototype.getId = function() {
      let key = this.getIdProperty();
      return this[key];
    };
    f.prototype.setId = function(value) {
      this.set({
        [this.getIdProperty()]: value
      });
      return this;
    };
    f.prototype.isModified = function(key) {
      let field = this.getField(key);
      if(!field) {
        return false;
      }
      return this.getField(key).hasChange;
    };
    f.prototype.modifiedAttributes = function() {
      let data = {};
      this.getFieldNames().forEach(fieldName => {
        if(this.isModified(fieldName)) {
          data[fieldName] = this[fieldName];
        }
      });
      return data;
    };
    f.prototype.previous = function(key) {
      return this.getField(key).previousValue;
    };
    f.prototype.rollback = action(function(fieldName) {
      if(fieldName) {
        this.getField(fieldName).rollback();
      }else{
        this.getFieldNames().forEach(key => {
          this.getField(key).rollback();
        });
      }
      // 校验
      // this._validate(this.attributes());
      return this;
    });
    f.prototype.set = action(function(attrs, option = {}) {
      let dirty = option.dirty;
      if(dirty === undefined) {
        dirty = true;
      }
      let field;
      for(let k in attrs) {
        let record;
        let fieldName;
        record = this;
        fieldName = k;
        field = record.getField(fieldName);
        if(field) {
          field.setValue(attrs[k], {
            needUpdateTrace: !record.phantom,
            dirty: dirty
          });
        }
      }
      return this;
    });
    f.prototype.get = function(fieldName) {
      let record;
      let name;
      record = this;
      name = fieldName;
      if(!record) {
        return undefined;
      }
      return record[name];
    };
    f.prototype.unset = action(function(key) {
      delete this.fields[key];
      return this;
    });
    f.prototype.syncComplete = action(function() {
      this.phantom = false;
      this.isSync = true;
      this._updateTrace();
    });
    f.prototype.clone = function() {
      return new this.constructor(this.attributes());
    };
    f.prototype.clone = function() {
      let attr = this.getAttributes();
      return new f(attr);
    };

    // prototype [private] function =============================
    f.prototype._updateTrace = action(function() {
      for(let name in this.fields) {
        this.fields[name].originalValue = this.fields[name].previousValue = this[name];
        this.fields[name].hasChange = false;
      }
    });
    f.prototype.createField = action(function(fieldConfig) {
      let field = transformFields(fieldConfig);
      let name = field.name;
      this.fields[name] = field;

      if(field) {
        if(Reflect.has(field, 'defaultValue')) {
          field.setDefaultValue();
        }
        // if(Reflect.has(data, name)) {
        //   field.setValue(data[name]);
        // }else if(Reflect.has(field, 'defaultValue')) {
        //   field.setDefaultValue();
        // }else {
        //   field.setValue(null);
        // }
      }
      if(field.type === 'decimal') {
        // 将decimalField暴露出去
        extendObservable(this, {
          get [name]() {
            return field;
          }
        });
      }else{
        extendObservable(this, {
          get [name]() {
            return field.getValue();
          }
        });
      }
      extendObservable(this.errors, {
        get [name]() {
          return field.errors;
        }
      });
      return field;
    });

    f.prototype.dispose = action(function() {
      if(this.expandTreeDispose) {
        this.expandTreeDispose();
      }
    });
    f.prototype.isDirty = function(){
      return this.dirty;
    }

    // ============
    Reflect.defineProperty(f.prototype, 'dirty', {
      get: function() {
        return this.getFieldNames().some(key => this.isModified(key));
      }
    });
    f.modelName = f.prototype.modelName = options.modelName || f.name;
    f.prototype.options = options;
    ModelManager.setModel(f);

    return f;
  };

}
export default model;
