export const DEFAULT_LOCALE = 'jp';
export const DEFAULT_MOMENT_LOCALE = 'ja';
export function getCaptcha() {
  if (process.env.NODE_ENV === 'production') {
    return '/api/register/captcha?randstr=';
  }
  return 'http://localhost:8888/register/captcha?randstr=';
  //return 'http://123.57.238.198/register/captcha?randstr='
};
export function getBasePath() {
  let baseUrl = 'http://localhost:8888/api';
  if (process.env.NODE_ENV === 'production') {
    baseUrl = '/api';
  }
  return baseUrl;
}
export function getQrCodeRegisterPath() {
  let baseUrl = 'http://47.74.9.188:8898';
  return baseUrl;
}
export const UPLOAD_MAX_SIZE_PER_ITEM = Math.pow(1024, 2); // 1M
export const UPLOAD_STORE_MAX_SIZE_PER_ITEM = Math.pow(1024, 4); // 1M
export const UPLOAD_MIN_COUNT = 1 // 9张
export const UPLOAD_MAX_COUNT = 10 // 9张
export const DOMAIN = 'https://web.qfpay.info';