import { Model } from 'qn/data';

@Model({
  modelName: 'Channel',
  fields: {
    channelKey: 'string',
    hasPassedReview: 'boolean',
    channel: 'string',
    bankingOrg: 'string',
    currency: 'string',
    feeRate: 'number',
    comment: 'string',
    createdDate: 'date',
  },
  idProperty: 'channelKey',
})
class Channel {
  isLogicSame(record) {
    return this.bankingOrg === record.bankingOrg && this.currency === record.currency;
  }
}

export default Channel;
