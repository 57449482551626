import { Route, Redirect } from 'react-router-dom';
import React from 'react';
import { observer } from 'mobx-react';
import sessionStore from 'sessionStore';

const AuthorizedRoute = observer(({ component: Component, roles, ...rest }) => {
  const userRole = sessionStore.getUser().role;
  const hasAccess = roles && roles.some(o => o === userRole);
  const redirectUrl = sessionStore.getRedirectUrl();
  return (
    <Route
      {...rest}
      render={(props) => {
        // if (!redirectUrl.startsWith('/app')) {
        //   return (
        //     <Redirect
        //       to={{
        //         pathname: '/auth/login',
        //         state: { from: props.location },
        //       }}
        //     />
        //   );
        // }
        if (!roles) {
          return <Component {...props} />;
        }
        return hasAccess
          ? <Component {...props} />
          : (
            <Redirect
              to={{
                pathname: '/app',
                state: { from: props.location },
              }}
            />
          );
      }} 
    />
  );
});
export default AuthorizedRoute;

