export const BRANCH_STATUS = {
  NORMAL: 'NORMAL',
  CLOSED: 'CLOSED',
  BLOCKED: 'BLOCKED',
};
export const TIME = {
  F_NORMAL: 'YYYY-MM-DD HH:mm:ss',
  F_SHOW: 'YYYY/MM/DD HH:mm',
  F_SHOW_YMD: 'YYYY/MM/DD',
};
export const USER_STATUS = {
  NORMAL: 'NORMAL',
  BLOCKED: 'BLOCKED',
  TO_COMPLETE_MATERIAL: 'TO_COMPLETE_MATERIAL',
  TO_REVISE_MATERIAL: 'TO_REVISE_MATERIAL',
  AWAITING_REVIEW: 'AWAITING_REVIEW',
}
export const MERCHANT_USER_STATUS = {
  NORMAL: 'NORMAL',
  BLOCKED: 'BLOCKED',
  TO_COMPLETE_MATERIAL: 'TO_COMPLETE_MATERIAL',
  TO_REVISE_MATERIAL: 'TO_REVISE_MATERIAL',
  AWAITING_REVIEW: 'AWAITING_REVIEW',
  REVIEWING: 'REVIEWING',
  REVIEW_PASS: 'REVIEW_PASS',
};
export const MERCHANT_TYPE = {
  ENTERPRISE: 'ENTERPRISE',
  STORE: 'SUB_STORE'
}
export const CHANNEL = {
  ALIPAY: 'ALIPAY',
  WECHAT: 'WECHAT',
};
export const CURRENCY = {
  JPY: 'JPY',
  CNY: 'CNY',
  USD: 'USD',
};
export const USER_ROLE = {
  ADMIN : 'ADMIN',
  AGENT: 'AGENT',
  ISO: 'ISO',
  ISV_LEVEL_1: 'ISV_LEVEL_1',
  ISV_LEVEL_2: 'ISV_LEVEL_2'
}

export const BANK_TYPE = {
  BANK : 'BANK',
  FINANCIAL_COOPERATIVE : 'FINANCIAL_COOPERATIVE',
  CREDIT_COOPERATIVE : 'CREDIT_COOPERATIVE',
  AGRICULTURAL_COOPERATIVE : 'AGRICULTURAL_COOPERATIVE'
}
export const BRANCH_TYPE = {
  BRANCH_STORE : 'BRANCH_STORE',
  HEAD_OFFICE : 'HEAD_OFFICE',
  FIELD_OFFICE : 'FIELD_OFFICE',
  BRANCH_OFFICE : 'BRANCH_OFFICE'
}

export const ACCOUNT_TYPE = {
  SAVINGS: 'SAVINGS',
  CHECKING: 'CHECKING'
}

export const REVIEW_USER_TYPE = {
  INCOMPLETE_REGISTRATION: 'INCOMPLETE_REGISTRATION',
  ENTERPRISE_USER: 'ENTERPRISE_USER',
  STORE_USER: 'STORE_USER',
  SINGLE: 'SINGLE',
};
export const NULL_ID = 'LTE=';
export const USER_REGISTER_TYPE = {
  SELF_REGISTERED: 'SELF_REGISTERED',
  ENTERPRISE_REGISTERED: 'ENTERPRISE_REGISTERED',
};
export const DATE_PICKER_LANG = {
  'zh-CN': 'zh',
  'en': 'en',
  'jp': 'ja',
};
export const REVENUE_SEARCH_TYPE = {
  VIEW_BY_ISO: 'ALL_ISO',
  VIEW_BY_AGENT: 'ALL_AGENT',
}
export const CURRENCY_PRECISION = {
  JPY: 0,
  USD: 2,
  CNY: 2,
}