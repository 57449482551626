import React from 'react';
import sessionStore from 'sessionStore';
import { observer } from 'mobx-react';

function getUserAccess() {
  return sessionStore.getUser().role || [];
}

const Can = observer(({ allows, forbiddens, children }) => {
  const userRole = getUserAccess();
  if (allows && !allows.some(o => userRole.includes(o))) {
    return null;
  }
  if (forbiddens && forbiddens.some(o => userRole.includes(o))) {
    return null;
  }
  return children;
});

const canDoSome = (access) => {
  const userAccess = getUserAccess();
  if (!access || access.length === 0) {
    return true;
  }
  return access.some(o => userAccess.includes(o));
};
const canDoEvery = (access) => {
  const userAccess = getUserAccess();
  if (!access || access.length === 0) {
    return true;
  }
  return access.every(o => userAccess.includes(o));
};
const cannotDoSome = (access) => {
  const userAccess = getUserAccess();
  if (!access || access.length === 0) {
    return true;
  }
  return access.some(o => !userAccess.includes(o));
};
export { Can, canDoSome, canDoEvery, cannotDoSome };
