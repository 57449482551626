import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch } from 'react-router-dom';
import 'public/css/index.scss';
import * as models from 'model';
// require('util/canvas-toBlob');
import moment from 'moment';
import App from './App';
import { DEFAULT_MOMENT_LOCALE } from 'config';

moment.locale(DEFAULT_MOMENT_LOCALE);

// import { translate } from 'i18n/translate';
// translate.setLocale('zh-cn');
ReactDOM.render((
  
  <App/>
), document.getElementById('root'));
