import { Model } from 'qn/data';
import { computed } from 'mobx';

@Model({
  modelName: 'Earning',
  fields: {
    agencyId: 'number',
    agencyLevel: 'number',
    agencyName: 'string',
    name : 'string',
    monthDate: 'string',
    earnings: 'number',
    meAndLowerEarnings: 'number',
    phone: 'string'
  },
  idgen: 'uuid',
})
class Earning {

}

export default Earning;
