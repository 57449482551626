export default {
    l: 'jp',
    param: 'ja_JP',
    lang: '日本語',
    langSelection: '言語選択  ',
    brand: '営業管理システム',
    searchMore: 'さらに検索',
    search: '検索',
    modify: '訂正',
    delete: '削除',
    export: 'エクスポート',
    complete: '完了',
    cancel: 'キャンセル',
    all: '全て',
    add: '追加',
    back: '戻る',
    logout: '  ログアウト',
    logoutConfirm: 'ログアウトして終了してもよろしいですか？',
    submit: '提出',
    resubmit: '再提出',
    yes: 'はい',
    no: 'いいえ',
    psdError: '入力されたパスワードが正しくありません',
    noWebLoginAccess: '申し訳ありませんが、ログインする権限がありません。',
    operate: '操作',
    nextStep: '次へ',
    detail: '詳細',
    modifyPsd: 'パスワード変更',
    repeatLogin: 'このアカウントで他の端末からログインがありました、直ちにログアウトします。',
    thatQrcode: 'QRコード',
    thatQrcodeHover: '店舗管理者がQRコードをスキャンしますと、加盟店管理システムの登録ページへ案内されます。登録ページで店舗登録が完了になり次第、自動的にこちらの代理店情報が更新されます。',
    noAuth: '操作する権限がありません。',
    none: '無し',
    certificateOfIncorporation: '営業許可書',
    total: '全部',
    count: '件',
    noData: 'データなし',
    choose:'選択してください',
    home: {
      title: 'トップページ',
      sevenDayEarnings: '最近７日間利益',
      thirtyDayEarnings: '最近３０日間利益',
      sevenDay: '最近７日間',
      thirtyDay: '最近３０日間',
      todaySignNum: '本日加盟店総数',
      monthSignNum: '本月合計',
      allSignNum: '加盟店総数',
      waitHandle : '料率保留中',
      notBeenSubmitted: '提出待ち',
      inTheReview: '審査中',
      haveBeenThrough: '審査通過',
      auditFailure: '審査失敗',
      storeSignTrend: '契約加盟店数の推移',
      agencyTOP3: '代理店ランキングtop3',
      agentTOP3: '営業担当者ランキングtop3',
      storeTOP3: '店舗ランキングtop3',
      growthMost: '増加率',
      dropMost: '減少率',
      signNumberMost: '契約加盟店数',
      totalNumberMost: '収益',
      earnings: '利益の割合',
      signPercentage: '審査通過済み加盟店数の割合',
      signNum: '契約済み加盟店数',
      top3Detail1:'当該代理ランキングtop3というのは、ご各級代理の契約済み加盟店数の増加率、減少率及び契約済み加盟店数の週間ランキングにおける変化表示です。周期は先週と先々週とのデータの比較です。',
      top3Detail2:'当該営業担当者ランキングtop3というのは、ご各級代理の契約済み加盟店数の増加率、減少率及び契約済み加盟店数の週間ランキングにおける変化表示です。周期は先週と先々週とのデータの比較です。',
      top3Detail3:'当該店舗ランキングtop3というのは、ご各級代理の契約済み加盟店数の増加率、減少率及び契約済み店舗による収益力の週間ランキングにおける変化表示です。周期は先週と先々週とのデータの比較です。',
      top3Detail4:'当該店舗統計top2というのは、ご各級代理の契約済み加盟店数の増加率、減少率及び契約済み店舗による収益力の週間ランキングにおける変化表示です。周期は先週と先々週とのデータの比較です。',
      top3Detail5:'ウォレットによる収入top5とは、ご開通のウォレットチャンネルによる取引金額のランキング表示です。',
      },
    login: {
      welcome:'ようこそ',
      id: 'ログインID',
      idPlaceholder: 'メールアドレスを入力してください',
      storeownerEmail: '店舗管理者メールアドレス',
      psd: 'パスワード',
      psdPlaceholder: 'パスワードを入力してください',
      workNumber: '従業員番号',
      workNumberPlaceholder: '従業員番号を入力してください',
      autoLogin: 'ログインしたままにする',
      shopkeeperLogin: '店舗管理者',
      employeeLogin: '従業員',
      forgetPsd: 'パスワードを忘れた場合',
      submit: 'ログイン',
      emailSend:'送信',
      noLoginIdTip: 'まだアカウントがない場合、',
      registerNow: 'すぐに登録する',
      mail: '申請メールアドレス',
      forgetMail: 'ログインメールアドレス',
      mailPlaceholder: 'メールアドレスを入力してください',
      findPassword:'パスワードをお忘れの方へ',
      Message:'８桁以上のパスワードを設定してください。他のウェブサイトへのログインで使用中のパスワード、または第三者に推測されやすいパスワードの使用を避けてください（例：１２３４５６）。',
      remind:'パスワードを記憶する場合',
      goLog:'ログインへ',
      newPad : '新たなパスワード',
      newPadConfirm: '新たなパスワードの確認'
    },
    qrCodeRegister:{
      newtitle:'加盟店追加',
      brdtitleStep1:'企業情報登録',
      brdtitleStep2:'店舗情報登録',
      brdtitleStep3:'完了',
      entertitle:'企業情報登録',
      storetitle:'店舗情報登録',
      next:'次へ',
      commit:'提出完了!',
      committext:'記入した登録情報はすでに提出されました。審査が通ったらメールでお知らせします。',
      waitmail:'審査を待ってください......',

      enterprisemail:'企業メール',
      enterprisemailconfim:'確認メールアドレス',
      enterprisename:'企業名',
      enterprisetype:'加盟店種類',
      companycode:'法人番号',
      accountphone:'営業担当者番号',

      storemail:'店舗メール',
      storemailconfim:'確認メールアドレス',
      storename:'实际店铺名称',
      prefecture:'都道府県',
      municipalward:'市区町村',
      detailadress:'詳細住所',
      streetnumber:'建物名・部屋番号',
      zipcode:'郵便番号',
      businesscode:'担当者氏名',
      businessphone:'担当者番号',

      enterprisemailPlaceholder:'例：qfpay@qfpay.com',
      enterprisemailconfimPlaceholder:'企業メールアドレスをもう一度入力してください',
      enterprisenamePlaceholder:'企業名を入力してください',
      enterprisetypePlaceholder:'例：飲食',
      companycodePlaceholder:'0100-01-178407',
      accountphonePlaceholder:'090-1374-1287',

      storemailPlaceholder:'例：qfpay@qfpay.com',
      storemailconfimPlaceholder:'店舗メールアドレスをもう一度入力してください',
      storenamePlaceholder:'店舗名を入力してください',
      prefecturePlaceholder:'東京都',
      municipalwardPlaceholder:'港区',
      detailadressPlaceholder:'愛宕グリーンヒルズMORIタワー38F',
      streetnumberPlaceholder:'2-5-1',
      zipcodePlaceholder:'160-0023',
      businesscodePlaceholder:'佐藤太郎',
      businessphonePlaceholder:'090-1374-1287',
      registerInfo1:'ログアウト前に、入力された内容は保存されません。登録情報を記入する場合、5~8分間かかりますので',
      registerInfo2:' 記入情報を事前にご用意ください。',
    },

    register: {
      newtitle:'代理店登録',
      agentInformation:'エージェント情報',
      mailtitle:'メールアドレス認証',
      success:'完了',
      title: 'アカウント情報',
      mail: 'メールアドレス（ログインIDとする）',
      mailPlaceholder: '例：qfpay@qfpay.com',
      mailConfirm: '確認メールアドレス',
      mailConfirmPlaceholder: 'メールアドレスをもう一度入力してください',
      captcha: '認証コードの取得',
      newcaptcha:'認証コード',
      captchaPlaceholder: 'コードを入力してください',
      termsOfService: '「利用規約」',
      privacyRelatedPolicies: '「プライバシーポリシー」',
      agree: '同意する',
      and: 'と',
      submit: '登録',
      hasLoginId: '登録済みのアカウントで',
      toLogin: 'ログイン',
      psd: 'パスワード',
      psd2:"******",
      psdPlaceholder: 'パスワードを入力してください',
      psdConfirm: 'パスワードの確認',
      psdConfirmPlaceholder: 'パスワードをもう一度入力してください',
      loginId: 'ログインID',
      phone: '担当者連絡電話',
      name: '担当者名前',
      agentInformation:'代理店情報',
      mailVerifyInvalid: 'リンクの有効時間が過ぎました。',
      waitingTip: 'ご提出いただいた申請は現在審査中です。申請は1～2営業日以内に処理いたしますので、しばらくお待ちください。',
      failureTip: '申し訳ありませんが、ご提出頂いた情報の一部に誤りがありました。ご確認のうえ修正いただき、もう一度申請してください。',
      errorCaptcha: '検証コードに誤りがあります',
      phonePlaceholder: '固定電話・携帯電話を入力してください',
      namePlaceholder: '例: 佐藤太郎',
      agencyNamePlaceholder: '例: 株式会社〇〇〇〇',
      agencyName: '代理店会社名',
      privacyTip: '（株）QFPay Japanと営業パートナー業務提携契約を締結したうえ、お申し込みください。',
      next:'次へ',
      invitationCode:'招待コード',
      invitationcode2:'招待コードを入力',
      mail2: 'メールアドレス',
      mailPlaceholder2: '加盟店管理システムのログインIDとする',
      mailPlaceholderStore: '店舗ユーザーログインID',
      mailPlaceholderEnterprise: '企業ユーザーログインID',
      storeUserLoginMail: '店舗用ログインメールアドレス',
      defaultPassword: '初期パスワードは123456',
      theDefaultPassword: '初期パスワード',
      captchaPlaceholder: '大文字・小文字を区別',
      storeInfo: '店舗情報',
      attachedStoreInfo: '添付店舗情報',
      storeName: '支店名',
      storeAddress: '支店住所',
      storeEmail: '支店メールアドレス',
      storeTel: '支店電話番号',
      bankInfo: '銀行情報',
      bankCode: '金融機関コード',
      bankName: '銀行名称',
      branchBankCode: '支店番号',
      branchBankName: '支店名',
      accountType: '預金類別',
      accountName: '銀行口座名',
      accountCode: '口座番号',
      settlementCurrency: '決済通貨',
      storeNamePlaceholder: '例：鮨　次郎',
      storeAddressPlaceholder: '例：東京都港区西新橋３丁目２５−８',
      storeEmailPlaceholder: '例：qfpay@qfpay.com',
      storeTelPlaceholder: '例：090-1374-1287',
      bankCodePlaceholder: '例：0005',
      bankNamePlaceholder: '例：三菱銀行',
      branchBankCodePlaceholder: '例：251',
      branchBankNamePlaceholder: '例：新瑞橋支店',
      accountTypePlaceholder: '例：普通預金',
      accountNamePlaceholder: '例：カブシキガイシャ〇〇〇〇',
      accountCodePlaceholder: '例：12414141',
      psdPlaceholder: '6~8桁の数字と英字を入力してください',
      isDevicePurchase: '端末を購入するか',
      loadingmore:'もっとローダする',

    },
    registerAftermail:{
      agentManagement: '代理店管理 ',
      homePagenew:'代理店ホームページ',
      create: '代理店追加',
      mailtitle:'メールアドレス認証',
      agentInformation:'代理店情報 ',
      success:'完了',  
      mailRegistration:'メールアドレスによる登録',
      captcha: '検証コードの取得',
      newcaptcha:'検証コード:',
      bankInfo: '銀行情報',
      bankName : '銀行名称',
      bankCode : '金融機関番号',
      bankType:'銀行タイプ',
      branchName : '銀行支店名称',
      branchnumber:'支店番号',
      Subbranchtype:'銀行支店タイプ',
      Accounttype:'口座タイプ',
      Bankaccountname:'銀行口座名義人(半角カタカナ)',
      Bankaccountname2:'銀行口座名義人',
      Bankaccount:'銀行口座',
      psd: 'パスワード入力',
      psdConfirm: 'パスワード確認',
      bankInfo: '銀行情報',
      ChannelAddition:'チャンネルの追加',
      submit:'提出',
      man:'男',
      woman:'女',
      agentInformation:'代理店情報 ',
      corporateInformation:'法人情報',
      Contacts:'連絡先情報',
      undertaker:'担当者情報',
      Preservation:'保存',
      trueEmail: '正しいメールアドレスを入力してください！',
      filEmail:'メールアドレスを入力してください',
      psd:'パスワード',
      popcontent:'ログアウト前に、入力された内容をご保存ください。再びログイン時に続けます。',
      fillcode:'検証コードを入力してください',
      psdPlaceholder: 'パスワードを入力してください！',
      psdConfirmPlaceholder: 'もう一度パスワードを入力してください！',
      fillInformation:'情報を入力してください',
      fillNumber:'番号を入力してください',
      filEmail:'メールアドレスを入力してください',
      rate:'レートを入力してください',
      addTips:'（ 注意：今から、すべてのチャンネルが開通されています。）',
      toLogin1:'アカウントをお持ちの場合、',
      toLogin2:'ログインへ',
      picDetail:'鮮明で、実際の写真をアップロードしてください。写真には文字、数字、URLなどのような内容を加えないでください。名刺、ｑｒコード、自取り写真、風景写真などのような関係ない写真をアップロードしないでください。JPG、PNGの形式が使われ、最多９枚アップロードできて、毎枚のバイト数は最大２Ｍです。',
      WeChatExplain:'中国で最も利用されているコミュニケーションアプリ「WeChat（微信）」内で提供されている決済サービスが「WeChat Pay」です。',
      AlipayExplain:'支付宝(Alipay / アリペイ)」とは、タオバオをはじめとしたネットショップサイトで利用される、全世界のユーザー数が10億人を超えた（※2019年1月時点）とされる世界最大の第三者決済です。',
      OrigamiExplain:'Origamiは、「お金、決済、商いの未来を創造する」というミッションのもと、キャッシュレスを実現するプラットフォームを提供しています。',
      PaypayExplain:'ソフトバンクとYahoo!Japanが共同で進めるスマホ決済アプリPayPay（ペイペイ）',
      NetspayExplain:'シンガポールの３大銀行すべてのQRコード決済が行えるサービス。',
      RakutenpayExplain:'楽天ペイは楽天以外のショップでも、お持ちの楽天IDでかんたんに決済ができます。',
      UmspayExplain:'銀聯（UnionPay / ユニオンペイ）カードとは、国際5ブランド（VISA、MASTER、JCB、AMERICAN EXPRESS、DINERS CLUB）に続く第6の国際ブランドとしての地位を確立しつつあります。',
      VIAExplain:'シンガポールのDash、タイのGlobal Payが使える。',
      DocomoExplain:'d払いは、街のお店やネットショッピングでのお支払いを月々のケータイ料金と合算して支払える、かんたん・便利・おトクなスマホ決済サービスです。',
      PringExplain:'pring（プリン）は、友達や家族とメッセージを送るような感覚でお金のやり取りができるスマホアプリです。',
      SolPayExpain: '韓国国内第二位の新韓銀行が提供するQRコード決済',
      GMOExpalin : 'オンライン決済にを得意とする決済システム',
      DiXingExpalin : '国境を越えた支払いおよび銀行会員サービスプロバイダー',
      tBTCExplain: '誰もがBTCを利用してtBTC（ERC20）を作成できる仕組みを目指している。第三者を信用しないくても良いという点と誰もがBTCでtBTCをミントでき、かつデポジット者がコントロールできるウォレットに格納できるという点がメリットとなります。',
      BTCExplain: 'ビットコインは、新しい決済システムと完全な電子通貨を実現するものであり、中央機関や仲介者を必要としない、ユーザーによる初の分散型P2P決済ネットワークです。ユーザーの観点からは、ビットコインはインターネット上のお金の様なものです。また、ビットコインは現存するものの中で、最も優れた三式簿記システム ともいえます。',
      ACDExplain: 'ACD COINは、航空会社やECプラットフォームと連携し、グローバルなオンラインショップ、オフラインショップで利用することができます。',
      ContentPic1:'個人事業主の場合は、必ず営業許可証が必要になります。 (写真/スキャンデーター)',
      ContentPic2:'振込口座が確認できるページの写真/スキャンデーター',


    },
    registerMore: {
      belongToEInfo: '企業名',
      belongToEName: '所属企業名称',
      hasEnterprise: '親会社は登録されていますか？',
      has: 'はい',
      not: 'いいえ',
      privacy: 'QFPayの利用規約を店舗に確認しました'
    },
    registerMailSended: {
      title: '確認メール',
      content1: '確認メールをご登録いただいたメールアドレスに送付しました。',
      content2: 'メールボックスをチェックして、QFPayからの確認メールに従い、アカウントの作成を完了させてください。'
    },
    agentregistration:{
      newtitle:'代理店登録',
      mailRegistration:'メールアドレス',
      agentInformation:'代理店情報',
      success:'完了',   
      Submitted:' 完了しました!',
      SubmissionDetails:'ご登録情報をご提出いただきありがとうございます。',
      SubmissionDetails2:'審査をお待ちください。',
      loginto:'ログインへ',
      backPage:'ホームページに戻る',
    },
    registerStep: {
      register: 'アカウント情報',
      moreInfo: '詳細情報',
      waitingReview: '審査待ち',
    },
    revenue: {
      typeLabel: '検索分類',
      viewType: {
        iso: '代理店',
        agent: '営業担当者'
      },
      search: {
        tradeTime: '取引時間',
        keywordPlaceholder: 'キーワードを入力してください',
      },
      key: 'システムコード',
      isoName: '代理店名',
      agencyCode: '代理店コード',
      cashFlow: '取引金額',
      superiorName: '一級代理',
      agentId: '営業担当者システムコード',
      agentCode: '営業担当者コード',
      agentName: '営業担当者氏名',
      parentAgency: '所属代理',
      phone: '連絡先',
    },
    merchant: {
      managementTitle: '店舗管理',
      statTitle: '店舗統計',
      unReview: '審査待ち店舗数',
      pendingReview: '審査中店舗数',
      reviewFailure: '審査失敗店舗数',
      signedMerchant: '審査通過店舗数',
      agentName: '代理店名/営業担当者名',
      addMerchant: '店舗追加',
      myQrcode: 'QRコード',
      qrcodeHover: '店舗管理者がQRコードをスキャンしますと、加盟店管理システムの登録ページへ案内されます。登録ページで店舗登録が完了になり次第、自動的にこちらの代理店情報が更新されます。',
      download: 'ダウンロード',
      registerTime: '登録時間',
      enterpriseName: '所属企業名',
      storeName: '支店名',
      type: 'タイプ',
      address: '住所',
      channels: '登録ウォレット',
      reviewingChannels: '審査中ウォレット',
      status: '審査状態',
      typeEnum: {
        ENTERPRISE: '企業',
        SUB_STORE: '支店',
      },
      attachedTypeEnum: {
        ENTERPRISE: '企業',
        SUB_STORE: '付属店舗',
      },
      statusEnum: {
        AWAITING_REVIEW: '審査待ち',
        TO_REVISE_MATERIAL: '審査失敗',
        REVIEW_PASS: '審査通過',
        REVIEWING: '審査中',
      },
      search: {
        registerTime: '登録時間',
        keywords: 'キーワード検索',
        keywordsPlaceholder: '企業名/支店名を入力してください',
      },
      // register: {    

      //   mail: 'メールアドレス',
      //   mailPlaceholder: '加盟店管理システムのログインIDとする',
      //   mailPlaceholderStore: '店舗ユーザーログインID',
      //   mailPlaceholderEnterprise: '企業ユーザーログインID',
      //   storeUserLoginMail: '店舗用ログインメールアドレス',
      //   defaultPassword: '初期パスワードは123456',
      //   theDefaultPassword: '初期パスワード',
      //   captchaPlaceholder: '大文字・小文字を区別',
      //   storeInfo: '店舗情報',
      //   attachedStoreInfo: '添付店舗情報',
      //   storeName: '支店名',
      //   storeAddress: '支店住所',
      //   storeEmail: '支店メールアドレス',
      //   storeTel: '支店電話番号',
      //   bankInfo: '銀行情報',
      //   bankCode: '銀行コード',
      //   bankName: '銀行名',
      //   branchBankCode: '支店番号',
      //   branchBankName: '支店名',
      //   accountType: '預金類別',
      //   accountName: '銀行口座名',
      //   accountCode: '口座番号',
      //   settlementCurrency: '決済通貨',
      //   storeNamePlaceholder: '例：鮨　次郎',
      //   storeAddressPlaceholder: '例：東京都港区西新橋３丁目２５−８',
      //   storeEmailPlaceholder: '例：qfpay@qfpay.com',
      //   storeTelPlaceholder: '例：090-1374-1287',
      //   bankCodePlaceholder: '例：0005',
      //   bankNamePlaceholder: '例：三菱銀行',
      //   branchBankCodePlaceholder: '例：251',
      //   branchBankNamePlaceholder: '例：新瑞橋支店',
      //   accountTypePlaceholder: '例：普通預金',
      //   accountNamePlaceholder: '例：カブシキガイシャ〇〇〇〇',
      //   accountCodePlaceholder: '例：12414141',
      //   psdPlaceholder: '数字六桁から八桁まで入力してください',
      //   isDevicePurchase: '端末を購入するか',
      // },
      add: '店舗追加',
      copy: 'リンクをコピー',
      complete: 'QRコードリンクがクリップボードにコピーされました。',
      hasBeendisabled: '使用禁止',
    },
    merchantRevenue: {
      revenue: '売上',
      dividends: '私の割前',
      dividendsInMonth: '当月の利益',
      showMore: '詳細',
      title: '売上の詳細',
      merchantDividends: '商店からの利益',
      agencyDividends: '下位代理店からの利益',
      agentDividends: '営業員からの利益',
      search: {
        date: '検索時間',
        channel: 'チャンネル',
      }
    },
    footer: {
      concatInfo: '連絡先',
      concatMail: 'メールアドレス',
      concatTel: '電話番号',
      businessHours: '営業時間',
      except: '定休日と祝日は除く',
      useRole: '利用規約',
      privacy: 'プライバシーポリシー',
      companyInfo: '会社情報',
      aboutUs: '私たちについて',
      qfpay: 'QFPay Japan',
      payMode:'決済サービス',
      address:'住所：〒105-0001 東京都港区虎ノ門 1-10-5 KDX Toranomon 1 Chome 11F 株式会社QFPay Japan',
      payModeDetail:'QFPayで利用可能な決済サービスは以下のとおりです',
    },
    nav: {
      title: 'トップページ',
      agentSettlementSelf: '精算管理',
      merchantManagement: '店舗管理',
      agentManagement: '営業担当者管理',
      agencyManagement: '代理店管理',
      agencySettlement: '代理店清算',
      agentSettlement: '営業担当者精算',
      bankInfo: '契約情報',
      RevenueSearch: '代理店取引検索',
      ISOManagement: '一級代理店管理',
      ISV1Management: '二級代理店管理',
      ISV2Management: '三級代理店管理',
      ISOReview: '一級代理店審査',
      ISOSettlement: '一級代理店精算',
      am:'営業管理',
      Storemanagement:'店舗管理',
      Revenuemanagement:'収益管理',
      messageManagent:'通知管理',
      groupManagent:'グループ管理',
      accountManage:'アカウント管理',
      infoManage:'情報管理',
      dataDownLoad:'資料ダウンロード'
        },
    agency: {
      agencyName: '代理店名',
      agentCode: '代理店コード',
      new: '代理店追加',
      info: 'ユーザー情報',
    },
    agent: {
      agentCode: '営業担当者コード',
      new: '営業担当者追加',
      AgentTitle:'営業担当者管理',
      AgentName:'営業担当者管理',
      AgentAdd:'営業担当者の追加',
      AgentDetail:'営業担当者詳細',
      AgentLocal:'基本情報',
      AgentMail:'登録メールアドレス',
      AgentPhone:'携帯電話',
      AgentNa:'担当者名称',
      AgentNo:'営業担当者番号',
      currency:'決済通貨',
      feerate:'レート',
      all:'全部',
      one:'件',
      loginId: 'ログインID',
    },
    bankInfo: {
      title: '銀行情報',
      bankCode: '銀行番号',
      bankName: '銀行名',
      settlementCurrency: '決済通貨',
      accountType: '口座種類',
      accountName: '銀行口座名',
      accountCode: '口座番号',
      branchCode: '支店番号',
      branchName: '支店名',
      noData: '銀行情報まだ登録されていません',
      accountTypeEnum: {
        SAVINGS: '普通',
        CHECKING: '当座'
      },
      bankTypeEnum : {
        BANK : '銀行',
        FINANCIAL_COOPERATIVE : '信金',
        CREDIT_COOPERATIVE : '信組',
        AGRICULTURAL_COOPERATIVE : '農協'
    },
    branchTypeEnum : {
      BRANCH_STORE : '支店',
      HEAD_OFFICE : '本店',
      FIELD_OFFICE : '出張所',
      BRANCH_OFFICE : '支所'
    },
      bankNamePlaceholder: '例：三菱銀行',
      bankCodePlaceholder: '例：0005',
      bankTypePlaceholder: '銀行',
      branchBankNamePlaceholder: '例：新瑞橋支店',
      branchBankCodePlaceholder: '例：251',
      branchTypePlaceholder : '例：支店',
      accountTypePlaceholder: '例：普通預金',
      accountNameHalfKataPlaceholder :'例：カブシキガイシャ〇〇〇〇',
      accountNamePlaceholder: '例：株式会社〇〇〇〇',
      accountCodePlaceholder: '例：12414141',
    },
      companyInfo: {
      devicePurchaseType: {
        lease: 'レンタル',
        buy: '購入する',
        noBuy: '購入しない',
      }
    },
    review: {
      search: {
        registerTime: '登録時間',
        keywords: 'キーワード検索',
        keywordsPlaceholder: 'メール/連絡先を入力してください',
      },
      statusEnum: {
        AWAITING_REVIEW: '審査待ち',
        TO_REVISE_MATERIAL: '審査失敗',
        NORMAL: '審査通過',
        TO_COMPLETE_MATERIAL: '提出待ち'
      },
      registerTime: '登録時間',
      name: '一次代理店名',
      mail: 'メールアドレス',
      audit: '審査',
      status: '審査状態',
      loginId: 'ログインID',
    },
    isoManagement: {
      key: 'システムコード',
      loginId: 'ログインID',
      name: '一次代理店名',
      mail: 'メールアドレス',
      registeredChannel: '登録済みウォレット',
      phone: '連絡先',
      resetPsd: 'パスワード変更',
      search: {
        keywords: 'キーワード検索',
        keywordsPlaceholder: 'メール/連絡先を入力してください',
      },
    },
    isvManagement: {
      id: 'システムコード',
      agencyCode: '代理店コード',
      loginId: 'ログインID',
      isoName: '一次代理店名',
      isv1Name: '二次代理店名',
      superIsvName: '紹介を受けた代理店名',
      name: '代理店名',
      mail: 'メールアドレス',
      phone: '連絡先',
      resetPsd: 'パスワードを変更',
      search: {
        keywords: 'キーワード検索',
        keywordsPlaceholder: 'メール/連絡先を入力してください',
      },
    },
    agentCreate:{
      agentManagement: '代理店管理',
      homePagenew:'代理店ホームページ',
      create: '代理店追加',
      mailRegistration:'メールアドレスによる登録',
      mailConfirm: '確認メールアドレス',
      mailConfirmPlaceholder: 'メールアドレスをもう一度入力してください',
      captcha: '検証コードの取得',
      newcaptcha:'検証コード:',
      bankInfo: '銀行情報',
      bankName : '銀行名称',
      bankCode : '金融機関番号',
      bankType:'銀行タイプ',
      branchName :'銀行支店名称',
      branchnumber:'銀行支店番号',
      Subbranchtype:'銀行支店タイプ',
      Accounttype:'口座種類',
      Bankaccountname:'銀行口座名義人(半角カタカナ)',
      Bankaccountname2:'銀行口座名義人',
      Bankaccount:'銀行口座',
      captchaPlaceholder: 'コードを入力してください',
      mailPlaceholder: '例：qfpay@qfpay.com',
      psd2:"******",
      psd: 'パスワードを入力してください',
      psdConfirm: 'パスワード確認',
      bankInfo: '銀行情報',
      ChannelAddition:'チャンネル追加',
      submit:'提出',
      man:'男',
      woman:'女',
      mailtitle:'メールアドレス認証',
      agentInformation:'代理店情報',
      corporateInformation:'法人情報',
      Contacts:'連絡先情報',
      undertaker:'担当者情報',
      success:'完了',   
      Preservation:'保存',
      trueEmail: '正しいメールアドレスを入力してください！',
      filEmail:'メールアドレスを入力してください',
      psd:'パスワード',
      popcontent:'ログアウト前に、入力された内容をご保存ください。再びログイン時に続けます。',
      fillcode:'検証コードを入力してください',
      psdPlaceholder: 'パスワードを入力してください！',
      psdConfirmPlaceholder: 'もう一度パスワードを入力してください！',
      psdConfirmNewPlaceholder: 'もう一度パスワードを入力してください！',
      fillInformation:'情報を入力してください',
      fillNumber:'番号を入力してください',
      filEmail:'メールアドレスを入力してください',
      rate:'レートを入力してください',
      rateTip1 : 'のレート範囲：最小限:',
      rateTip2 : '最大限:',
      rateTip3: 'です',
      picDetail:'鮮明で、実際の写真をアップロードしてください。写真には文字、数字、URLなどのような内容を加えないでください。名刺、ｑｒコード、自取り写真、風景写真などのような関係ない写真をアップロードしないでください。JPG、PNGの形式が使われ、最多９枚アップロードできて、毎枚のバイト数は最大２Ｍです。',
      WeChatExplain:'中国で最も利用されているコミュニケーションアプリ「WeChat（微信）」内で提供されている決済サービスが「WeChat Pay」です。',
      AlipayExplain:'支付宝(Alipay / アリペイ)」とは、タオバオをはじめとしたネットショップサイトで利用される、全世界のユーザー数が10億人を超えた（※2019年1月時点）とされる世界最大の第三者決済です。',
      OrigamiExplain:'Origamiは、「お金、決済、商いの未来を創造する」というミッションのもと、キャッシュレスを実現するプラットフォームを提供しています。',
      PaypayExplain:'ソフトバンクとYahoo!Japanが共同で進めるスマホ決済アプリPayPay（ペイペイ）',
      NetspayExplain:'シンガポールの３大銀行すべてのQRコード決済が行えるサービス。',
      RakutenpayExplain:'楽天ペイは楽天以外のショップでも、お持ちの楽天IDでかんたんに決済ができます。',
      UmspayExplain:'銀聯（UnionPay / ユニオンペイ）カードとは、国際5ブランド（VISA、MASTER、JCB、AMERICAN EXPRESS、DINERS CLUB）に続く第6の国際ブランドとしての地位を確立しつつあります。',
      VIAExplain:'シンガポールのDash、タイのGlobal Payが使える。',
      DocomoExplain:'d払いは、街のお店やネットショッピングでのお支払いを月々のケータイ料金と合算して支払える、かんたん・便利・おトクなスマホ決済サービスです。',
      PringExplain:'pring（プリン）は、友達や家族とメッセージを送るような感覚でお金のやり取りができるスマホアプリです。',
      SolPayExpain: '韓国国内第二位の新韓銀行が提供するQRコード決済',
      GMOExpalin : 'オンライン決済にを得意とする決済システム',
      DiXingExpalin : '国境を越えた支払いおよび銀行会員サービスプロバイダー',
      tBTCExplain: '誰もがBTCを利用してtBTC（ERC20）を作成できる仕組みを目指している。第三者を信用しないくても良いという点と誰もがBTCでtBTCをミントでき、かつデポジット者がコントロールできるウォレットに格納できるという点がメリットとなります。',
      BTCExplain: 'ビットコインは、新しい決済システムと完全な電子通貨を実現するものであり、中央機関や仲介者を必要としない、ユーザーによる初の分散型P2P決済ネットワークです。ユーザーの観点からは、ビットコインはインターネット上のお金の様なものです。また、ビットコインは現存するものの中で、最も優れた三式簿記システム ともいえます。',
      ACDExplain: 'ACD COINは、航空会社やECプラットフォームと連携し、グローバルなオンラインショップ、オフラインショップで利用することができます。',

},
    agentdetail:{
      agentManagement: '代理店管理',
      homePagenew:'代理店ホームページ',
      agentdetail:'代理店詳細',
      ChannelAddition:'チャンネル詳細',
      feeRate: 'レート:',
      man:'男',
      woman:'女',
      bankInfo: '銀行情報',
      bankName : '銀行名称',
      bankCode : '金融機関番号',
      bankType:'銀行タイプ',
      branchName : '銀行支店名称',
      branchnumber:'銀行支店番号',
      Subbranchtype:'銀行支店タイプ',
      Accounttype:'口座種類',
      Bankaccountname:'銀行口座名義人 (半角カタカナ)',
      Bankaccountname2:'銀行口座名義人',
      Bankaccount:'銀行口座',
      rate:'レートをご記入ください',
    },
    accountdetail:{
      agentManagement: '代理店管理',
      homePagenew:'代理店ホームページ',
      agentdetail:'代理店詳細',
      ChannelAddition:'チャンネル詳細',
      feeRate: 'レート:',
      man:'男',
      woman:'女',
      bankInfo: '銀行情報',
      bankName : '銀行名称',
      bankCode : '金融機関番号',
      bankType:'銀行タイプ',
      branchName : '銀行支店名称',
      branchnumber:'銀行支店番号',
      Subbranchtype:'銀行支店タイプ',
      Accounttype:'口座種類',
      Bankaccountname:'銀行口座名義人 (半角カタカナ)',
      Bankaccountname2:'銀行口座名義人',
      Bankaccount:'銀行口座',
      rate:'レートをご記入ください',
      accountManage:'アカウント管理',
      infoManage:'情報管理',
      contactInfo:'基本情報',
      loginId:'ログインID',
      loginPsd:'パスワード',
      tell:'連絡先',
      agentName:'代理店名称',
      qrCode:'個人QRコード',
      invitationCode:'招待コード',
      inputOldPsd:'パスワードを入力してください',
      inputNewPsd:'新しいパスワードを入力してください',
      confirmNewPsd:'再確認',
      resetPsdBtn:'パスワード変更',
      psdInfoTitle:'パスワードの強度:',
      psdInfo:'パスワードは、6桁～8桁の長さで、英字と数字の両方を含む必要があります。他のウェブサイトへのログインで使用中のパスワード、または第三者に推測されやすいパスワードの使用を避けてください（例：123abc）。',
    },

    agentedit:{
      agentManagement: '代理店管理',
      homePagenew:'代理店ホームページ',
      edit: '情報編集',
      create: '代理店追加',
      mailRegistration:'メールアドレスによる登録',
      psd: 'パスワード',
      bankInfo: '銀行情報',
      bankName : '銀行名称',
      bankCode : '銀行番号',
      bankType:'銀行タイプ',
      branchName : '銀行支店名称',
      branchnumber:'銀行支店番号',
      Subbranchtype:'銀行支店タイプ',
      Accounttype:'口座種類',
      Bankaccountname:'銀行口座名義人(半角カタカナ)',
      Bankaccountname2:'銀行口座名義人',
      Bankaccount:'銀行口座',
      ChannelAddition:'チャンネル追加',
      submit:'提出',
      mailtitle:'メールアドレス認証',
      agentInformation:'代理店情報',
      corporateInformation:'法人情報',
      Contacts:'連絡先情報',
      undertaker:'担当者情報',
      success:'完了',   
      Preservation:'保存',
      popcontent:'ログアウト前に、入力された内容をご保存ください。再びログイン時に続けます。',
      man:'男',
      woman:'女',
      rate:'レートをご記入ください',
      WeChatExplain:'中国で最も利用されているコミュニケーションアプリ「WeChat（微信）」内で提供されている決済サービスが「WeChat Pay」です。',
      AlipayExplain:'支付宝(Alipay / アリペイ)」とは、タオバオをはじめとしたネットショップサイトで利用される、全世界のユーザー数が10億人を超えた（※2019年1月時点）とされる世界最大の第三者決済です。',
      OrigamiExplain:'Origamiは、「お金、決済、商いの未来を創造する」というミッションのもと、キャッシュレスを実現するプラットフォームを提供しています。',
      PaypayExplain:'ソフトバンクとYahoo!Japanが共同で進めるスマホ決済アプリPayPay（ペイペイ）',
      NetspayExplain:'シンガポールの３大銀行すべてのQRコード決済が行えるサービス。',
      RakutenpayExplain:'楽天ペイは楽天以外のショップでも、お持ちの楽天IDでかんたんに決済ができます。',
      UmspayExplain:'銀聯（UnionPay / ユニオンペイ）カードとは、国際5ブランド（VISA、MASTER、JCB、AMERICAN EXPRESS、DINERS CLUB）に続く第6の国際ブランドとしての地位を確立しつつあります。',
      VIAExplain:'シンガポールのDash、タイのGlobal Payが使える。',
      DocomoExplain:'d払いは、街のお店やネットショッピングでのお支払いを月々のケータイ料金と合算して支払える、かんたん・便利・おトクなスマホ決済サービスです。',
      PringExplain:'pring（プリン）は、友達や家族とメッセージを送るような感覚でお金のやり取りができるスマホアプリです。',
      SolPayExpain: '韓国国内第二位の新韓銀行が提供するQRコード決済',
      GMOExpalin : 'オンライン決済にを得意とする決済システム',
      DiXingExpalin : '国境を越えた支払いおよび銀行会員サービスプロバイダー',
      tBTCExplain: '誰もがBTCを利用してtBTC（ERC20）を作成できる仕組みを目指している。第三者を信用しないくても良いという点と誰もがBTCでtBTCをミントでき、かつデポジット者がコントロールできるウォレットに格納できるという点がメリットとなります。',
      BTCExplain: 'ビットコインは、新しい決済システムと完全な電子通貨を実現するものであり、中央機関や仲介者を必要としない、ユーザーによる初の分散型P2P決済ネットワークです。ユーザーの観点からは、ビットコインはインターネット上のお金の様なものです。また、ビットコインは現存するものの中で、最も優れた三式簿記システム ともいえます。',
      ACDExplain: 'ACD COINは、航空会社やECプラットフォームと連携し、グローバルなオンラインショップ、オフラインショップで利用することができます。',
      ContentPic1:'個人事業主の場合は、必ず営業許可証が必要になります。 (写真/スキャンデーター)',
      ContentPic2:'振込口座が確認できるページの写真/スキャンデーター',
      picDetail:'鮮明で、実際の写真をアップロードしてください。写真には文字、数字、URLなどのような内容を加えないでください。名刺、ｑｒコード、自取り写真、風景写真などのような関係ない写真をアップロードしないでください。JPG、PNGの形式が使われ、最多９枚アップロードできて、毎枚のバイト数は最大２Ｍです。',

    },
    agentaudit:{
      agentManagement: '代理店管理',
      homePagenew:'代理店ホームページ',
      auditAgent:'新規代理店を審査します',
      ChannelAddition:'チャンネル追加',
      man:'男',
      woman:'女',
      bankInfo: '銀行情報',
      bankName : '銀行名称',
      bankCode : '金融機関番号',
      bankType:'銀行タイプ',
      branchName : '銀行支店名称',
      branchnumber:'銀行支店番号',
      Subbranchtype:'銀行支店種類',
      Accounttype:'口座種類',
      Bankaccountname:'銀行口座名義人(半角カタカナ)',
      Bankaccountname2:'銀行口座名義人',
      Bankaccount:'銀行口座',
      feeRate: 'レート:',
      reject: '拒否',
      ok: '通過',
      Refuseapplication:'申請を拒否しますか？',
      Confirmrejection:'代理の申請を拒否しますか。拒否した後、申請人は再提出することができます。',
      Determine:'確定',
      cancel:'取消',
      rate:'レートをご記入ください',
      distribution : '分配',
      toBeISO: '一級代理になります',
      toBeSubordinate : '二次代理または三次代理になります',
      chooseOne: '上級代理をご選択ください'
    },  
    agentManagement: {
      examinePass:'審査済みウォレットチャンネルのレート',
      agentManagement: '代理店管理',
      id: '営業担当者システムコード',
      agentCode: '営業担当者コード',
      agentTime:'申請時間',
      loginId: 'ログインID',
      name: '営業担当者',
      mail: 'メールアドレス',
      registeredChannel: '登録済みウォレット',
      phone: '電話番号',
      resetPsd: 'パスワードを変更する',
      search: {
        keywords: 'キーワード検索',
        keywordsPlaceholder: '名前/メールアドレス/電話番号',
        applytime: '申請時間',
      },
      underCount:'下位代理店数の統計',
      agency : '代理店',
      state : '状態',
      query:'検索',
      reset:'クリア',
      status: {
        comoleteMeterial :  '審査失敗',
        waitingReview : '審査中',
        reverseMaterial: '提出待ち',
        normal: '審査通過'
      },
      contactInfo: '担当者情報',
      contactPhone: '電話番号',
      name: '名前',
      mail: 'メールアドレス',
      agencyName : 'エージェント名',
      bankInfo : '銀行情報',
      bankName : '銀行名称',
      bankCode : '金融機関番号',
      branchName : '銀行支店名称',
      screen : '選別',
      keyword : 'キーワード',
      subState : '審査状態',
      all : 'すべて',
      agentsList : '代理店一覧',
      systemCode : 'システムコード',
      agentName : '代理店名称',
      curChannel : '承認されたチャンネル',
      create: '代理店追加',
      invatation:'新規代理店の招待',
      invatationnew:'新規代理店の招待',
      homePagenew:'代理店ホームページ',
  
      invateCode: '招待コード',
      inVateCodePh: '招待コードをご入力ください',
      mailPh : '招待する代理店のメールアドレスを入力してください',
      send : '送信',
      sendpop:'「送信」をクリックすると、入力した招待コードを招待する代理店のメールアドレスに送信します。招待コードを受取った代理店は自ら登録処理を行うことができます。',
      select : '追加方式を選んでください',
      direct : '直接追加',
      tips : 'ヒント:',
      tip1: '「代理招待」とは、新しい代理のメールアドレスに招待用リンクを送ってから、新しい代理から登録情報を記入するということです。',
      tip2 : '「直接添加」とは、該アカウントにて新しい代理を直接添加して、新しい代理の登録情報を記入するということです。',
      homePage : '代理店ホームページ',
      action: {
        detail : '詳細',
        edit : '編集',
        delete : '削除 ',
        review : '審査'
      },
      channel: '承認済みチャンネル',
      operation: '操作',
      editPsdBtnOk: '実行',
      editPsdBtnCancle: '取消',
      Promptdescription:'「送信」をクリックすると、入力した招待コードを招待する代理店のメールアドレスに送信します。招待コードを受取った代理店は自ら登録処理を行うことができます。',
      sure:'送信',
      sucsend:'送信しました、「ok」をクリックして代理店ホームページに戻ります。',
      sendPrompt:'認証コードをメールアドレスにお送りいたしましたので、60分間以内に登録認証操作を行ってください。',
      level : 'レベル',
      roleEnum: {
        ADMIN : '管理人',
        AGENT: '営業担当者',
        ISO: '一次代理店',
        ISV_LEVEL_1: '二次代理店',
        ISV_LEVEL_2: '三次代理店'
      },
      superiorAgencyName : '上級代理名称'
    },
    agencyManagement: {
      id: '代理店システムコード',
      agentCode: '代理店コード',
      loginId: 'ログインID',
      name: '代理店',
      mail: 'メールアドレス',
      registeredChannel: '登録ウォレット',
      phone: '連絡先',
      resetPsd: 'パスワードを変更',
      search: {
        keywords: 'キーワード検索',
        keywordsPlaceholder: 'メール/連絡先を入力してください',
      },
    },
    reviewDetail: {
      revoke: 'キャンセル',
      reject: '拒否',
      ok: '通過',
      rejectReason: '拒否理由',
      null: 'なし',
    },
    modal: {
      title: 'メッセージ',
      primaryButtonText: '完了',
      secondaryButtonText: 'キャンセル',
      errorTitle: 'エラー',
      confirmPrimaryButtonText: '確定',
      confirmSecondaryButtonText: 'キャンセル',
    },
    validator: {
      required: '必須',
      number: '正しい番号を入力してください',
      format : '正しい形式で入力してください',
      email: '正しいメールアドレスを入力してください',
      len: '入力した文字列が長すぎます',
      len1: 'パスワードは、6桁～8桁の長さで、英字と数字の両方を含む必要があります',
      integer: '整数を入力してください',
      samePwd: 'パスワードが違います。同じパスワードを2回入力してください。',
      samePwdAccount:'同じパスワードを2回入力してください。',
      sameMail: '同じメールアドレスを入力してください',
      existEmail: '既にこのメールアドレスは使用されています。別のメールアドレスを入力してください。',
      unExistUser: 'ユーザが存在しないか、もしくはパスワードが間違っています。',
      receivable: {
        refoundAmountTooHight: '返金額は支払額を超えることはできません。',
        refoundAmountGtZero: '返金額は0より大きい数字を入力してください',
        refoundAmountInvalidDecimal: '返金額は小数点以下2桁で保持されます',
        max_l_8: '8桁以下にしてください。',
      },
      max_l_x: '%x%桁以下にしてください',
      max_l_100: '100桁以下にしてください',
      min_l_6: '6桁以上にしてください。',
      positiveNumber: 'マイナスの数は入力できません',
      noBindingChannels: '登録されたウォレットがありません',
      existLoginId: 'このログインIDは既に登録されています。別のIDを登録してください。',
      enCode: '記号は入力できません。',
      halfChar: '半角カナ・半角スペース・半角かっこをご入力ください。',
      maxUploadCount: '写真のアップロードは九枚まで',
      maxUploadSize: '写真を1MB以下にしてください',
      maxOneDeciaml: '小数第1位まで',
      maxTwoDeciaml: '小数第2位まで',
      percentageNumber: '正確な数字を入力してください（小数以下2桁まで表示する）',
      invalidUploadExtensions: 'アップロードできる画像形式はjpeg,jpg,pngのみです',
      invalidUploadExtensionsAndPdf: '画像とPDFファイルのみアップロードできます',
      simplePsd: '入力されたパスワードは使用できません',
      mailCannotRepeat: '企業と店舗のログインメールアドレスは重複することができません',
      errorCaptchaCode : '検証コードには誤りがありますので、書き直してください。',
      kata : '半角カタカナをご入力ください',
      kata1 : 'フリガナをご記入ください',
      firstMail : 'まず、メールアドレスの情報をご記入ください。',
      password: 'パスワードを入力してください',
      passwordAgin: 'パスワードをもう一度入力してください',
      mailAgin: 'メールアドレスをもう一度入力してください'
    },
    enterprise: {
      tip: '会社情報を変更する場合には、カスタマーセンターにご連絡ください',
      applyForChannel: '決済ウォレット申請',
      registeredChannelInfo: '既存決済ウォレット',
      applyChannelInfo: '審査中のウォレット',
      toApplyNewChannel: '新しいウォレットを申請する',
    },
    channel: {
      editTitle: 'ウォレット変更',
      addTitle: 'ウォレット追加',
      id: '決済ウォレットID',
      qf: 'QFPay',
      channel: 'ウォレット',
      bankingOrg: '支払いウォレット',
      currency: '通貨',
      rate: 'レート',
      rateUnit: '100分の1単位',
      withdrawCycle: '引き出しサイクル',
      withdrawCycleUnit: '日中',
      withdrawAmount: '１度に引き出せる最大額',
      withdrawAmountUnit: '単位は',
      applySuccess: '申請書が提出されました。審査結果をお待ちください。',
      comment: '備考',
      applicationDate: '申請時間',
      rejectReason: 'NG理由',
      typeEnum: {
        ALIPAY: 'Alipay',
        WECHAT: 'WeChat',
      },
      toApply: '新しいウォレットを申込む',
      invalidFeeRate: '貴社のレートを貴社代理店のレートより大きく設定してください。{{1}}）',
      nullChannel: 'レートがまだ設定されていません',
      feeRateTooLarge: 'レートを最大6%にしてください',
      type: '支払いチャンネル',
      repeatApplyError: '1つの支払いウォレットにつき1回しか申請できません',
      applyInAttachedStore: 'ヒント: 付属店舗内で新しいチャンネルをご申請ください。'
    },
    tips: {
      confirmToDelete: '削除してもよろしいですか？',
      needToReWrite: '申請が通りませんでした。ユーザーに連絡して情報を再入力してください。',
      noPremission: 'このアカウントには権限がありません',
      reviewInEmail: 'メールを確認してからパスワードを更新してください。',
      modifySuccess: 'パスワードを更新しました',
      channelExist: '同じウォレットを追加することはできません。',
      confirmPassed: 'この申請を承認しますか？',
      rejectStore: '申請NG',
      confirmToResetPsd: 'パスワード変更メールを登録メールアドレスに送付しますが、よろしいですか？',
      actionSuccess : '操作に成功',
      confirmRescindTip: '${name}との解約を確認しますか?登録時間:${createDate},解約時間:${now}',
      confirmRescindEnterpriseTip: '企業下全店舗を同時に解約いたします',
      confirmRenewalTip: '${name}とのリカバリ契約を確認しますか?',
      confirmRenewalEnterpriseTip: '企業下のすべての店舗を同時に復旧いたします',
    },
    message: {
      readed: '既読',
      unread: '未読',
      readAll: 'すべて見る',
    },
    pagination: {
      prev: '前のページ',
      next: '次のページ',
      each: '1ページあたりの記録数',
      total: '総ページ数',
    },
    currency: {
      CNY: '人民元',
      USD: 'ドル',
      JPY: '円',
    },
    bank: {
      ALIPAY: 'Alipay',
      WECHAT: 'WeChat',
      UNKNOWN: '未登録',
      ZERO: 'ゼロ',
    },
    settlement: {
      amountInThisMonth: '今月の精算金額',
      pay: '支払い',
      status: '精算状態',
      status_unsettlement: '未精算',
      status_settled: '精算済み',
      status_unsettlement_csv: '自動振込書',
      settlementTime: '精算期間',
      payeeName: '口座名義人名称',
      payeeAccount: '口座番号',
      payeeBankName: '銀行名',
      bankBranch: '支店名',
      channel: 'ウォレット',
      tradeAmount: '総売上',
      feeRate: 'レート',
      channelFee: '決済手数料',
      systemFee: 'システム利用料',
      serviceFee: 'サービス費',
      otherFee: 'その他の費用',
      finalFee: '決済金額',
      enterpriseName: '所属企業名',
      storeName: '支店名',
      doSettle: '精算',
      userType: 'タイプ',
      settlementRange: '精算期間',
      settlementFee: '精算見込み金額',
      settlementInfo: '精算情報',
      settlementSuccess: '精算完了',
      doSettleTime: '精算時間',
      operator: '精算者',
      exportUnsettlement: '自動送金伝票をエクスポート',
      transferDate: '振込日',
      ISOName: '一次代理店名',
      commission: '支払金額',
      agentCommission: '利益',
      QFPayCommission: 'QFPayの利益',
      agent: '営業担当者',
      agencyName: '代理店名',
      bankInfo: '銀行情報',
      bankCode: '銀行番号',
      bankName: '銀行名',
      branchBankCode: '支店番号',
      branchBankName: '支店名',
      accountType: '口座種類',
      accountName: '銀行口座名',
      accountCode: '口座番号',
      mineCommission: '利益',
      stat: {
        totalIncome: '売上',
        totalPay: '支払金額',
        dividends: '利益',
        title: '総計',
      }
    },
    profitManage: {
      profitTitle: '本月利益',
      storeTopFive: 'TOP5店舗',
      profitAccount:'ウォレットによる収益額の統計',
      trendAccount:'収益の推移',
      salesClerk:'TOP5代理・営業担当者',
      profitShop:'店舗',
      allEarnings:'利益総額',
      otherAgentString:'他の代理店',
      day_30:'最近３０日間',
      day_7:'最近７日間',
      liquidationTitle:'収益清算検索',
      lowerLevelEarnings:'下位代理店収益検索',
      agencyLevel:'代理店レベル別',
      all:'全部',
      agencyLevel_1:'一次代理店',
      agencyLevel_2:'二次代理店',
      agencyLevel_3:'三次代理店',
      agent:'営業担当者',
      keyword:'キーワード',
      keywordDefaultValue:'代理店名称を入力してください',
      monthScope:'収益日付',
      query:'検索',
      reset:'クリア',
      myEarnings:'本人収益検索',
      index:'番号',
      agencyName:'代理店名',
      name : 'エージェント名',
      month:'月',
      earnings:'金額',
      meAndLowerEarnings: '代理店総収益（下位代理店含）',
      phone:'連絡先',
      op:'操作',
      exportDetail:'詳細出力',
      fileName:'収益詳細',
      profitName:'収益管理',
      count :'個数',
      totalEarning:'総収益',
      otherAgent:'他の代理店'
    },
    resetPsd: {
      mailSended: 'パスワードの変更に関する情報ををあなたのメールアドレスに送付しました。',
    },
    header: {
      oldPsd: '現在のパスワードを入力してください',
      newPsd: '新しいパスワードを入力してください',
      confirmPsd: '新しいパスワードをもう一度入力してください',
      editPsdBtnOk: '実行',
      editPsdBtnCancle: '取消',
    },
    StoreManage:{
      tableTitle:'契約済み店舗一覧リスト',
      Title:'店舗管理',
      TabTitle:'営業管理',
      MesWrite:'店舗情報編集',
      TodayAccount:'加盟店数の本日統計',
      AccountMonth:'本月統計',
      AccountNum:'加盟店総数',
      AccountWait:'提出待ち',
      AccountWaitPass:'審査通過',
      AccountToday:'店舗統計TOP2',
      AccountUp:'増加率',
      AccountDown:'減少率',
      AccountProfit:'収益力ランキング',
      Package:'ウォレットによる収入TOP5',
      StoreList:'加盟店一覧リスト',
      AddStore:'加盟店の追加',
      Sign:'契約済み',
      SignWait:'提出待ち',
      StoreClass:'タイプ',
      Key:'キーワードを入力してください',
      Search:'検索',
      Reset:'クリア',
      All:'全部',
      AllDetail:'件、企業数',
      AllEnd:'店舗数',
      StoreNumber:'企業数',
      Submit:'提出',
      TableDetail:'詳細',
      TableWrite:'編集',
      SignTime:'契約日付',
      SignCard:'ウォレット',
      CardWait:'審査中ウォレット',
      Address:'アドレス',
      StoreName:'加盟店名称',
      StoreLevel:'レベル関係',
      StoreStatus:'状態',
      Action:'操作',
      MessageAll:'情報完備',
      MessageError:'情報不備',
      DownloadMoke:'サンプルのダウンロード',
      AddAll:'一括追加',
      ChooseAdd:'登録種類の選択',
      ChooseClass:'アカウント種類：',
      ClassPerson:'個人',
      ClassStore:'法人',
      StoreMessage:'企業アカウント情報',
      StoreMessageLabel:'企業アカウントID（メールアドレス）:',
      male:'男',
      female:'女',
      yes:'はい',
      no:'いいえ',
      StoreRate:'加盟店手数料',
      Please:'レートをご入力ください。',
      PendingApproval:"審査待ち",
      Rescind: "解約済み",
      Normal:"正常",
      Reviewed:"レビュー済",
      awaitHandle: '料率保留中',
      AddChannel:'新たなチャンネルの申請',
      ChannelMes:'新たなチャンネル情報',
      ChoosePlease:'選んでください',
      StartTime:'開始時間',
      EndTime:'終了時間',
      LogMail:'ログインID',
      export:'出力',
      Save :"保存",
      ChooseTime:"時間を選んでください。",
      loginId: 'ログインID:',
      feerateRule : '料率は以下の条件を満たす必要があります：店舗料率 >= 直属上位代理店料率,下位組織料率>= 上位組織料率',
      appliFeerateRule : '料率は以下の条件を満たす必要があります：店舗料率 >= 直属上位代理店料率,下位組織料率>= 上位組織料率,チャネル料金は代理店料金よりも低い',
      crossLevelNow : 'レベルをまたいで審査を受けていますので、ご確認ください。',
      awaitHandleChannel : '料率保留中チャネル',
      state : 'チャネル状態',
      differExplain : 'このチャネルの代理店料率は契約と異なります。確認してください。',
      edit : '編集',
      rescind: '解約',
      renewal: "契約を更新",

    },
      StoreAdd: {
            SelectRegistrationType:'登録タイプを選択',
            AccountType:'アカウントの種類:',
            LegalPerson:'法人',
            Personal:'個人',
            EnterpriseInfo:'企業アカウント',
            EnterpriseLoginId:'企業アカウントID:',
            FillEnterpriseLoginId:'企業アカウントIDを入力してください！',
            man:'男',
            woman:'女',
            yes: 'はい',
            no: 'いいえ',
            submit: '申請',
            rate:'レートを入力してください',
            storeRate:'加盟店手数料',
            storeChannel : 'チャンネルを選択してください'
      },
      ModalMessage: {
            confirmTitle:'登録済み企業へ追加しますか。',
            confirmContent:'この企業ログインID（メールアドレス）は既に存在します、この企業に新しい店舗を追加しますか。',
            Cancel:'キャンセル',
            Ok:'確認',
            mailExist:'このメールアドレスは既に登録されています。',
            onlyChoose:'チャンネルを少なくとも一つ選んでください。',
            WriteRate:'選択されたチャンネルレートをご記入ください。',
            AccountError:'営業担当者のアカウント番号が違います。',
            NoRate:'チャンネルがありません',
            RightNo:'正しい数字を入力してください。',
            MessageError:'情報に誤りがあります。',
            need:'必須！',
            check:'正しいメールアドレスを入力してください。',
            MessageNoAll:'店舗情報に不備があります。',
            MessageNoAllStore:'企業情報に不備があります。',
            MessageNoAllInfo:'すべての情報を入力してください。',
            ForgetMail:'入力されたメールアドレスは登録されていません。',
            ForgetMailDetail:'メールを確認して再入力してください。',
            ForgetMailSend:'あなたのメールアドレスにメールを送信しました。',
            ForgetMailSendDetail:'メールを確認してパスワードを再設定してください。',
            NotSame:'企業メールアドレスと店舗メールアドレスは同じです。',
            RateGreater:'チャンネルレートが上位代理店より低くなります',
            UnExist:'代理店は該当チャンネルの権限がありません。',
            RateError:'チャンネルレートが間違っています。',
            AddSuccess:'追加に成功しました。',
            AddError:'追加に失敗しました。',
            NoAddInfo:"インポートする情報がありません。",
            imgTypeError:"JPG/PNG形式の画像をアップロードしてください。",
            imgBigError:"２ＭＢ（メガバイト）以下の画像をアップロードしてください。",
            imgLengthError:"アップロードできる画像の数量制限を超えました。!",
            fileTypeError:"JPG/PNG/PDF形式の画像をアップロードしてください。",
            fileBigError:"２ＭＢ（メガバイト）以下のファイルをアップロードしてください。",
            fileLengthError:"アップロードできるファイルの数量制限を超えました。",
            SaveSuccess:"保存が成功しました。",
            errorInfo:"決済チャネル再申請のため、店舗情報の修正をお願いします。",
            ConfirmDoc:"最新の文書を使用してください。",
      },
      StoreDetail:{
            Title:'店舗管理',
            Detail:'店舗情報',
            channelDetail:'ウォレット情報',
            StoreName:'店舗',
            StoreNum:'店舗数',
            StoreEarn:'手数料による収入',
            StoreNumber:'従業員の人数',
            examinePass:'審査済みウォレットチャンネルのレート',
            StoreMessage:'店舗情報',
            CompanyMessage:'企業情報',
            StoreExamine:'審査済みチャンネル',
            RateClass:'通貨の種類',
            Rate:'店舗料率',
            RateCompany:'アクワイアラ',
            StoreElse:'審査待ちチャンネル',
            superWaitHandle : 'チャネル確認中',
            ownWaitHandle : 'チャネル料率保留中',
            StoreActive:'店舗活動率',
            Company:'企業',
            ownFeerate: 'あなたの料率',
            ISORate: '一次代理店料率',
            ISVOneRate : '二次代理店料率',
            ISVTwoRate : '三次代理店料率',
            agentRate : '営業担当者料率',
            channelRate : 'チャネル料率',
            notHave : '保留中',
            channel: 'ウォレット',
            StoreElsePop : '店舗管理者の料率レビュー待ちです。',
            superWaitHandlePop : '上級代理店の料率レビュー待ちです。',
            ownWaitHandlePop:'チャネル料率の見直しが必要です。',
      },
    MessageDownLoad:{
        type:'資料ダウンロード',
        messageManagent:'資料ダウンロード',
        messageList:'資料ダウンロード',
        total:'全部',
        count:'件',
        title:'チャネル名称',
        createDate:'通知掲載日時',
        content:'チャネルロゴ',
        detail:'詳細',
        checkDetail:'詳細情報',
        messageDetail:'資料詳細',
        loadDetail: '詳細については、添付ファイルをダウンロードしてください。',
    },
      MessageNotice:{
            type:'通知タイプ',
            store:'店舗管理',
            agent:'代理店管理',
            profit:'収益管理',
            level:'通知等級',
            warning:'注意',
            task:'任務',
            notice:'通知',
            title:'タイトル',
            createDate:'通知掲載日時',
            content:'本文',
            detail:'詳細',
            checkDetail:'詳細情報',
            messageManagent:'通知管理',
            screen:'検索',
            messageList:'通知一覧',
            total:'全部',
            count:'件',
            createNotice:'通知新規',

            messageDetail:'通知詳細',
            send:'送信対象',

            sendTarget:'送信対象',
            agentGroup:'代理グループ',
            agents:'グループのメンバー',
            messageContent:'通知内容',
            messageTitle:'通知タイトル',
            chooseGroup:'代理グループをご選択ください。',
            titleTips:'入力して、字数上限は16字です。',
            contentTips:'内容をご入力ください。',
            submit:'掲載',
            back:'戻る',

            groupManagent:'グループ管理',
            groupList:'グループ一覧',
            createGroup:'グループ新規',
            chosseAgents:'グループメンバーをご選択ください。',
            save :"保存",
            cancel:'取消',
            groupInfo:'グループ情報',
            groupTitle:'グループ名',
            groupRemark:'グループに関する備考',
            remarkTips:'グループの詳しく説明などのような情報をご入力ください。',
            operate: '操作',
            edit : '編集',
            agentManagement: '営業担当者管理',
            readStatus:'状態',
            isRead:'既読',
            noRead:'未読',
            chooseAgents:'代理グループのメンバーを選択してください。'
      },
      merchantAsyncFieldPlaceholder: {
      'Agent Name': '例：佐藤太郎',
      'Agent Code': '例：930481098',
      'Agency Name': '例：株式会社〇〇〇〇',
      'First Contact Name': '例：藤本太郎',
      'First Contact Phone': '例：01-3987-3298',
      'First Contact E-Mail Address': '例：fujimoto@xxx.com',
      'Bank Code': '例：0005',
      'Bank Name': '例：三菱銀行',
      'Branch Code': '例：251',
      'Branch Name': '例：新瑞橋支店',
      'Account Code': '例：12414141',
      'Account Name': '例：ｶﾌﾞｼｷｶﾞｲｼｬ〇〇〇〇',
      'Account Type': '例：普通預金',
      'Company Name': '例：株式会社〇〇〇〇',
      'Company Code': '例：580000068',
      'Business Category': '例：飲食',
      'Principal Shareholder Name': '例：十元真由美',
      'Principal Shareholder Birth Day': '例：2018-10-22',
      'Principal Shareholder Home Address': '代表取締役の住所を入力してください',
      'Principal Shareholder Phone': '例：03-1374-1287',
      'Principal Shareholder E-Mail Address': '例：shiyuan@gmail.com',
    },
    agencyAsyncFieldPlaceholder: {
      'Agency Name(S)': '例：株式会社〇〇〇〇',
      'Name of Person in Charge': '例：藤本太郎',
      'Phone of Person in Charge': '例：01-3987-3298',
      'E-Mail Address of Person in Charge': '例：fujimoto@xxx.com'
    },
    errorCode: {
        403041: '権限がありません',
        40401: 'ユーザーが存在しません',
        40405: '記録がありません',
        40601: '情報に誤りがあります',
        40602: '検証コードに誤りがあります',
        40603: 'ログインIDとメールアドレスが一致しません',
        40605: 'リンクが無効になりました',
        50001: 'ネットワークに障害がありました。もう一度お試しください',
        50002: '店舗情報の更新に失敗しました',
        50003: '代理店の追加に失敗しました。もう一度お試しください',
        50004: '営業担当者の追加に失敗しました。もう一度お試しください',
        50005: 'ウォレットの追加に失敗しました。もう一度お試しください',
        50006: 'ウォレットの追加に失敗しました。もう一度お試しください',
        50007: 'ウォレットの更新に失敗しました。もう一度お試しください',
        50008: '支店情報の更新に失敗しました。もう一度お試しください',
        50009: '支店情報の追加に失敗しました。もう一度お試しください',
        50010: '銀行情報の追加に失敗しました、もう一度お試しください',
        50011: '銀行情報の更新に失敗しました、もう一度お試しください',
        50012: '企業情報の更新に失敗しました、もう一度お試しください',
        50013: '企業の追加に失敗しました、もう一度お試しください',
        50014: '企業情報の追加に失敗しました、もう一度お試しください',
        50015: 'ユーザーが存在しません',
        50016: '入力されたパスワードが間違っています',
        50017: '代理店情報の更新に失敗しました、もう一度お試しください',
        50018: 'ウォレットが追加されました',
        60001: '上位代理店のチャンネルレートより高くする必要があります。',
        70001: '追加に失敗しました。再度お試しください。',
        70002: '修正に失敗しました。再度お試しください。',
    },
      businessCategory: {
            ShoesGarments: '靴と衣料品',
            ComprehensiveMall: '総合ショッピングモール',
            Food: '食品',
            Cosmetics: '化粧品',
            MaternalInfant: 'マタニティ・ベビー用品',
            DigitalAppliance: 'デジタル家電',
            Logistics: '物流業界',
            EducationIndustry: '教育業界',
            HotelIndustry: 'ホテル業界',
            StationeryOfficeSupplies: '文具/事務用品',
            AirTicket: '航空券',
            OverseasEducation: '海外留学',
            TravelTicket: '旅行券',
            CarRental: 'レンタカー',
            InternationalConference: '国際会議',
            Software: 'ソフトウエア',
            MedicalService: '医療サービス',
            OtherTradeIndustry: 'その他貿易',
      },
};
