class Event {
  listeners = [];
  constructor(observable, ename) {
    this.name = ename;
    this.observable = observable;
    this.firing = false;
  }

  addListener(fn, scope) {
    let index = length = this.listeners.length;
    let oldIndex = this.findListener(fn, scope);
    if(oldIndex === -1) {
      let listener = this.createListener(fn, scope);
      this.listeners[index] = listener;
    }
  }

  findListener(fn, scope) {
    var listeners = this.listeners,
      i = listeners.length,
      listener;
      
      while (i--) {
        listener = listeners[i];
        if (listener) {
          
          // use ==, not === for scope comparison, so that undefined and null are equal 
          if (listener.fn === fn && listener.scope == scope) {
              return i;
          }
        }
      }

      return -1;
  }

  createListener(fn, scope) {
    let listener = {
      fn: fn,
      scope: scope
    }
    return listener;
  }

  fire(args) {
    let listeners = this.listeners;
    let observable = this.observable;
    if(observable.getSuspend() === true) {
      return;
    }
    let count = listeners.length;
    this.firing = true;
    for(let i = 0; i < count; i ++) {
      let listener = listeners[i];
      if (!listener) {
        continue;
      }
      let fireFn = listener.fn;
      let fireScope = listener.scope;
      if (!Array.isArray(args)) {
        args = [args];
      }
      if(!fireScope) {
        fireFn(args);
      }
      fireFn.apply(fireScope, args);
    }
    this.firing = false;
  }

  removeListener(fn, scope) {
    let index = length = this.listeners.length;
    let oldIndex = this.findListener(fn, scope);
    if(oldIndex !== -1) {
      this.listeners.splice(oldIndex, 1);
    }
  }
}
export default Event;