import Field from './Field';

class BooleanField extends Field {
  type = 'boolean'
  convert(v) {
    if(v === null || v === undefined) {
      return v;
    }
    return Boolean(v);
  }
}
export default BooleanField;
