const ClipDefault = {
  count: false,
  inclusive: false,
  wrap: true
};
const Numbers = {
  ClipDefault: ClipDefault,
  Clip: {
    DEFAULT: ClipDefault,

    COUNT: Object.assign({}, ClipDefault, {count: true}),

    INCLUSIVE: Object.assign({}, ClipDefault, {inclusive: true}),

    NOWRAP: Object.assign({}, ClipDefault, {wrap: false})
  },
  clipIndices: function clipIndices(length, indices, options) {
    options = options || ClipDefault;
  
    var defaultValue = 0, // default value for "begin" 
        wrap = options.wrap,
        begin, end, i;
  
    indices = indices || [];
    for (i = 0; i < 2; ++i) {
        // names are off on first pass but used this way so things make sense 
        // following the loop.. 
        begin = end;  // pick up and keep the result from the first loop 
        end = indices[i];
        if (end == null) {
            end = defaultValue;
        } else if (i && options.count) {
            end += begin; // this is the length not "end" so convert to "end" 
            end = (end > length) ? length : end;
        } else {
            if (wrap) {
                end = (end < 0) ? (length + end) : end;
            }
            if (i && options.inclusive) {
                ++end;
            }
            end = (end < 0) ? 0 : ((end > length) ? length : end);
        }
        defaultValue = length; // default value for "end" 
    }
  
    // after loop: 
    // 0 <= begin <= length  (calculated from indices[0]) 
    // 0 <= end <= length    (calculated from indices[1]) 
  
    indices[0] = begin;
    indices[1] = (end < begin) ? begin : end;
    return indices;
  }
};

export default Numbers;
