import { Model } from 'qn/data';
import { computed } from 'mobx';

@Model({
  modelName: 'Unsettlement',
  fields: {
    startTime: 'date',
    endTime: 'date',
    agencyName: 'string',
    agencyKey: 'string',
    name: 'string',
    agencyName: 'string',
    channels: 'any',
    // channel: 'any',
    // tradeAmount: 'any',
    // feeRate: 'any',
    // channelFee: 'any',
    // systemFee: 'any',
    // serviceFee: 'any',
    // otherFee: 'any',
    // finalFee: 'any',
  },
  idgen: 'uuid',
})
class SettlementAdmin {

}

export default SettlementAdmin;
