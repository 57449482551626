import { Model } from 'qn/data';

@Model({
  modelName: 'ReviewAgency',
  fields: {
    id: 'string',
    key: 'string',
    loginId: 'string',
    name: 'string',
    status: 'string',
    mail: 'string',
    phone: 'string',
    agentCode: 'string',
    agencyName: 'string',
    comment: 'string',
    createdDate: 'date',
    updatedDate: 'date',
  },
  idProperty: 'key',
})
class ReviewAgency {

}

export default ReviewAgency;
