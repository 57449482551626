import React from 'react';
import Header from 'component/header/Header';
import LoginHeader from 'component/header/LoginHeader';
import { LocaleContext, locales } from 'component/LocaleContext';
import { Login } from 'component/login';
//import { Forget } from 'component/forgetPwd';
import { ForgetPassword } from 'component/forgetPassword';
import { ResetPassword } from 'component/resetpassword';
import { QfpayTostoremanagement, QfpayToAgentregistration } from 'component/qfpayTostoremanagement';
// import { Register, RegisterAfterMail, RegisterEmailSended, RegisterSuccess,Agentregistration, RegisterType } from 'component/register';
import { Register, RegisterAfterMail, RegisterEmailSended, RegisterSuccess,QrCodeRegister,QrCodeRegisterAfterMail,QrCodeRegisterSucess,Agentregistration, RegisterType } from 'component/register';

import { Route, Link, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import Nav from 'component/nav';
import api from 'remote/api';
import { observer } from 'mobx-react';
import Footer from 'component/footer/Footer';
import sessionStore from 'sessionStore';
import appStore from 'appStore';
import { AuthorizedRoute, access } from 'acl';
import Card from 'control/card';
import withLocale from 'decorator/withLocale';
import { DEFAULT_LOCALE } from 'config';
import { MerchantRoute } from 'component/merchant';
import { MerchantRevenueRoute } from 'component/merchant/merchantRevenue';
import { AgentRoute } from 'component/agent';
import { AgentManageRoute } from 'component/agentmanage';
import { ROLE } from 'acl/role';
import { getBasePath } from 'config';
import { StoreManagementRoute } from 'component/storemanagement';
import { ProfitManagementRoute } from 'component/profitmanage';
import { NoticeGroupRoute } from 'component/noticeGroup';
import { NoticeManagementRoute } from 'component/notice';
import { AccountRoute } from 'component/account';
import { DataDownloadRoute } from 'component/dataDownload';


const basePath = getBasePath();


@withLocale
@observer
class Home extends React.Component {


  createSSE = () => {
    if ('EventSource' in window) {
      this.source = new EventSource(`${basePath}/login`);
      this.source.onopen = function (event) {
        console.log('Open EventSource')
      };
      this.source.onmessage = function (event) {
        let data = event.data;
        if (sessionStore.getUser().agentId === Number(data)) {
          console.log('sse')
          window.alert(window.qf_locale.repeatLogin);
          sessionStore.logout();
          window.history.replaceState(null, null, '/auth/login');
          window.location.reload();
        }
      };
    } else {
      console.error(`this agent dosen't support EventSource api`);
    }
  }

  async componentWillMount() {
    const { history } = this.props;
    if (!sessionStore.hasAccessToHomePage()) {
      history.replace(sessionStore.getRedirectUrl());
    }
    await sessionStore.fetchChannels();
    sessionStore.getUser();
    if (sessionStore.hasAccessToHomePage()) {
      api.get('/register/checksession');
      this.createSSE();
    }
  }

  async componentDidUpdate() {
    const { history } = this.props;
    if (!sessionStore.hasAccessToHomePage()) {
      history.replace(sessionStore.getRedirectUrl());
    }
    if (!sessionStore.channels) {
      await sessionStore.fetchChannels();
    }
  }

  componentWillUnmount() {
    if (this.source) {
      this.source.close();
    }
  }


  render() {
    const { match, locale } = this.props;
    return (
      <React.Fragment>
        <Header />
        <div className="d-flex" style={{ minHeight: appStore.getMainMinHeight() }}>
          <Card style={{ marginTop: 10, height: 790 ,zIndex:2}}>
            <Nav />
          </Card>
          <div className="main" style={{ width: '100%', minHeight: 790 }}>
            <Switch>
            <AuthorizedRoute path={`${match.path}/noticeManage`}
                               roles={[ROLE.ADMIN, ROLE.AGENT, ROLE.ISO, ROLE.ISV_LEVEL_1, ROLE.ISV_LEVEL_2]}
                               component={NoticeManagementRoute} />
            <AuthorizedRoute path={`${match.path}/noticeGroup`}
                               roles={[ROLE.ADMIN, ROLE.AGENT, ROLE.ISO, ROLE.ISV_LEVEL_1, ROLE.ISV_LEVEL_2]}
                               component={NoticeGroupRoute} />
            <AuthorizedRoute path={`${match.path}/account`}
                               roles={[ROLE.ADMIN, ROLE.AGENT, ROLE.ISO, ROLE.ISV_LEVEL_1, ROLE.ISV_LEVEL_2]}
                               component={AccountRoute} />
            <AuthorizedRoute path={`${match.path}/storemanagement`}
                               roles={[ROLE.ADMIN, ROLE.AGENT, ROLE.ISO, ROLE.ISV_LEVEL_1, ROLE.ISV_LEVEL_2]}
                               component={StoreManagementRoute} />
            <AuthorizedRoute path={`${match.path}/profitmanage`}
                               roles={[ROLE.ADMIN, ROLE.AGENT, ROLE.ISO, ROLE.ISV_LEVEL_1, ROLE.ISV_LEVEL_2]}
                               component={ProfitManagementRoute} />
              <AuthorizedRoute path={`${match.path}/merchant`}
                               roles={[ROLE.ADMIN, ROLE.AGENT, ROLE.ISO, ROLE.ISV_LEVEL_1, ROLE.ISV_LEVEL_2]}
                               component={MerchantRoute} />
              <AuthorizedRoute path={`${match.path}/merchantRevenue`}
                               roles={[ROLE.ADMIN, ROLE.AGENT, ROLE.ISO, ROLE.ISV_LEVEL_1, ROLE.ISV_LEVEL_2]}
                               component={MerchantRevenueRoute} />
              <AuthorizedRoute path={`${match.path}/agent`}
                               roles={[ROLE.ADMIN,ROLE.ISO, ROLE.ISV_LEVEL_1]}
                               component={AgentRoute} />
              <AuthorizedRoute path={`${match.path}/agentmanage`}
                              roles={[ROLE.ADMIN,ROLE.ISO, ROLE.ISV_LEVEL_1, ROLE.ISV_LEVEL_2]}
                              component={AgentManageRoute} />
              <AuthorizedRoute path={`${match.path}/dataDownload`}
                              roles={[ROLE.ADMIN, ROLE.AGENT, ROLE.ISO, ROLE.ISV_LEVEL_1, ROLE.ISV_LEVEL_2]}
                              component={DataDownloadRoute} />
            </Switch>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

@observer
class Auth extends React.Component {

  componentWillMount() {
    const { history } = this.props;
    if (sessionStore.hasAccessToHomePage()) {
      history.replace(sessionStore.getRedirectUrl());
    }
  }

  componentDidUpdate() {
    const { history } = this.props;
    if (sessionStore.hasAccessToHomePage()) {
      history.replace(sessionStore.getRedirectUrl());
    }
  }

  render() {
    const { match } = this.props;
    return (
      <div className="auth-wrapper">
        <LoginHeader/>
        <Switch>
          <Route exact path={`${match.path}/login`} component={Login} />
          <Route exact path={`${match.path}/forgetpassword`} component={ForgetPassword} />
          <Route exact path={`${match.path}/resetpassword`} component={ResetPassword} />
          <Route exact path={`${match.path}/AddStore`} component={QfpayTostoremanagement} />
          <Route exact path={`${match.path}/qfpayToagentregistration`} component={QfpayToAgentregistration} />
          <Route exact path={`${match.path}/register`} component={Register} />
          <Route exact path={`${match.path}/registerAfterMail/:mail`} component={RegisterAfterMail} />
          <Route exact path={`${match.path}/registerEmailSended`} component={RegisterEmailSended} />
           {/*<Route exact path={`${match.path}/registerMore`} component={RegisterMore} /> */}
          <Route exact path={`${match.path}/QrCodeRegister/:agentId`} component={QrCodeRegister} />
          <Route exact path={`${match.path}/QrCodeRegisterAfterMail/:mail`} component={QrCodeRegisterAfterMail} />
          <Route exact path={`${match.path}/QrCodeRegisterSucess`} component={QrCodeRegisterSucess} /> 
          <Route exact path={`${match.path}/registerSuccess`} component={RegisterSuccess} />
          <Route exact path={`${match.path}/agentregistration`} component={Agentregistration} />
          <Route component={Login} />
        </Switch>
      </div>
    );
  }
}

@observer
class App extends React.Component {
  constructor() {
    super();
    const langInStorage = sessionStore.getLocale();
    let lang = DEFAULT_LOCALE;
    if (langInStorage) {
      lang = langInStorage;
    }
    this.state = {
      locale: locales[lang],
      setLocale: this.setLocale,
    };
    window.qf_locale = locales[lang];
  }

  setLocale = (value) => {
    if (value !== this.state.locale.l) {
      this.setState({
        locale: locales[value],
      });
      window.qf_locale = locales[value];
      sessionStore.saveLocale(value);
      window.location.reload();
    }
  }

  // componentDidUpdate() {
  //   console.log('didupdate')
  //   if (sessionStore.isOldTag()) {
  //     console.log('isOldTag')
  //     window.location.reload();
  //   }
  // }

  render() {
    return (
      <LocaleContext.Provider value={this.state}>
        <BrowserRouter>
          <Switch>
            <Route path="/auth" component={Auth} />
            <AuthorizedRoute path="/app" component={Home} />
            <Redirect exact from="/" to={sessionStore.getRedirectUrl()} />
          </Switch>
        </BrowserRouter>
      </LocaleContext.Provider>
    );
  }
}
export default App;
