import Field from './Field';
import {action} from 'mobx';

class NumberField extends Field {
  type = 'number'

  convert(v) {
    if(v === '' || v === null || v === undefined) {
      return v;
    }
    return Number(v);
  }
}
export default NumberField;
