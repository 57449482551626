import Loadable from 'react-loadable';
import { LCLoading } from 'control/loading';
import { AuthorizedRouter } from 'acl';
import { Route, Switch } from 'react-router-dom';
import React from 'react';

const Account = Loadable({
    loader: () => import('./Account'),
    loading: LCLoading,
  });


  const AccountRoute = ({ match }) => {
    return (
      <Switch>
        <Route exact path={`${match.path}`} component={Account} />
      </Switch>
    );
  }
  export { AccountRoute };
  