import { CURRENCY } from 'const';

const Currency = {
  fmt(val, currency) {
    if (typeof val === 'string' && !isNaN(val)) {
      val = Number(val);
    }
    if (currency !== CURRENCY.JPY ) {
      val = val / 100;
    }
    return val;
  },
  unfmt(val, currency) {
    if (typeof val === 'string' && !isNaN(val)) {
      val = Number(val);
    }
    if (currency !== CURRENCY.JPY) {
      val = val * 100;
    }
    return val;
  },
  // val为万分制，要转换成百分制显示
  fmtFeeRate(val, currency) {
    if (typeof val === 'string' && !isNaN(val)) {
      val = Number(val);
    }
    return val / 100;
  },
  // val为万分制，要转换成百分制显示
  unfmtFeeRate(val, currency) {
    if (typeof val === 'string' && !isNaN(val)) {
      val = Number(val);
    }
    return val * 100;
  },

  // val为千分制，要转换成百分制显示
  fmtMerchantFeeRate(val, currency) {
    if (typeof val === 'string' && !isNaN(val)) {
      val = Number(val);
    }
    return (val / 10).toFixed(2);
  },
  // val为千分制，要转换成百分制显示
  unfmtMerchantFeeRate(val, currency) {
    if (typeof val === 'string' && !isNaN(val)) {
      val = Number(val);
    }
    return val * 10;
  },
}
export default Currency;