import React from 'react';
import './style/footer.scss';
import { Row, Col } from 'antd';
import { observer } from 'mobx-react';
import { observable, runInAction, action } from 'mobx';

import withLocale from 'decorator/withLocale';
import api from 'remote/api';

@withLocale
@observer
class Footer extends React.Component {
  
  @observable allPayType = [];

  async componentDidMount() {
    const res= await api.get('/register/allPayType');
    this.allPayType = res.data;
  }
  payType = (allPayType) => {
    return (
      allPayType.map((item,i) => {
        if((i+1) % 7 != 0){
          return(
            <img src={item.iconFooterUrl} class="footer-img "/>
          )
        } else {
          return(
            <span><img src={item.iconFooterUrl} class="footer-img "/><br/></span>
          )
        }
      })
    )
  }

  render(){
    
  const { locale } = this.props;
  const lang = locale.footer;
  return (
    <div>
      <div className="my-footer">
        <Row>
          <Col xs={8} sm={8} md={10} lg={10} xl={10} className=" footer-col">
            <div className=" line50" style={{fontSize : 18}}>{lang.concatInfo}</div>
            <ul className=" footer-detail">
              <li>{lang.concatMail}：info@qfpay.info </li>
              <li>{lang.businessHours}：10:00 〜 17:00（{lang.except}）</li>
              <li>{lang.address}</li>
              <li>TEL：0120-501-080</li>
            </ul>
          </Col>
          <Col xs={8} sm={8} md={7} lg={7} xl={7} className=" footer-col">
            <div className=" line50"  style={{fontSize : 18}}>{lang.aboutUs}</div>
            <ul className=" footer-detail">
              <li>QFPay</li>
            </ul>
          </Col>
          <Col xs={8} sm={8} md={7} lg={7} xl={7} className=" footer-col">
            <div className=" line50"  style={{fontSize : 18}}>{lang.payMode}</div>
            <ul className=" footer-detail">
              <li style={{paddingBottom : 8}}>{lang.payModeDetail}</li>
              <li>
                {this.payType(this.allPayType)}
                {/* {this.allPayType.map((payItem) => {
                        return <img src={payItem.iconFooterUrl}></img>;
                      })} */}
              </li>
            </ul>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className=" footer-col text-center margin-top-30">Copyright © 2020 QFPay Japan Inc.All rights reserved.</Col>
        </Row>
      </div>
    </div>
  );
}
};
export default Footer;
