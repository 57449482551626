import { observable, action, computed } from 'mobx';

export const defaultPageSize = 10;

export const PageMode = {
  remote: 1,
  local: 2
}

export class PageStore {
  @observable remoteTotal = 0
  @observable localTotal = 0

  @observable current = 1
  @observable pageSize = defaultPageSize

  @observable mode = PageMode.remote

  @computed
  get total() {
    return this.mode === PageMode.remote ? this.remoteTotal : this.localTotal;
  }

  @action
  setTotal(value) {
    if (this.mode === PageMode.remote) {
      this.remoteTotal = value;
    } else {
      this.localTotal = value;
    }
  }

  @action
  setCurrent(value) {
    this.current = value;
  }

  @action
  setPageSize(value) {
    this.pageSize = value;
  }

  getData() {
    return {
      current: this.current,
      pageSize: this.pageSize,
      total: this.total,
    }
  }

  @action
  useLocal() {
    this.mode = PageMode.local;
  }

  @action
  useRemote() {
    this.mode = PageMode.remote;
  }

  @action
  dispose() {
    this.remoteTotal = 0;
    this.localTotal = 0;
    this.current = 1;
    this.pageSize = defaultPageSize;
    this.mode = PageMode.remote;
  }
}

