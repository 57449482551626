import Loadable from 'react-loadable';
import { LCLoading } from 'control/loading';

export const Register = Loadable({
  loader: () => import('./Register'),
  loading: LCLoading,
});

export const RegisterAfterMail = Loadable({
  loader: () => import('./RegisterAfterMail'),
  loading: LCLoading,
});

export const RegisterEmailSended = Loadable({
  loader: () => import('./RegisterEmailSended'),
  loading: LCLoading,
});

export const RegisterSuccess = Loadable({
  loader: () => import('./RegisterSucess'),
  loading: LCLoading,
});

export const Agentregistration = Loadable({
  loader: () => import('./Agentregistration'),
  loading: LCLoading,
});

export const QrCodeRegister = Loadable({
  loader: () => import('./QrCodeRegister'),
  loading: LCLoading,
});

export const QrCodeRegisterAfterMail = Loadable({
  loader: () => import('./QrCodeRegisterAfterMail'),
  loading: LCLoading,
});

export const QrCodeRegisterSucess = Loadable({
  loader: () => import('./QrCodeRegisterSucess'),
  loading: LCLoading,
});

export default { Register, RegisterAfterMail,  RegisterSuccess, Agentregistration, QrCodeRegister, QrCodeRegisterAfterMail, QrCodeRegisterSucess};
