import Loadable from 'react-loadable';
import { LCLoading } from 'control/loading';
import { Route, Switch } from 'react-router-dom';
import React from 'react';

const DataDownload = Loadable({
    loader: () => import('./DataDownload'),
    loading: LCLoading,
});
const DownLoadDetail = Loadable({
    loader: () => import('./DownLoadDetail'),
    loading: LCLoading,
});

const DataDownloadRoute = ({ match }) => {
    return (
        <Switch>
            <Route exact path={`${match.path}`} component={DataDownload} />
            <Route exact path={`${match.path}/downLoadDetail/:messageUid`} component={DownLoadDetail} />
        </Switch>
    );
}
export { DataDownloadRoute };
