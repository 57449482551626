import { Model } from 'qn/data';
import { computed } from 'mobx';
import Channel from './Channel';

@Model({
  modelName: 'ISO',
  fields: {
    id: 'id',
    key: 'string',
    loginId: 'string',
    name: 'string',
    status: 'string',
    mail: 'string',
    phone: 'string',
    agentCode: 'string',
    agencyName: 'string',
    createdDate: 'date',
  },
  idProperty: 'key',
})
class ISO {
  constructor(data) {
    this.channels = data.channels.map(o => new Channel(o));
  }

  @computed get registeredChannels() {
    return this.channels.filter(o => o.hasPassedReview === true);
  }

  @computed get applyingChannels() {
    return this.channels.filter(o => o.hasPassedReview === false);
  }
}

export default ISO;
