import { Model } from 'qn/data';
import { CURRENCY } from 'const';

@Model({
  modelName: 'BankInfo',
  fields: {
    key: 'string',
    settlementCurrency: { type: 'string', defaultValue: CURRENCY.JPY },
    accountType: 'string',
    accountName: 'string',
    accountCode: 'string',
    branchName: 'string',
    branchCode: 'string',
    bankCode: 'string',
    bankName: 'string',
    comment: 'string',
  },
  idProperty: 'key',
})
class BankInfo {

}

export default BankInfo;
