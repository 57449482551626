import Loadable from 'react-loadable';
import { LCLoading } from 'control/loading';
import { AuthorizedRouter } from 'acl';
import { Route, Switch } from 'react-router-dom';
import React from 'react';

const StoreManage = Loadable({
  loader: () => import('./StoreManage'),
  loading: LCLoading,
});
const StoreDetail = Loadable({
  loader: () => import('./Detail/StoreDetail'),
  loading: LCLoading,
});
const EnterpriseDetail = Loadable({
  loader: () => import('./Detail/EnterpriseDetail'),
  loading: LCLoading,
});
const TempDetail = Loadable({
  loader: () => import('./Detail/TempDetail'),
  loading: LCLoading,
});
const AddStore = Loadable({
  loader: () => import('./Detail/AddStore'),
  loading: LCLoading,
});
const TempUpdate = Loadable({
  loader: () => import('./Detail/TempUpdate'),
  loading: LCLoading,
});
const TempEnterUpdate = Loadable({
  loader: () => import('./Detail/TempEnterUpdate'),
  loading: LCLoading,
});
const StoreUpdate = Loadable({
  loader: () => import('./Detail/StoreUpdate'),
  loading: LCLoading,
});
const EnterpriseUpdate = Loadable({
  loader: () => import('./Detail/EnterpriseUpdate'),
  loading: LCLoading,
});
const StoreManagementRoute = ({ match }) => {

  return (
    <Switch>
      <Route exact path={`${match.path}`} component={StoreManage} />
      <Route exact path={`${match.path}/Detail/StoreDetail/:storeKey`} component={StoreDetail} />
      <Route exact path={`${match.path}/Detail/EnterpriseDetail/:storeKey`} component={EnterpriseDetail} />
      <Route exact path={`${match.path}/Detail/NewTempDetail/:storeKey`} component={TempDetail} />
      <Route exact path={`${match.path}/Detail/NewTempUpdate/:storeKey`} component={TempUpdate} />
      <Route exact path={`${match.path}/Detail/StoreUpdate/:storeKey`} component={StoreUpdate} />
      <Route exact path={`${match.path}/Detail/EnterpriseUpdate/:storeKey`} component={EnterpriseUpdate} />
      <Route exact path={`${match.path}/Detail/NewTempEnterUpdate/:storeKey`} component={TempEnterUpdate} />
      <Route exact path={`${match.path}/Detail/AddStore`} component={AddStore} />
    </Switch>
  );
}
export { StoreManagementRoute };
